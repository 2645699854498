export const facialHair = {
  none:`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0.875732C11.2312 0.875732 0.87561 11.2257 0.87561 24.0001C0.87561 36.7745 11.2312 47.1245 24 47.1245C36.7725 47.1245 47.1244 36.7745 47.1244 24.0001C47.1244 11.2257 36.7744 0.875732 24 0.875732ZM24 6.50073C27.4687 6.50073 30.6975 7.52823 33.42 9.27573L9.27561 33.4201C7.46368 30.6124 6.50016 27.3417 6.50061 24.0001C6.50061 14.3514 14.3512 6.50073 24 6.50073ZM24 41.4995C20.6585 41.4991 17.388 40.5356 14.58 38.7245L38.7225 14.5801C40.5344 17.3878 41.4979 20.6585 41.4975 24.0001C41.4975 33.6489 33.6487 41.4995 24 41.4995Z" fill="#8D8D8D"/>
        </svg>`,
  facial_hair_1: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="35" viewBox="0 0 50 35" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.73587 1.00429C4.48937 2.32557 0 21.6791 0 21.6791C26.6333 51.1558 49.7335 22.5985 49.9918 22.0248C50.259 21.451 43.9228 3.17744 41.206 1.66493C37.2154 -0.560385 14.9913 -0.316987 9.73587 1.00429ZM36.9124 5.90112C31.1582 4.90147 17.8504 4.43206 11.9715 6.20536C11.6241 6.30967 11.3301 6.49221 11.1342 6.71822C5.19289 13.5941 6.30632 23.2255 7.01892 24.0339C11.8646 29.5103 16.9775 28.5106 18.9817 20.5134C19.1509 19.8093 19.9348 19.3051 20.8344 19.2877L27.4705 19.1747C28.4058 19.1574 29.2253 19.6876 29.4123 20.4178C31.0869 26.8329 37.1351 29.3712 42.7112 23.9296C44.3591 22.3128 42.6043 11.3601 38.0347 6.45744C37.7675 6.1619 37.3578 5.97935 36.9124 5.90112Z" fill="black"/>
</svg>
`,
  facial_hair_2: `<svg xmlns="http://www.w3.org/2000/svg" width="78" height="59" viewBox="0 0 78 59" fill="none">
  <path d="M73.2057 15.8257C67.0655 35.0915 54.4324 29.8719 48.2506 27.4319C44.5582 24.6523 41.6333 23.8036 38.8951 23.8036C36.1569 23.8036 33.232 24.6523 29.5395 27.4319C23.3578 29.8719 10.7247 35.0915 4.58446 15.8257C4.58446 15.8257 0.394161 -1.46677 2.44515e-05 0.103341C0 2 0 3 0 7.5C0 7.5 0.35451 11.3109 0.997575 16C1.18427 17.8884 2.28187 22.7003 2.44782 24.0158C3.31907 31.3147 5.24827 33.6274 6.99076 36.3433C12.5502 43.3876 19.6861 51.3443 22.3828 54.527C26.0545 58.8342 34.041 59.0888 35.9909 58.9827C37.9616 58.8978 38.9158 58.8342 38.9158 58.8342C38.9158 58.8342 39.8493 58.8766 41.8407 58.9827C43.7907 59.0676 51.7979 58.813 55.4488 54.527C58.1455 51.3655 65.2815 43.3876 70.8409 36.3433C72.5626 33.6274 74.5126 31.3147 75.3631 24.0158C75.529 22.7003 75.8157 21 76.3145 19C77.3121 14 77.8109 10 77.8109 10C78.3097 4 77.6449 1.31275 77.8109 0.103341C77.4167 -1.48799 73.2265 15.8257 73.2265 15.8257H73.2057ZM47.7113 40.8203C46.6534 41.2022 44.8279 41.4568 42.712 41.6053C41.9237 41.4992 41.2184 41.2658 40.7828 40.8415C39.1648 39.2289 38.8951 39.5048 38.8951 39.5048C38.8951 39.5048 38.6254 39.2501 37.0074 40.8415C36.5718 41.2658 35.8665 41.4992 35.0782 41.6053C32.9623 41.478 31.1161 41.2022 30.0581 40.8203C25.4529 38.9743 25.4271 32.5875 25.937 31.5C28.4309 29 38.8743 29.5748 38.8743 29.5748C38.8743 29.5748 49.5515 27.523 52.3727 32C52.9447 34.0875 52.2957 38.9743 47.6906 40.8203H47.7113Z" fill="#683824"/>
</svg>`,
  facial_hair_3: `<svg xmlns="http://www.w3.org/2000/svg" width="34" height="21" viewBox="0 0 34 21" fill="none">
  <path d="M31.3211 6.73377C25.2937 1.82035 17 0.5 17 0.5C17 0.5 8.70628 1.82035 2.67885 6.73377C-3.34857 11.6472 2.67885 20.5 2.67885 20.5L4.00486 18.9632C-2.50476 7.12338 17 6.40909 17 6.40909C17 6.40909 36.5047 7.12338 29.9951 18.9632L31.3211 20.5C31.3211 20.5 37.3486 11.6472 31.3211 6.73377Z" fill="#683824"/>
</svg>`,
  facial_hair_4: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="7" viewBox="0 0 32 7" fill="none">
  <path d="M16.0002 1.24019C16.0002 1.24019 13.9067 0.0231694 11.0869 0.71021C9.10021 1.20095 2.6489 6.0495 0 5.77469C0 5.77469 3.41793 7.83578 8.01078 5.0091C10.5529 3.43872 13.4367 3.34058 14.5048 3.24243C15.5943 3.16392 16.8333 2.69279 16.0002 1.24019Z" fill="#683824"/>
  <path d="M15.9998 1.24019C15.9998 1.24019 18.072 0.0231694 20.9131 0.71021C22.8998 1.20095 29.3512 6.0495 32.0001 5.77469C32.0001 5.77469 28.5821 7.83578 23.9893 5.0091C21.4472 3.43872 18.5633 3.34058 17.4952 3.24243C16.4057 3.16392 15.1667 2.69279 15.9998 1.24019Z" fill="#683824"/>
</svg>`,
  facial_hair_5:`<svg xmlns="http://www.w3.org/2000/svg" width="78" height="63" viewBox="0 0 78 63" fill="none">
  <path d="M76.7991 1.3894C76.7991 3.68538 76.1731 15.2601 75.4728 21C72.4887 37 64.7351 43.4823 62.4743 45.9998C60.1935 48.5174 50.8903 58.3659 47.6892 59.4938C44.5082 60.6418 39.1663 57.4394 39.1663 57.4394C39.1663 57.4394 33.8245 60.6418 30.6434 59.4938C27.4623 58.3458 18.1391 48.5174 15.8784 45.9998C13.5976 43.4823 4.55453 33 1.86506 16.5C1.18483 10.7399 1.55351 3.66524 1.55351 1.3894C1.55351 -0.121111 0.813231 0.422641 0.373081 2.17483C-0.12436 9.5 -0.124337 12 0.373081 15.5C0.373081 15.5 1.66798 25.713 2.85976 30C3.35711 32 3.8943 33.1706 4.01434 34.4193C4.07436 35.0235 4.27444 35.6881 4.55453 36.393C5.39482 37.7223 6.71524 39.8772 8.37581 42.4552C13.7376 49.1417 22.4406 56.7144 25.0415 59.7153C28.5827 63.8037 34.4647 62.2127 36.3654 62.112C38.266 62.0314 39.1863 61.971 39.1863 61.971C39.1863 61.971 40.0866 62.0113 42.0073 62.112C43.8879 62.1925 49.79 63.7836 53.3112 59.7153C55.912 56.7144 64.615 49.1417 69.9768 42.4552C71.6374 39.8772 72.9578 37.7223 73.7981 36.393C74.0782 35.6881 74.2783 35.0235 74.3383 34.4193C74.4584 33.1706 74.9755 32 75.4728 30C77.4622 24 77.9596 12.5 77.9596 12.5C78.0996 11.3319 77.8195 3.32282 77.9596 2.17483C77.5194 0.422641 76.7792 -0.141251 76.7792 1.3894H76.7991Z" fill="#683824"/>
  <path d="M39 48.5H36C36 48.5 36.1911 56.013 39 56.5C41.7898 56.0362 42 48.5 42 48.5H39Z" fill="#683824"/>
</svg>`
}

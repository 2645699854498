import {
  bottomClothes,
  bottomClothesPicker,
} from "../views/profile/sections/bottomClothes/bottomClothes";
import { shoes, shoesPicker } from "../views/profile/sections/shoes/shoes";

export function getData(
  data,
  style,
  color,
  primaryColor,
  replace = /fill="[^"]*"/g
) {
  let svg = data[style];
  if (color) svg = svg?.replace(replace, `fill="${color}"`);
  if (primaryColor) {
    const regex = /fill="[^"]*"/g;
    const matches = svg?.match(regex);
    if (matches && matches.length >= 3) {
      svg = svg
        .replace(matches[1], `fill="${primaryColor}"`)
        .replace(matches[2], `fill="${primaryColor}"`)
        .replace(matches[3], `fill="${primaryColor}"`);
    }
  }
  return svg;
}
export const clothesTypes = {
  TYPE_1: "type_1",
  TYPE_2: "type_2",
  TYPE_3: "type_3",
  TYPE_4: "type_4",
};
export const clothesColors = [
  "#000000",
  "#0071CE",
  "#7D3906",
  "#E9B90C",
  "#0B6614",
  "#A7A7A7",
  "#C11717",
  "#C9E6FF",
];

const generateKeys = (key) => {
  const data = {};
  Object.values(clothesTypes).forEach((type, index) => {
    data[type] = {};
    for (let i = 0; i < 8; i++) {
      data[type][clothesColors[i]] = key + (index * 8 + i + 1);
    }
  });
  return data;
};

export const shoesKeys = generateKeys("shoes_");

export const bottomClothesKeys = generateKeys("bottom_");
// {
//   [clothesTypes.TYPE_1]: {
//     "#000000": "bottom_1",
//     "#0071CE": "bottom_2",
//     "#7D3906": "bottom_3",
//     "#E9B90C": "bottom_4",
//     "#0B6614": "bottom_5",
//     "#A7A7A7": "bottom_6",
//     "#C11717": "bottom_7",
//     "#C9E6FF": "bottom_8",
//   },
//   [clothesTypes.TYPE_2]: {
//     "#000000": "bottom_9",
//     "#0071CE": "bottom_10",
//     "#7D3906": "bottom_11",
//     "#E9B90C": "bottom_12",
//     "#0B6614": "bottom_13",
//     "#A7A7A7": "bottom_14",
//     "#C11717": "bottom_15",
//     "#C9E6FF": "bottom_16",
//   },
//   [clothesTypes.TYPE_3]: {
//     "#000000": "bottom_17",
//     "#0071CE": "bottom_18",
//     "#7D3906": "bottom_19",
//     "#E9B90C": "bottom_20",
//     "#0B6614": "bottom_21",
//     "#A7A7A7": "bottom_22",
//     "#C11717": "bottom_23",
//     "#C9E6FF": "bottom_24",
//   },
//   [clothesTypes.TYPE_4]: {
//     "#000000": "bottom_25",
//     "#0071CE": "bottom_26",
//     "#7D3906": "bottom_27",
//     "#E9B90C": "bottom_28",
//     "#0B6614": "bottom_29",
//     "#A7A7A7": "bottom_30",
//     "#C11717": "bottom_31",
//     "#C9E6FF": "bottom_32",
//   },
// };

export function getClothesData(type, color, pos) {
  const defaultColor = clothesColors[0];
  switch (pos) {
    case "bottom":
      const b =
        bottomClothesKeys[type]?.[color] ||
        bottomClothesKeys[type]?.[defaultColor];
      return bottomClothes[b || "bottom_1"];
    case "shoes":
      const s = shoesKeys[type]?.[color] || shoesKeys[type]?.[defaultColor];
      return shoes[s || "shoes_1"];
  }
}
export function getPickerData(type, color, pos) {
  const defaultColor = clothesColors[0];
  switch (pos) {
    case "bottom":
      const b =
        bottomClothesKeys[type]?.[color] ||
        bottomClothesKeys[type]?.[defaultColor];
      return bottomClothesPicker[b || "bottom_1"];
    case "shoes":
      const s = shoesKeys[type]?.[color] || shoesKeys[type]?.[defaultColor];
      return shoesPicker[s || "shoes_1"];
  }
}
export function getClothesKey(type, color, pos) {
  const defaultColor = clothesColors[0];
  switch (pos) {
    case "bottom":
      return (
        bottomClothesKeys[type]?.[color] ||
        bottomClothesKeys[type]?.[defaultColor] ||
        "bottom_1"
      );
    case "shoes":
      return (
        shoesKeys[type]?.[color] || shoesKeys[type]?.[defaultColor] || "shoes_1"
      );
  }
}

export function getPosition(type) {
  switch (type) {
    case "facial_hair_1":
      return {
        top: "72px",
      };
    case "facial_hair_2":
      return {
        top: "50px",
      };
    case "facial_hair_3":
      return {
        top: "72px",
      };
    case "facial_hair_4":
      return {
        top: "66px",
      };
    case "facial_hair_5":
      return {
        top: "45px",
      };
    case "clothe_1":
    case "clothe_2":
    case "clothe_3":
    case "clothe_4":
    case "clothe_5":
    case "clothe_13":
    case "clothe_10":
    case "clothe_11":
    case "clothe_14":
    case "clothe_18":
    case "clothe_19":
    case "clothe_20":
    case "clothe_21":
    case "clothe_22":
    case "clothe_38":
    case "clothe_39":
    case "clothe_40":
    case "clothe_41":
    case "clothe_42":
    case "clothe_43":
    case "clothe_44":
    case "clothe_45":
    case "clothe_46":
    case "clothe_47":
    case "clothe_48":
    case "clothe_49":
      return {
        top: "108px",
      };
    case "clothe_6":
    case "clothe_7":
    case "clothe_8":
    case "clothe_9":
    case "clothe_12":
    case "clothe_15":
    case "clothe_16":
    case "clothe_17":
    case "clothe_23":
    case "clothe_24":
    case "clothe_25":
    case "clothe_26":
    case "clothe_27":
    case "clothe_28":
    case "clothe_29":
    case "clothe_30":
    case "clothe_31":
    case "clothe_32":
    case "clothe_33":
    case "clothe_34":
    case "clothe_35":
    case "clothe_36":
    case "clothe_37":
    case "clothe_50":
    case "clothe_51":
    case "clothe_52":
    case "clothe_53":
    case "clothe_54":
    case "clothe_55":
    case "clothe_56":
    case "clothe_57":
    case "clothe_58":
    case "clothe_59":
    case "clothe_60":
    case "clothe_61":
    case "clothe_62":
    case "clothe_63":
      return {
        top: "122px",
      };
    case "bottom_1":
    case "bottom_2":
    case "bottom_3":
    case "bottom_4":
    case "bottom_5":
    case "bottom_6":
    case "bottom_7":
    case "bottom_8":
      return {
        bottom: "23px",
        left: "",
      };
    case "bottom_9":
    case "bottom_10":
    case "bottom_11":
    case "bottom_12":
    case "bottom_13":
    case "bottom_14":
    case "bottom_15":
    case "bottom_16":
      return {
        bottom: "68px",
        left: "0",
      };
    case "bottom_17":
    case "bottom_18":
    case "bottom_19":
    case "bottom_20":
    case "bottom_21":
    case "bottom_22":
    case "bottom_23":
    case "bottom_24":
      return {
        bottom: "44px",
        left: "",
      };
    case "bottom_25":
    case "bottom_26":
    case "bottom_27":
    case "bottom_28":
    case "bottom_29":
    case "bottom_30":
    case "bottom_31":
    case "bottom_32":
      return {
        bottom: "64px",
        left: "27px",
      };
    default: {
      return {};
    }
  }
}

export const topClothesPicker = {
  clothe_1: `<svg xmlns="http://www.w3.org/2000/svg" width="68" height="86" viewBox="0 0 68 86" fill="none">
  <path d="M0.24707 85.4367H7.01776C7.0903 85.0262 10.9109 60.2306 11.4832 55.4984C12.0152 51.1123 12.531 39.4026 12.523 32.6665C12.6278 35.781 12.8293 37.0204 13.1517 43.4346C13.3935 48.2392 13.5466 65.003 13.2081 76.2701C13.2081 76.2701 19.076 86 33.9796 86C48.8832 86 54.7672 76.2701 54.7672 76.2701C54.4287 65.003 54.6141 48.3438 54.7914 43.6358C55.009 37.9781 55.3476 35.781 55.4524 32.6665C55.4524 39.4026 55.9602 51.1043 56.4921 55.4984C57.0725 60.2306 60.7158 85.0182 60.7883 85.4367H67.7524C67.3736 73.9764 67.3011 60.6249 66.1001 50.7823C65.4552 45.5029 65.028 25.1095 65.1328 21.2465C65.2618 16.7075 64.3832 11.589 59.1682 10.7359C55.6377 10.1565 51.1159 8.61127 47.7547 7.55699C42.2253 5.82669 42.2334 0 42.2334 0C42.2334 0 36.5831 2.7041 34.2456 3.57327C33.9796 3.96762 33.9635 9.19877 33.9635 9.19877C33.9635 9.19877 33.8829 3.87105 33.6814 3.57327C31.2552 2.49485 25.8225 0 25.8225 0C25.8225 0 25.75 5.81864 20.2206 7.55699C16.8594 8.61127 12.3376 10.1565 8.80715 10.7359C3.60017 11.589 2.7216 16.7075 2.8425 21.2465C2.94728 25.1095 2.52009 45.5109 1.87526 50.7823C0.674268 60.633 0.625906 73.9845 0.24707 85.4367Z" fill="#F4F4F4"/>
  <path d="M37.0587 10.6635C37.0103 10.6635 36.9619 10.6554 36.9216 10.6313C36.841 10.5911 36.7765 10.5186 36.7524 10.4301C36.0833 8.08815 34.9468 4.61145 34.4874 4.20906C34.3504 4.08834 34.3423 3.88714 34.4632 3.75033C34.5841 3.62156 34.7856 3.60547 34.9227 3.72618C35.6158 4.33783 36.833 8.37788 37.2682 9.87479C37.9775 9.4563 39.8233 8.18473 42.717 4.90923C42.8379 4.77241 43.0394 4.76436 43.1684 4.87704C43.3054 4.99775 43.3135 5.19895 43.2006 5.32772C39.3236 9.72188 37.2763 10.5991 37.1876 10.6393C37.1473 10.6554 37.107 10.6635 37.0667 10.6635H37.0587Z" fill="#8FC5D8"/>
  <path d="M30.828 10.6635C30.7877 10.6635 30.7474 10.6554 30.7071 10.6393C30.6184 10.6072 28.5792 9.72188 24.6941 5.32772C24.5732 5.1909 24.5893 4.98971 24.7263 4.87704C24.8634 4.75632 25.0649 4.77241 25.1777 4.90923C28.0714 8.18473 29.9172 9.4563 30.6265 9.87479C31.0618 8.38593 32.2869 4.33783 32.9721 3.72618C33.1091 3.60547 33.3106 3.62156 33.4315 3.75033C33.5524 3.88714 33.5363 4.08834 33.4073 4.20906C32.9479 4.61145 31.8114 8.08815 31.1424 10.4301C31.1182 10.5186 31.0537 10.5911 30.9731 10.6313C30.9328 10.6554 30.8844 10.6635 30.8361 10.6635H30.828Z" fill="#8FC5D8"/>
  <path d="M4.64802 76.7771C3.67272 76.7771 2.55233 76.7127 1.5206 76.5196C1.34328 76.4874 1.23043 76.3184 1.26267 76.1413C1.29491 75.9643 1.46418 75.8516 1.64151 75.8838C4.14022 76.3667 7.26763 75.9884 7.29987 75.9884C7.4772 75.9642 7.6384 76.093 7.66258 76.2701C7.68677 76.4471 7.5578 76.6081 7.38047 76.6322C7.29987 76.6403 6.13112 76.7851 4.63996 76.7851L4.64802 76.7771Z" fill="#8FC5D8"/>
  <path d="M63.3354 76.7771C61.8523 76.7771 60.6754 76.6322 60.5948 76.6242C60.4175 76.6 60.2966 76.4391 60.3127 76.262C60.3369 76.085 60.4981 75.9643 60.6754 75.9804C60.7077 75.9804 63.8432 76.3586 66.3338 75.8757C66.5111 75.8435 66.6804 75.9562 66.7126 76.1333C66.7449 76.3103 66.632 76.4793 66.4547 76.5115C65.423 76.7127 64.3107 76.7691 63.3273 76.7691L63.3354 76.7771Z" fill="#8FC5D8"/>
  <path d="M33.7297 85.0182C33.5927 85.0182 33.4718 84.8733 33.4718 84.6882V7.68576C33.4718 7.50066 33.5846 7.35579 33.7297 7.35579C33.8748 7.35579 33.9877 7.50066 33.9877 7.68576V84.6882C33.9877 84.8733 33.8748 85.0182 33.7297 85.0182Z" fill="#8FC5D8"/>
  <path d="M35.7609 78.9742C35.7609 79.3202 35.4788 79.6019 35.1322 79.6019C34.7856 79.6019 34.5035 79.3202 34.5035 78.9742C34.5035 78.6281 34.7856 78.3464 35.1322 78.3464C35.4788 78.3464 35.7609 78.6281 35.7609 78.9742Z" fill="#8FC5D8"/>
  <path d="M35.7609 69.4696C35.7609 69.8157 35.4788 70.0973 35.1322 70.0973C34.7856 70.0973 34.5035 69.8157 34.5035 69.4696C34.5035 69.1235 34.7856 68.8419 35.1322 68.8419C35.4788 68.8419 35.7609 69.1235 35.7609 69.4696Z" fill="#8FC5D8"/>
  <path d="M35.7609 59.965C35.7609 60.3111 35.4788 60.5927 35.1322 60.5927C34.7856 60.5927 34.5035 60.3111 34.5035 59.965C34.5035 59.6189 34.7856 59.3373 35.1322 59.3373C35.4788 59.3373 35.7609 59.6189 35.7609 59.965Z" fill="#8FC5D8"/>
  <path d="M35.7609 50.4524C35.7609 50.7984 35.4788 51.0801 35.1322 51.0801C34.7856 51.0801 34.5035 50.7984 34.5035 50.4524C34.5035 50.1063 34.7856 49.8246 35.1322 49.8246C35.4788 49.8246 35.7609 50.1063 35.7609 50.4524Z" fill="#8FC5D8"/>
  <path d="M35.7609 40.9478C35.7609 41.2938 35.4788 41.5755 35.1322 41.5755C34.7856 41.5755 34.5035 41.2938 34.5035 40.9478C34.5035 40.6017 34.7856 40.32 35.1322 40.32C35.4788 40.32 35.7609 40.6017 35.7609 40.9478Z" fill="#8FC5D8"/>
  <path d="M35.7609 31.4432C35.7609 31.7893 35.4788 32.0709 35.1322 32.0709C34.7856 32.0709 34.5035 31.7893 34.5035 31.4432C34.5035 31.0971 34.7856 30.8155 35.1322 30.8155C35.4788 30.8155 35.7609 31.0971 35.7609 31.4432Z" fill="#8FC5D8"/>
  <path d="M35.7609 21.9386C35.7609 22.2847 35.4788 22.5664 35.1322 22.5664C34.7856 22.5664 34.5035 22.2847 34.5035 21.9386C34.5035 21.5926 34.7856 21.3109 35.1322 21.3109C35.4788 21.3109 35.7609 21.5926 35.7609 21.9386Z" fill="#8FC5D8"/>
  <path d="M35.7609 12.426C35.7609 12.772 35.4788 13.0537 35.1322 13.0537C34.7856 13.0537 34.5035 12.772 34.5035 12.426C34.5035 12.0799 34.7856 11.7982 35.1322 11.7982C35.4788 11.7982 35.7609 12.0799 35.7609 12.426Z" fill="#8FC5D8"/>
  <path d="M35.7609 8.2974C35.7609 8.64346 35.4788 8.92514 35.1322 8.92514C34.7856 8.92514 34.5035 8.64346 34.5035 8.2974C34.5035 7.95134 34.7856 7.66966 35.1322 7.66966C35.4788 7.66966 35.7609 7.95134 35.7609 8.2974Z" fill="#8FC5D8"/>
</svg>`,
  clothe_2: `<svg xmlns="http://www.w3.org/2000/svg" width="68" height="86" viewBox="0 0 68 86" fill="none">
  <path d="M0.299316 85.4366H7.05956C7.13199 85.0262 10.9467 60.2306 11.5181 55.4984C12.0493 51.1123 12.5643 39.4026 12.5563 32.6665C12.6609 35.781 12.8621 37.0204 13.184 43.4346C13.4255 48.2392 13.5784 65.003 13.2403 76.2701C13.2403 76.2701 19.0992 86 33.9798 86C48.8604 86 54.7354 76.2701 54.7354 76.2701C54.3973 65.003 54.5824 48.3438 54.7595 43.6358C54.9768 37.9781 54.9474 35.8864 55.052 32.7718C55.052 39.5079 55.9264 51.1042 56.4576 55.4984C57.0371 60.2306 60.6747 85.0182 60.7471 85.4366H67.7005C67.3223 73.9764 67.2498 60.6249 66.0507 50.7823C65.4069 45.5029 64.9803 25.1095 65.085 21.2465C65.2137 16.7075 64.3365 11.589 59.1295 10.7359C55.6045 10.1565 51.0897 8.61127 47.7337 7.55699C42.2128 5.82669 42.2209 0 42.2209 0C42.2209 0 36.5793 2.7041 34.2454 3.57327C33.9798 3.96762 33.9637 9.19877 33.9637 9.19877C33.9637 9.19877 33.8832 3.87105 33.682 3.57327C31.2596 2.49485 25.8353 0 25.8353 0C25.8353 0 25.7629 5.81864 20.242 7.55699C16.8861 8.61127 12.3712 10.1565 8.84619 10.7359C3.64724 11.589 2.77002 16.7075 2.89074 21.2465C2.99537 25.1095 2.56883 45.5109 1.92499 50.7823C0.725856 60.633 0.677568 73.9845 0.299316 85.4366Z" fill="#FFDEC0"/>
  <path d="M33.4322 6.2038L32.6837 4.79541L32.1123 6.31647C32.1123 6.31647 32.1928 6.63838 32.7722 7.1454C32.901 6.39695 33.4322 6.2038 33.4322 6.2038Z" fill="#BF062E"/>
  <path d="M34.3989 6.2038L34.9945 4.79541L35.5739 5.74506C35.5739 5.74506 35.6785 6.23599 35.3325 6.42109C35.2037 6.2038 34.3989 6.2038 34.3989 6.2038Z" fill="#BF062E"/>
  <path d="M38.116 73.8149C38.0597 71.4891 36.933 33.2776 36.3938 17.1979C36.2328 12.4738 35.0578 11.009 35.1061 10.0352C35.2429 7.35528 32.2652 8.07154 32.6515 10.3974C32.7883 11.2344 31.0097 13.4636 30.712 17.2301C29.4565 33.2454 29.2312 71.4891 29.1748 73.8149C29.1668 74.2334 33.6253 79.529 33.6253 79.529C33.6253 79.529 38.1241 74.2415 38.116 73.8149Z" fill="#D60B2D"/>
  <mask id="mask0_607_15803" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="29" y="8" width="10" height="72">
    <path d="M38.116 73.8149C38.0597 71.4891 36.933 33.2776 36.3938 17.1979C36.2328 12.4738 35.0578 11.009 35.1061 10.0352C35.2429 7.35528 32.2652 8.07154 32.6515 10.3974C32.7883 11.2344 31.0097 13.4636 30.712 17.2301C29.4565 33.2454 29.2312 71.4891 29.1748 73.8149C29.1668 74.2334 33.6253 79.529 33.6253 79.529C33.6253 79.529 38.1241 74.2415 38.116 73.8149Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_607_15803)">
    <path d="M28.6616 -19.178L-44.231 53.7146L-43.679 54.2666L29.2136 -18.626L28.6616 -19.178Z" fill="#BF062E"/>
    <path d="M30.4106 -17.4166L-42.4819 55.4761L-41.9299 56.0281L30.9626 -16.8646L30.4106 -17.4166Z" fill="#BF062E"/>
    <path d="M32.1553 -15.67L-40.7373 57.2227L-40.1853 57.7747L32.7073 -15.118L32.1553 -15.67Z" fill="#BF062E"/>
    <path d="M33.9062 -13.9246L-38.9736 58.9807L-38.4215 59.5326L34.4583 -13.3727L33.9062 -13.9246Z" fill="#BF062E"/>
    <path d="M35.6694 -12.169L-37.2231 60.7236L-36.6711 61.2756L36.2214 -11.617L35.6694 -12.169Z" fill="#BF062E"/>
    <path d="M37.4209 -10.4166L-35.4717 62.4761L-34.9197 63.0281L37.9729 -9.86455L37.4209 -10.4166Z" fill="#BF062E"/>
    <path d="M39.1719 -8.66777L-33.7207 64.2249L-33.1687 64.7769L39.7239 -8.11577L39.1719 -8.66777Z" fill="#BF062E"/>
    <path d="M40.9209 -6.91411L-31.9717 65.9785L-31.4197 66.5305L41.4729 -6.36211L40.9209 -6.91411Z" fill="#BF062E"/>
    <path d="M42.6772 -5.16753L-30.2153 67.7251L-29.6633 68.2771L43.2292 -4.61553L42.6772 -5.16753Z" fill="#BF062E"/>
    <path d="M44.4228 -3.41362L-28.4697 69.479L-27.9177 70.031L44.9748 -2.86162L44.4228 -3.41362Z" fill="#BF062E"/>
    <path d="M46.1797 -1.6502L-26.7129 71.2424L-26.1609 71.7944L46.7317 -1.09819L46.1797 -1.6502Z" fill="#BF062E"/>
    <path d="M47.9365 0.096386L-24.9561 72.989L-24.4041 73.541L48.4885 0.648388L47.9365 0.096386Z" fill="#BF062E"/>
    <path d="M49.6797 1.8564L-23.2129 74.749L-22.6609 75.301L50.2317 2.4084L49.6797 1.8564Z" fill="#BF062E"/>
    <path d="M51.4307 3.60493L-21.4619 76.4976L-20.9099 77.0496L51.9826 4.15693L51.4307 3.60493Z" fill="#BF062E"/>
    <path d="M53.1875 5.35151L-19.7051 78.2441L-19.1531 78.7961L53.7395 5.90351L53.1875 5.35151Z" fill="#BF062E"/>
    <path d="M54.9443 7.11372L-17.9482 80.0063L-17.3962 80.5583L55.4963 7.66572L54.9443 7.11372Z" fill="#BF062E"/>
    <path d="M56.6899 8.86885L-16.2026 81.7615L-15.6506 82.3135L57.2419 9.42085L56.6899 8.86885Z" fill="#BF062E"/>
    <path d="M58.4468 10.6149L-14.4458 83.5076L-13.8938 84.0596L58.9988 11.1669L58.4468 10.6149Z" fill="#BF062E"/>
    <path d="M60.1953 12.3691L-12.6973 85.2617L-12.1453 85.8137L60.7473 12.9211L60.1953 12.3691Z" fill="#BF062E"/>
    <path d="M61.9463 14.1218L-10.9463 87.0144L-10.3943 87.5664L62.4983 14.6738L61.9463 14.1218Z" fill="#BF062E"/>
    <path d="M63.6977 15.8618L-9.19482 88.7544L-8.64282 89.3064L64.2497 16.4138L63.6977 15.8618Z" fill="#BF062E"/>
    <path d="M65.4546 17.6079L-7.43799 90.5005L-6.88599 91.0525L66.0066 18.1599L65.4546 17.6079Z" fill="#BF062E"/>
    <path d="M67.2114 19.363L-5.68115 92.2556L-5.12915 92.8076L67.7634 19.915L67.2114 19.363Z" fill="#BF062E"/>
    <path d="M68.9487 21.1174L-3.94385 94.01L-3.39185 94.562L69.5007 21.6694L68.9487 21.1174Z" fill="#BF062E"/>
    <path d="M70.7056 22.8718L-2.18701 95.7644L-1.63501 96.3164L71.2576 23.4238L70.7056 22.8718Z" fill="#BF062E"/>
    <path d="M72.4624 24.6264L-0.430176 97.519L0.121826 98.071L73.0144 25.1784L72.4624 24.6264Z" fill="#BF062E"/>
    <path d="M74.2139 26.3862L1.32129 99.2788L1.87329 99.8308L74.7659 26.9382L74.2139 26.3862Z" fill="#BF062E"/>
    <path d="M75.9648 28.135L3.07227 101.028L3.62427 101.58L76.5168 28.687L75.9648 28.135Z" fill="#BF062E"/>
    <path d="M77.7222 29.8815L4.82959 102.774L5.38159 103.326L78.2742 30.4335L77.7222 29.8815Z" fill="#BF062E"/>
    <path d="M79.4707 31.6364L6.57812 104.529L7.13013 105.081L80.0227 32.1884L79.4707 31.6364Z" fill="#BF062E"/>
    <path d="M81.2158 33.3984L8.32324 106.291L8.87524 106.843L81.7678 33.9504L81.2158 33.3984Z" fill="#BF062E"/>
    <path d="M82.9731 35.1528L10.0806 108.045L10.6326 108.597L83.5251 35.7048L82.9731 35.1528Z" fill="#BF062E"/>
    <path d="M84.7295 36.8989L11.8369 109.792L12.3889 110.344L85.2815 37.4509L84.7295 36.8989Z" fill="#BF062E"/>
    <path d="M86.4809 38.6596L13.5884 111.552L14.1404 112.104L87.0329 39.2116L86.4809 38.6596Z" fill="#BF062E"/>
    <path d="M88.2241 40.4001L15.3315 113.293L15.8835 113.845L88.7761 40.9521L88.2241 40.4001Z" fill="#BF062E"/>
    <path d="M89.9805 42.1389L17.0879 115.031L17.6399 115.583L90.5325 42.6909L89.9805 42.1389Z" fill="#BF062E"/>
    <path d="M91.7378 43.8925L18.8452 116.785L19.3972 117.337L92.2898 44.4445L91.7378 43.8925Z" fill="#BF062E"/>
    <path d="M93.4834 45.6391L20.5908 118.532L21.1428 119.084L94.0354 46.1911L93.4834 45.6391Z" fill="#BF062E"/>
    <path d="M95.2397 47.3935L22.3472 120.286L22.8992 120.838L95.7917 47.9455L95.2397 47.3935Z" fill="#BF062E"/>
    <path d="M96.9966 49.1564L24.104 122.049L24.656 122.601L97.5486 49.7084L96.9966 49.1564Z" fill="#BF062E"/>
    <path d="M98.7397 50.916L25.8472 123.809L26.3992 124.361L99.2917 51.468L98.7397 50.916Z" fill="#BF062E"/>
  </g>
  <path d="M35.8465 6.1635C35.7821 5.56795 34.929 5.7611 33.8104 5.7611C32.6917 5.7611 32.0881 5.60819 32.0237 6.20373C31.9593 6.79928 31.5409 7.57188 31.5731 7.93404C31.5891 8.14328 31.8789 11.0808 32.4664 10.8876C33.8104 10.4369 35.3475 10.6784 35.4602 10.4772C35.7419 9.97821 36.2811 8.27205 36.3294 8.0628C36.4179 7.66846 35.9189 6.75904 35.8545 6.1635H35.8465Z" fill="#EA1840"/>
  <path d="M37.0547 10.6556C37.0064 10.6556 36.9582 10.6476 36.9179 10.6234C36.8374 10.5832 36.7731 10.5108 36.7489 10.4222C36.0809 8.08029 34.9462 4.60359 34.4874 4.2012C34.3506 4.08048 34.3426 3.87928 34.4633 3.74247C34.584 3.6137 34.7852 3.5976 34.922 3.71832C35.6141 4.32996 36.8294 8.37002 37.264 9.86693C37.9722 9.44844 39.8152 8.17687 42.7044 4.90137C42.8251 4.76455 43.0263 4.7565 43.155 4.86917C43.2919 4.98989 43.2999 5.19109 43.1872 5.31986C39.3162 9.71402 37.272 10.5912 37.1835 10.6315C37.1432 10.6476 37.103 10.6556 37.0628 10.6556H37.0547Z" fill="#EFB88B"/>
  <path d="M30.842 10.6556C30.8018 10.6556 30.7615 10.6476 30.7213 10.6315C30.6328 10.5993 28.5966 9.71402 24.7176 5.31986C24.5968 5.18304 24.6129 4.98184 24.7497 4.86917C24.8866 4.74845 25.0878 4.76455 25.2004 4.90137C28.0896 8.17687 29.9326 9.44844 30.6408 9.86693C31.0754 8.37806 32.2987 4.32996 32.9828 3.71832C33.1196 3.5976 33.3208 3.6137 33.4415 3.74247C33.5622 3.87928 33.5461 4.08048 33.4173 4.2012C32.9586 4.60359 31.8239 8.08029 31.1559 10.4222C31.1317 10.5108 31.0674 10.5832 30.9869 10.6234C30.9466 10.6476 30.8984 10.6556 30.8501 10.6556H30.842Z" fill="#EFB88B"/>
  <path d="M4.69469 76.7699C3.72089 76.7699 2.60223 76.7055 1.5721 76.5124C1.39504 76.4802 1.28237 76.3112 1.31456 76.1341C1.34675 75.9571 1.51576 75.8444 1.69281 75.8766C4.18766 76.3594 7.31026 75.9812 7.34245 75.9812C7.5195 75.9571 7.68046 76.0858 7.7046 76.2629C7.72874 76.4399 7.59998 76.6009 7.42293 76.625C7.34245 76.6331 6.1755 76.7779 4.68664 76.7779L4.69469 76.7699Z" fill="#EFB88B"/>
  <path d="M63.2987 76.7696C61.8179 76.7696 60.6429 76.6248 60.5624 76.6167C60.3854 76.5926 60.2646 76.4316 60.2807 76.2546C60.3049 76.0775 60.4658 75.9568 60.6429 75.9729C60.6751 75.9729 63.8057 76.3511 66.2925 75.8683C66.4696 75.8361 66.6386 75.9488 66.6708 76.1258C66.703 76.3029 66.5903 76.4719 66.4132 76.5041C65.3831 76.7053 64.2725 76.7616 63.2907 76.7616L63.2987 76.7696Z" fill="#EFB88B"/>
</svg>`,
  clothe_3: `<svg xmlns="http://www.w3.org/2000/svg" width="70" height="94" viewBox="0 0 70 94" fill="none">
  <g clip-path="url(#clip0_607_15864)">
    <path d="M11.8959 39.5694C12.0179 41.3091 12.1747 43.4383 12.3751 47.4631C12.6365 52.6303 12.6017 70.7027 12.227 82.8203C12.227 82.8203 18.771 93.25 34.8826 93.25C50.9942 93.25 57.5643 82.6472 57.5643 82.6472C57.1983 70.5296 57.1896 52.7515 57.3813 47.6881C57.6166 41.6034 57.9825 39.2405 58.0958 35.8908C58.0958 36.9987 58.1307 39.5694 58.1307 39.5694L69.2929 36.8948C69.2929 36.8948 68.5174 25.2966 68.5697 23.6002C68.7091 18.7186 67.7593 13.2137 62.1215 12.2963C58.3049 11.6731 53.4166 10.0113 49.783 8.8774C43.8054 7.01649 43.8141 0.75 43.8141 0.75C43.8141 0.75 37.7058 3.65821 35.1788 4.59299C34.8913 5.0171 34.8739 10.6431 34.8739 10.6431C34.8739 10.6431 34.7867 4.91324 34.5689 4.59299C31.9461 3.43317 26.073 0.75 26.073 0.75C26.073 0.75 25.9946 7.00784 20.017 8.8774C16.3834 10.0113 11.4951 11.6731 7.67846 12.2963C3.13864 13.032 1.63988 16.7451 1.30005 20.718C1.22163 21.6787 0.70752 36.9035 0.70752 36.9035C0.70752 36.9035 6.15357 39.0847 11.8959 39.5867V39.5694Z" fill="#CFE9FF"/>
    <path d="M34.3343 7.42289L33.5239 5.9082L32.9053 7.54407C32.9053 7.54407 32.9924 7.89028 33.6198 8.43557C33.7592 7.63062 34.3343 7.42289 34.3343 7.42289Z" fill="#353535"/>
    <path d="M35.3711 7.42289L36.0159 5.9082L36.6433 6.92954C36.6433 6.92954 36.7566 7.45752 36.3819 7.65659C36.2425 7.42289 35.3711 7.42289 35.3711 7.42289Z" fill="#353535"/>
    <path d="M39.3962 80.1459C39.3352 77.6445 38.1153 36.5488 37.5315 19.2554C37.3572 14.1747 36.085 12.5994 36.1373 11.5521C36.2855 8.66985 33.0614 9.44018 33.4797 11.9416C33.6278 12.8417 31.7021 15.2393 31.3797 19.29C30.0203 36.5142 29.7763 77.6445 29.7153 80.1459C29.7066 80.596 34.534 86.2913 34.534 86.2913C34.534 86.2913 39.405 80.6047 39.3962 80.1459Z" fill="#353535"/>
    <mask id="mask0_607_15864" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="29" y="9" width="11" height="78">
      <path d="M39.3962 80.1459C39.3352 77.6445 38.1153 36.5488 37.5315 19.2554C37.3572 14.1747 36.085 12.5994 36.1373 11.5521C36.2855 8.66985 33.0614 9.44018 33.4797 11.9416C33.6278 12.8417 31.7021 15.2393 31.3797 19.29C30.0203 36.5142 29.7763 77.6445 29.7153 80.1459C29.7066 80.596 34.534 86.2913 34.534 86.2913C34.534 86.2913 39.405 80.6047 39.3962 80.1459Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_607_15864)">
      <path d="M30.0725 11.6121C30.0725 11.9583 29.7849 12.2439 29.4364 12.2439C29.0878 12.2439 28.8003 11.9583 28.8003 11.6121C28.8003 11.2659 29.0878 10.9802 29.4364 10.9802C29.7849 10.9802 30.0725 11.2659 30.0725 11.6121Z" fill="black"/>
      <path d="M33.8894 11.6121C33.8894 11.9583 33.6018 12.2439 33.2533 12.2439C32.9047 12.2439 32.6172 11.9583 32.6172 11.6121C32.6172 11.2659 32.9047 10.9802 33.2533 10.9802C33.6018 10.9802 33.8894 11.2659 33.8894 11.6121Z" fill="black"/>
      <path d="M37.7146 11.6121C37.7146 11.9583 37.427 12.2439 37.0785 12.2439C36.7299 12.2439 36.4424 11.9583 36.4424 11.6121C36.4424 11.2659 36.7299 10.9802 37.0785 10.9802C37.427 10.9802 37.7146 11.2659 37.7146 11.6121Z" fill="black"/>
      <path d="M31.9284 13.6804C31.9284 14.0266 31.6409 14.3123 31.2923 14.3123C30.9438 14.3123 30.6562 14.0266 30.6562 13.6804C30.6562 13.3342 30.9438 13.0486 31.2923 13.0486C31.6409 13.0486 31.9284 13.3342 31.9284 13.6804Z" fill="black"/>
      <path d="M35.1092 14.3123C35.4606 14.3123 35.7453 14.0294 35.7453 13.6804C35.7453 13.3315 35.4606 13.0486 35.1092 13.0486C34.7579 13.0486 34.4731 13.3315 34.4731 13.6804C34.4731 14.0294 34.7579 14.3123 35.1092 14.3123Z" fill="black"/>
      <path d="M39.5705 13.6804C39.5705 14.0266 39.283 14.3123 38.9344 14.3123C38.5859 14.3123 38.2983 14.0266 38.2983 13.6804C38.2983 13.3342 38.5859 13.0486 38.9344 13.0486C39.283 13.0486 39.5705 13.3342 39.5705 13.6804Z" fill="black"/>
      <path d="M30.0725 15.9661C30.0725 16.3123 29.7849 16.5979 29.4364 16.5979C29.0878 16.5979 28.8003 16.3123 28.8003 15.9661C28.8003 15.6199 29.0878 15.3342 29.4364 15.3342C29.7849 15.3342 30.0725 15.6199 30.0725 15.9661Z" fill="black"/>
      <path d="M33.8894 15.9661C33.8894 16.3123 33.6018 16.5979 33.2533 16.5979C32.9047 16.5979 32.6172 16.3123 32.6172 15.9661C32.6172 15.6199 32.9047 15.3342 33.2533 15.3342C33.6018 15.3342 33.8894 15.6199 33.8894 15.9661Z" fill="black"/>
      <path d="M37.7146 15.9661C37.7146 16.3123 37.427 16.5979 37.0785 16.5979C36.7299 16.5979 36.4424 16.3123 36.4424 15.9661C36.4424 15.6199 36.7299 15.3342 37.0785 15.3342C37.427 15.3342 37.7146 15.6199 37.7146 15.9661Z" fill="black"/>
      <path d="M31.9284 18.0344C31.9284 18.3806 31.6409 18.6663 31.2923 18.6663C30.9438 18.6663 30.6562 18.3806 30.6562 18.0344C30.6562 17.6882 30.9438 17.4026 31.2923 17.4026C31.6409 17.4026 31.9284 17.6882 31.9284 18.0344Z" fill="black"/>
      <path d="M35.7453 18.0344C35.7453 18.3806 35.4578 18.6663 35.1092 18.6663C34.7607 18.6663 34.4731 18.3806 34.4731 18.0344C34.4731 17.6882 34.7607 17.4026 35.1092 17.4026C35.4578 17.4026 35.7453 17.6882 35.7453 18.0344Z" fill="black"/>
      <path d="M39.5705 18.0344C39.5705 18.3806 39.283 18.6663 38.9344 18.6663C38.5859 18.6663 38.2983 18.3806 38.2983 18.0344C38.2983 17.6882 38.5859 17.4026 38.9344 17.4026C39.283 17.4026 39.5705 17.6882 39.5705 18.0344Z" fill="black"/>
      <path d="M30.0725 20.3196C30.0725 20.6658 29.7849 20.9514 29.4364 20.9514C29.0878 20.9514 28.8003 20.6658 28.8003 20.3196C28.8003 19.9734 29.0878 19.6877 29.4364 19.6877C29.7849 19.6877 30.0725 19.9734 30.0725 20.3196Z" fill="black"/>
      <path d="M33.8894 20.3196C33.8894 20.6658 33.6018 20.9514 33.2533 20.9514C32.9047 20.9514 32.6172 20.6658 32.6172 20.3196C32.6172 19.9734 32.9047 19.6877 33.2533 19.6877C33.6018 19.6877 33.8894 19.9734 33.8894 20.3196Z" fill="black"/>
      <path d="M37.7146 20.3196C37.7146 20.6658 37.427 20.9514 37.0785 20.9514C36.7299 20.9514 36.4424 20.6658 36.4424 20.3196C36.4424 19.9734 36.7299 19.6877 37.0785 19.6877C37.427 19.6877 37.7146 19.9734 37.7146 20.3196Z" fill="black"/>
      <path d="M31.9284 22.3884C31.9284 22.7346 31.6409 23.0203 31.2923 23.0203C30.9438 23.0203 30.6562 22.7346 30.6562 22.3884C30.6562 22.0422 30.9438 21.7566 31.2923 21.7566C31.6409 21.7566 31.9284 22.0422 31.9284 22.3884Z" fill="black"/>
      <path d="M35.7453 22.3884C35.7453 22.7346 35.4578 23.0203 35.1092 23.0203C34.7607 23.0203 34.4731 22.7346 34.4731 22.3884C34.4731 22.0422 34.7607 21.7566 35.1092 21.7566C35.4578 21.7566 35.7453 22.0422 35.7453 22.3884Z" fill="black"/>
      <path d="M39.5705 22.3884C39.5705 22.7346 39.283 23.0203 38.9344 23.0203C38.5859 23.0203 38.2983 22.7346 38.2983 22.3884C38.2983 22.0422 38.5859 21.7566 38.9344 21.7566C39.283 21.7566 39.5705 22.0422 39.5705 22.3884Z" fill="black"/>
      <path d="M30.0725 24.6729C30.0725 25.0191 29.7849 25.3047 29.4364 25.3047C29.0878 25.3047 28.8003 25.0191 28.8003 24.6729C28.8003 24.3266 29.0878 24.041 29.4364 24.041C29.7849 24.041 30.0725 24.3266 30.0725 24.6729Z" fill="black"/>
      <path d="M33.8894 24.6729C33.8894 25.0191 33.6018 25.3047 33.2533 25.3047C32.9047 25.3047 32.6172 25.0191 32.6172 24.6729C32.6172 24.3266 32.9047 24.041 33.2533 24.041C33.6018 24.041 33.8894 24.3266 33.8894 24.6729Z" fill="black"/>
      <path d="M37.7146 24.6729C37.7146 25.0191 37.427 25.3047 37.0785 25.3047C36.7299 25.3047 36.4424 25.0191 36.4424 24.6729C36.4424 24.3266 36.7299 24.041 37.0785 24.041C37.427 24.041 37.7146 24.3266 37.7146 24.6729Z" fill="black"/>
      <path d="M31.9284 26.742C31.9284 27.0882 31.6409 27.3738 31.2923 27.3738C30.9438 27.3738 30.6562 27.0882 30.6562 26.742C30.6562 26.3957 30.9438 26.1101 31.2923 26.1101C31.6409 26.1101 31.9284 26.3957 31.9284 26.742Z" fill="black"/>
      <path d="M35.7453 26.742C35.7453 27.0882 35.4578 27.3738 35.1092 27.3738C34.7607 27.3738 34.4731 27.0882 34.4731 26.742C34.4731 26.3957 34.7607 26.1101 35.1092 26.1101C35.4578 26.1101 35.7453 26.3957 35.7453 26.742Z" fill="black"/>
      <path d="M39.5705 26.742C39.5705 27.0882 39.283 27.3738 38.9344 27.3738C38.5859 27.3738 38.2983 27.0882 38.2983 26.742C38.2983 26.3957 38.5859 26.1101 38.9344 26.1101C39.283 26.1101 39.5705 26.3957 39.5705 26.742Z" fill="black"/>
      <path d="M30.0725 29.0269C30.0725 29.3731 29.7849 29.6587 29.4364 29.6587C29.0878 29.6587 28.8003 29.3731 28.8003 29.0269C28.8003 28.6806 29.0878 28.395 29.4364 28.395C29.7849 28.395 30.0725 28.6806 30.0725 29.0269Z" fill="black"/>
      <path d="M33.8894 29.0269C33.8894 29.3731 33.6018 29.6587 33.2533 29.6587C32.9047 29.6587 32.6172 29.3731 32.6172 29.0269C32.6172 28.6806 32.9047 28.395 33.2533 28.395C33.6018 28.395 33.8894 28.6806 33.8894 29.0269Z" fill="black"/>
      <path d="M37.7146 29.0269C37.7146 29.3731 37.427 29.6587 37.0785 29.6587C36.7299 29.6587 36.4424 29.3731 36.4424 29.0269C36.4424 28.6806 36.7299 28.395 37.0785 28.395C37.427 28.395 37.7146 28.6806 37.7146 29.0269Z" fill="black"/>
      <path d="M31.9284 31.096C31.9284 31.4422 31.6409 31.7278 31.2923 31.7278C30.9438 31.7278 30.6562 31.4422 30.6562 31.096C30.6562 30.7497 30.9438 30.4641 31.2923 30.4641C31.6409 30.4641 31.9284 30.7497 31.9284 31.096Z" fill="black"/>
      <path d="M35.7453 31.096C35.7453 31.4422 35.4578 31.7278 35.1092 31.7278C34.7607 31.7278 34.4731 31.4422 34.4731 31.096C34.4731 30.7497 34.7607 30.4641 35.1092 30.4641C35.4578 30.4641 35.7453 30.7497 35.7453 31.096Z" fill="black"/>
      <path d="M39.5705 31.096C39.5705 31.4422 39.283 31.7278 38.9344 31.7278C38.5859 31.7278 38.2983 31.4422 38.2983 31.096C38.2983 30.7497 38.5859 30.4641 38.9344 30.4641C39.283 30.4641 39.5705 30.7497 39.5705 31.096Z" fill="black"/>
      <path d="M30.0725 33.3809C30.0725 33.7271 29.7849 34.0127 29.4364 34.0127C29.0878 34.0127 28.8003 33.7271 28.8003 33.3809C28.8003 33.0347 29.0878 32.749 29.4364 32.749C29.7849 32.749 30.0725 33.0347 30.0725 33.3809Z" fill="black"/>
      <path d="M33.8894 33.3809C33.8894 33.7271 33.6018 34.0127 33.2533 34.0127C32.9047 34.0127 32.6172 33.7271 32.6172 33.3809C32.6172 33.0347 32.9047 32.749 33.2533 32.749C33.6018 32.749 33.8894 33.0347 33.8894 33.3809Z" fill="black"/>
      <path d="M37.7146 33.3809C37.7146 33.7271 37.427 34.0127 37.0785 34.0127C36.7299 34.0127 36.4424 33.7271 36.4424 33.3809C36.4424 33.0347 36.7299 32.749 37.0785 32.749C37.427 32.749 37.7146 33.0347 37.7146 33.3809Z" fill="black"/>
      <path d="M31.9284 35.4492C31.9284 35.7954 31.6409 36.0811 31.2923 36.0811C30.9438 36.0811 30.6562 35.7954 30.6562 35.4492C30.6562 35.103 30.9438 34.8174 31.2923 34.8174C31.6409 34.8174 31.9284 35.103 31.9284 35.4492Z" fill="black"/>
      <path d="M35.7453 35.4492C35.7453 35.7954 35.4578 36.0811 35.1092 36.0811C34.7607 36.0811 34.4731 35.7954 34.4731 35.4492C34.4731 35.103 34.7607 34.8174 35.1092 34.8174C35.4578 34.8174 35.7453 35.103 35.7453 35.4492Z" fill="black"/>
      <path d="M39.5705 35.4492C39.5705 35.7954 39.283 36.0811 38.9344 36.0811C38.5859 36.0811 38.2983 35.7954 38.2983 35.4492C38.2983 35.103 38.5859 34.8174 38.9344 34.8174C39.283 34.8174 39.5705 35.103 39.5705 35.4492Z" fill="black"/>
      <path d="M30.0725 37.7256C30.0725 38.0718 29.7849 38.3574 29.4364 38.3574C29.0878 38.3574 28.8003 38.0718 28.8003 37.7256C28.8003 37.3794 29.0878 37.0938 29.4364 37.0938C29.7849 37.0938 30.0725 37.3794 30.0725 37.7256Z" fill="black"/>
      <path d="M33.8894 37.7256C33.8894 38.0718 33.6018 38.3574 33.2533 38.3574C32.9047 38.3574 32.6172 38.0718 32.6172 37.7256C32.6172 37.3794 32.9047 37.0938 33.2533 37.0938C33.6018 37.0938 33.8894 37.3794 33.8894 37.7256Z" fill="black"/>
      <path d="M37.7146 37.7256C37.7146 38.0718 37.427 38.3574 37.0785 38.3574C36.7299 38.3574 36.4424 38.0718 36.4424 37.7256C36.4424 37.3794 36.7299 37.0938 37.0785 37.0938C37.427 37.0938 37.7146 37.3794 37.7146 37.7256Z" fill="black"/>
      <path d="M31.9284 39.7944C31.9284 40.1407 31.6409 40.4263 31.2923 40.4263C30.9438 40.4263 30.6562 40.1407 30.6562 39.7944C30.6562 39.4482 30.9438 39.1626 31.2923 39.1626C31.6409 39.1626 31.9284 39.4482 31.9284 39.7944Z" fill="black"/>
      <path d="M35.7453 39.7944C35.7453 40.1407 35.4578 40.4263 35.1092 40.4263C34.7607 40.4263 34.4731 40.1407 34.4731 39.7944C34.4731 39.4482 34.7607 39.1626 35.1092 39.1626C35.4578 39.1626 35.7453 39.4482 35.7453 39.7944Z" fill="black"/>
      <path d="M39.5705 39.7944C39.5705 40.1407 39.283 40.4263 38.9344 40.4263C38.5859 40.4263 38.2983 40.1407 38.2983 39.7944C38.2983 39.4482 38.5859 39.1626 38.9344 39.1626C39.283 39.1626 39.5705 39.4482 39.5705 39.7944Z" fill="black"/>
      <path d="M30.0725 42.0796C30.0725 42.4258 29.7849 42.7114 29.4364 42.7114C29.0878 42.7114 28.8003 42.4258 28.8003 42.0796C28.8003 41.7334 29.0878 41.4478 29.4364 41.4478C29.7849 41.4478 30.0725 41.7334 30.0725 42.0796Z" fill="black"/>
      <path d="M33.8894 42.0796C33.8894 42.4258 33.6018 42.7114 33.2533 42.7114C32.9047 42.7114 32.6172 42.4258 32.6172 42.0796C32.6172 41.7334 32.9047 41.4478 33.2533 41.4478C33.6018 41.4478 33.8894 41.7334 33.8894 42.0796Z" fill="black"/>
      <path d="M37.7146 42.0796C37.7146 42.4258 37.427 42.7114 37.0785 42.7114C36.7299 42.7114 36.4424 42.4258 36.4424 42.0796C36.4424 41.7334 36.7299 41.4478 37.0785 41.4478C37.427 41.4478 37.7146 41.7334 37.7146 42.0796Z" fill="black"/>
      <path d="M31.9284 44.148C31.9284 44.4942 31.6409 44.7798 31.2923 44.7798C30.9438 44.7798 30.6562 44.4942 30.6562 44.148C30.6562 43.8017 30.9438 43.5161 31.2923 43.5161C31.6409 43.5161 31.9284 43.8017 31.9284 44.148Z" fill="black"/>
      <path d="M35.7453 44.148C35.7453 44.4942 35.4578 44.7798 35.1092 44.7798C34.7607 44.7798 34.4731 44.4942 34.4731 44.148C34.4731 43.8017 34.7607 43.5161 35.1092 43.5161C35.4578 43.5161 35.7453 43.8017 35.7453 44.148Z" fill="black"/>
      <path d="M39.5705 44.148C39.5705 44.4942 39.283 44.7798 38.9344 44.7798C38.5859 44.7798 38.2983 44.4942 38.2983 44.148C38.2983 43.8017 38.5859 43.5161 38.9344 43.5161C39.283 43.5161 39.5705 43.8017 39.5705 44.148Z" fill="black"/>
      <path d="M30.0725 46.4331C30.0725 46.7793 29.7849 47.065 29.4364 47.065C29.0878 47.065 28.8003 46.7793 28.8003 46.4331C28.8003 46.0869 29.0878 45.8013 29.4364 45.8013C29.7849 45.8013 30.0725 46.0869 30.0725 46.4331Z" fill="black"/>
      <path d="M33.8894 46.4331C33.8894 46.7793 33.6018 47.065 33.2533 47.065C32.9047 47.065 32.6172 46.7793 32.6172 46.4331C32.6172 46.0869 32.9047 45.8013 33.2533 45.8013C33.6018 45.8013 33.8894 46.0869 33.8894 46.4331Z" fill="black"/>
      <path d="M37.7146 46.4331C37.7146 46.7793 37.427 47.065 37.0785 47.065C36.7299 47.065 36.4424 46.7793 36.4424 46.4331C36.4424 46.0869 36.7299 45.8013 37.0785 45.8013C37.427 45.8013 37.7146 46.0869 37.7146 46.4331Z" fill="black"/>
      <path d="M31.9284 48.501C31.9284 48.8472 31.6409 49.1328 31.2923 49.1328C30.9438 49.1328 30.6562 48.8472 30.6562 48.501C30.6562 48.1548 30.9438 47.8691 31.2923 47.8691C31.6409 47.8691 31.9284 48.1548 31.9284 48.501Z" fill="black"/>
      <path d="M35.7453 48.501C35.7453 48.8472 35.4578 49.1328 35.1092 49.1328C34.7607 49.1328 34.4731 48.8472 34.4731 48.501C34.4731 48.1548 34.7607 47.8691 35.1092 47.8691C35.4578 47.8691 35.7453 48.1548 35.7453 48.501Z" fill="black"/>
      <path d="M39.5705 48.501C39.5705 48.8472 39.283 49.1328 38.9344 49.1328C38.5859 49.1328 38.2983 48.8472 38.2983 48.501C38.2983 48.1548 38.5859 47.8691 38.9344 47.8691C39.283 47.8691 39.5705 48.1548 39.5705 48.501Z" fill="black"/>
      <path d="M30.0725 50.7861C30.0725 51.1324 29.7849 51.418 29.4364 51.418C29.0878 51.418 28.8003 51.1324 28.8003 50.7861C28.8003 50.4399 29.0878 50.1543 29.4364 50.1543C29.7849 50.1543 30.0725 50.4399 30.0725 50.7861Z" fill="black"/>
      <path d="M33.8894 50.7861C33.8894 51.1324 33.6018 51.418 33.2533 51.418C32.9047 51.418 32.6172 51.1324 32.6172 50.7861C32.6172 50.4399 32.9047 50.1543 33.2533 50.1543C33.6018 50.1543 33.8894 50.4399 33.8894 50.7861Z" fill="black"/>
      <path d="M37.7146 50.7861C37.7146 51.1324 37.427 51.418 37.0785 51.418C36.7299 51.418 36.4424 51.1324 36.4424 50.7861C36.4424 50.4399 36.7299 50.1543 37.0785 50.1543C37.427 50.1543 37.7146 50.4399 37.7146 50.7861Z" fill="black"/>
      <path d="M31.9284 52.855C31.9284 53.2012 31.6409 53.4868 31.2923 53.4868C30.9438 53.4868 30.6562 53.2012 30.6562 52.855C30.6562 52.5088 30.9438 52.2231 31.2923 52.2231C31.6409 52.2231 31.9284 52.5088 31.9284 52.855Z" fill="black"/>
      <path d="M35.7453 52.855C35.7453 53.2012 35.4578 53.4868 35.1092 53.4868C34.7607 53.4868 34.4731 53.2012 34.4731 52.855C34.4731 52.5088 34.7607 52.2231 35.1092 52.2231C35.4578 52.2231 35.7453 52.5088 35.7453 52.855Z" fill="black"/>
      <path d="M39.5705 52.855C39.5705 53.2012 39.283 53.4868 38.9344 53.4868C38.5859 53.4868 38.2983 53.2012 38.2983 52.855C38.2983 52.5088 38.5859 52.2231 38.9344 52.2231C39.283 52.2231 39.5705 52.5088 39.5705 52.855Z" fill="black"/>
      <path d="M30.0725 55.1394C30.0725 55.4856 29.7849 55.7713 29.4364 55.7713C29.0878 55.7713 28.8003 55.4856 28.8003 55.1394C28.8003 54.7932 29.0878 54.5076 29.4364 54.5076C29.7849 54.5076 30.0725 54.7932 30.0725 55.1394Z" fill="black"/>
      <path d="M33.8894 55.1394C33.8894 55.4856 33.6018 55.7713 33.2533 55.7713C32.9047 55.7713 32.6172 55.4856 32.6172 55.1394C32.6172 54.7932 32.9047 54.5076 33.2533 54.5076C33.6018 54.5076 33.8894 54.7932 33.8894 55.1394Z" fill="black"/>
      <path d="M37.7146 55.1394C37.7146 55.4856 37.427 55.7713 37.0785 55.7713C36.7299 55.7713 36.4424 55.4856 36.4424 55.1394C36.4424 54.7932 36.7299 54.5076 37.0785 54.5076C37.427 54.5076 37.7146 54.7932 37.7146 55.1394Z" fill="black"/>
      <path d="M31.9284 57.2083C31.9284 57.5545 31.6409 57.8401 31.2923 57.8401C30.9438 57.8401 30.6562 57.5545 30.6562 57.2083C30.6562 56.862 30.9438 56.5764 31.2923 56.5764C31.6409 56.5764 31.9284 56.862 31.9284 57.2083Z" fill="black"/>
      <path d="M35.7453 57.2083C35.7453 57.5545 35.4578 57.8401 35.1092 57.8401C34.7607 57.8401 34.4731 57.5545 34.4731 57.2083C34.4731 56.862 34.7607 56.5764 35.1092 56.5764C35.4578 56.5764 35.7453 56.862 35.7453 57.2083Z" fill="black"/>
      <path d="M39.5705 57.2083C39.5705 57.5545 39.283 57.8401 38.9344 57.8401C38.5859 57.8401 38.2983 57.5545 38.2983 57.2083C38.2983 56.862 38.5859 56.5764 38.9344 56.5764C39.283 56.5764 39.5705 56.862 39.5705 57.2083Z" fill="black"/>
      <path d="M30.0725 59.4929C30.0725 59.8391 29.7849 60.1248 29.4364 60.1248C29.0878 60.1248 28.8003 59.8391 28.8003 59.4929C28.8003 59.1467 29.0878 58.8611 29.4364 58.8611C29.7849 58.8611 30.0725 59.1467 30.0725 59.4929Z" fill="black"/>
      <path d="M33.8894 59.4929C33.8894 59.8391 33.6018 60.1248 33.2533 60.1248C32.9047 60.1248 32.6172 59.8391 32.6172 59.4929C32.6172 59.1467 32.9047 58.8611 33.2533 58.8611C33.6018 58.8611 33.8894 59.1467 33.8894 59.4929Z" fill="black"/>
      <path d="M37.7146 59.4929C37.7146 59.8391 37.427 60.1248 37.0785 60.1248C36.7299 60.1248 36.4424 59.8391 36.4424 59.4929C36.4424 59.1467 36.7299 58.8611 37.0785 58.8611C37.427 58.8611 37.7146 59.1467 37.7146 59.4929Z" fill="black"/>
      <path d="M31.9284 61.5618C31.9284 61.908 31.6409 62.1936 31.2923 62.1936C30.9438 62.1936 30.6562 61.908 30.6562 61.5618C30.6562 61.2156 30.9438 60.9299 31.2923 60.9299C31.6409 60.9299 31.9284 61.2156 31.9284 61.5618Z" fill="black"/>
      <path d="M35.7453 61.5618C35.7453 61.908 35.4578 62.1936 35.1092 62.1936C34.7607 62.1936 34.4731 61.908 34.4731 61.5618C34.4731 61.2156 34.7607 60.9299 35.1092 60.9299C35.4578 60.9299 35.7453 61.2156 35.7453 61.5618Z" fill="black"/>
      <path d="M39.5705 61.5618C39.5705 61.908 39.283 62.1936 38.9344 62.1936C38.5859 62.1936 38.2983 61.908 38.2983 61.5618C38.2983 61.2156 38.5859 60.9299 38.9344 60.9299C39.283 60.9299 39.5705 61.2156 39.5705 61.5618Z" fill="black"/>
      <path d="M30.0725 63.8469C30.0725 64.1931 29.7849 64.4788 29.4364 64.4788C29.0878 64.4788 28.8003 64.1931 28.8003 63.8469C28.8003 63.5007 29.0878 63.2151 29.4364 63.2151C29.7849 63.2151 30.0725 63.5007 30.0725 63.8469Z" fill="black"/>
      <path d="M33.8894 63.8469C33.8894 64.1931 33.6018 64.4788 33.2533 64.4788C32.9047 64.4788 32.6172 64.1931 32.6172 63.8469C32.6172 63.5007 32.9047 63.2151 33.2533 63.2151C33.6018 63.2151 33.8894 63.5007 33.8894 63.8469Z" fill="black"/>
      <path d="M37.7146 63.8469C37.7146 64.1931 37.427 64.4788 37.0785 64.4788C36.7299 64.4788 36.4424 64.1931 36.4424 63.8469C36.4424 63.5007 36.7299 63.2151 37.0785 63.2151C37.427 63.2151 37.7146 63.5007 37.7146 63.8469Z" fill="black"/>
      <path d="M31.9284 65.9158C31.9284 66.262 31.6409 66.5476 31.2923 66.5476C30.9438 66.5476 30.6562 66.262 30.6562 65.9158C30.6562 65.5696 30.9438 65.2839 31.2923 65.2839C31.6409 65.2839 31.9284 65.5696 31.9284 65.9158Z" fill="black"/>
      <path d="M35.7453 65.9158C35.7453 66.262 35.4578 66.5476 35.1092 66.5476C34.7607 66.5476 34.4731 66.262 34.4731 65.9158C34.4731 65.5696 34.7607 65.2839 35.1092 65.2839C35.4578 65.2839 35.7453 65.5696 35.7453 65.9158Z" fill="black"/>
      <path d="M39.5705 65.9158C39.5705 66.262 39.283 66.5476 38.9344 66.5476C38.5859 66.5476 38.2983 66.262 38.2983 65.9158C38.2983 65.5696 38.5859 65.2839 38.9344 65.2839C39.283 65.2839 39.5705 65.5696 39.5705 65.9158Z" fill="black"/>
      <path d="M30.0725 68.2004C30.0725 68.5467 29.7849 68.8323 29.4364 68.8323C29.0878 68.8323 28.8003 68.5467 28.8003 68.2004C28.8003 67.8542 29.0878 67.5686 29.4364 67.5686C29.7849 67.5686 30.0725 67.8542 30.0725 68.2004Z" fill="black"/>
      <path d="M33.8894 68.2004C33.8894 68.5467 33.6018 68.8323 33.2533 68.8323C32.9047 68.8323 32.6172 68.5467 32.6172 68.2004C32.6172 67.8542 32.9047 67.5686 33.2533 67.5686C33.6018 67.5686 33.8894 67.8542 33.8894 68.2004Z" fill="black"/>
      <path d="M37.7146 68.2004C37.7146 68.5467 37.427 68.8323 37.0785 68.8323C36.7299 68.8323 36.4424 68.5467 36.4424 68.2004C36.4424 67.8542 36.7299 67.5686 37.0785 67.5686C37.427 67.5686 37.7146 67.8542 37.7146 68.2004Z" fill="black"/>
      <path d="M31.9284 70.2693C31.9284 70.6155 31.6409 70.9011 31.2923 70.9011C30.9438 70.9011 30.6562 70.6155 30.6562 70.2693C30.6562 69.9231 30.9438 69.6375 31.2923 69.6375C31.6409 69.6375 31.9284 69.9231 31.9284 70.2693Z" fill="black"/>
      <path d="M35.1092 70.9011C35.4606 70.9011 35.7453 70.6183 35.7453 70.2693C35.7453 69.9203 35.4606 69.6375 35.1092 69.6375C34.7579 69.6375 34.4731 69.9203 34.4731 70.2693C34.4731 70.6183 34.7579 70.9011 35.1092 70.9011Z" fill="black"/>
      <path d="M39.5705 70.2693C39.5705 70.6155 39.283 70.9011 38.9344 70.9011C38.5859 70.9011 38.2983 70.6155 38.2983 70.2693C38.2983 69.9231 38.5859 69.6375 38.9344 69.6375C39.283 69.6375 39.5705 69.9231 39.5705 70.2693Z" fill="black"/>
      <path d="M30.0725 72.5544C30.0725 72.9007 29.7849 73.1863 29.4364 73.1863C29.0878 73.1863 28.8003 72.9007 28.8003 72.5544C28.8003 72.2082 29.0878 71.9226 29.4364 71.9226C29.7849 71.9226 30.0725 72.2082 30.0725 72.5544Z" fill="black"/>
      <path d="M33.8894 72.5544C33.8894 72.9007 33.6018 73.1863 33.2533 73.1863C32.9047 73.1863 32.6172 72.9007 32.6172 72.5544C32.6172 72.2082 32.9047 71.9226 33.2533 71.9226C33.6018 71.9226 33.8894 72.2082 33.8894 72.5544Z" fill="black"/>
      <path d="M37.7146 72.5544C37.7146 72.9007 37.427 73.1863 37.0785 73.1863C36.7299 73.1863 36.4424 72.9007 36.4424 72.5544C36.4424 72.2082 36.7299 71.9226 37.0785 71.9226C37.427 71.9226 37.7146 72.2082 37.7146 72.5544Z" fill="black"/>
      <path d="M31.9284 74.6233C31.9284 74.9695 31.6409 75.2551 31.2923 75.2551C30.9438 75.2551 30.6562 74.9695 30.6562 74.6233C30.6562 74.2771 30.9438 73.9915 31.2923 73.9915C31.6409 73.9915 31.9284 74.2771 31.9284 74.6233Z" fill="black"/>
      <path d="M35.7453 74.6233C35.7453 74.9695 35.4578 75.2551 35.1092 75.2551C34.7607 75.2551 34.4731 74.9695 34.4731 74.6233C34.4731 74.2771 34.7607 73.9915 35.1092 73.9915C35.4578 73.9915 35.7453 74.2771 35.7453 74.6233Z" fill="black"/>
      <path d="M39.5705 74.6233C39.5705 74.9695 39.283 75.2551 38.9344 75.2551C38.5859 75.2551 38.2983 74.9695 38.2983 74.6233C38.2983 74.2771 38.5859 73.9915 38.9344 73.9915C39.283 73.9915 39.5705 74.2771 39.5705 74.6233Z" fill="black"/>
      <path d="M30.0725 76.9085C30.0725 77.2547 29.7849 77.5403 29.4364 77.5403C29.0878 77.5403 28.8003 77.2547 28.8003 76.9085C28.8003 76.5622 29.0878 76.2766 29.4364 76.2766C29.7849 76.2766 30.0725 76.5622 30.0725 76.9085Z" fill="black"/>
      <path d="M33.8894 76.9085C33.8894 77.2547 33.6018 77.5403 33.2533 77.5403C32.9047 77.5403 32.6172 77.2547 32.6172 76.9085C32.6172 76.5622 32.9047 76.2766 33.2533 76.2766C33.6018 76.2766 33.8894 76.5622 33.8894 76.9085Z" fill="black"/>
      <path d="M37.7146 76.9085C37.7146 77.2547 37.427 77.5403 37.0785 77.5403C36.7299 77.5403 36.4424 77.2547 36.4424 76.9085C36.4424 76.5622 36.7299 76.2766 37.0785 76.2766C37.427 76.2766 37.7146 76.5622 37.7146 76.9085Z" fill="black"/>
      <path d="M31.9284 78.9773C31.9284 79.3235 31.6409 79.6091 31.2923 79.6091C30.9438 79.6091 30.6562 79.3235 30.6562 78.9773C30.6562 78.6311 30.9438 78.3455 31.2923 78.3455C31.6409 78.3455 31.9284 78.6311 31.9284 78.9773Z" fill="black"/>
      <path d="M35.7453 78.9773C35.7453 79.3235 35.4578 79.6091 35.1092 79.6091C34.7607 79.6091 34.4731 79.3235 34.4731 78.9773C34.4731 78.6311 34.7607 78.3455 35.1092 78.3455C35.4578 78.3455 35.7453 78.6311 35.7453 78.9773Z" fill="black"/>
      <path d="M39.5705 78.9773C39.5705 79.3235 39.283 79.6091 38.9344 79.6091C38.5859 79.6091 38.2983 79.3235 38.2983 78.9773C38.2983 78.6311 38.5859 78.3455 38.9344 78.3455C39.283 78.3455 39.5705 78.6311 39.5705 78.9773Z" fill="black"/>
      <path d="M30.0725 81.2625C30.0725 81.6087 29.7849 81.8943 29.4364 81.8943C29.0878 81.8943 28.8003 81.6087 28.8003 81.2625C28.8003 80.9162 29.0878 80.6306 29.4364 80.6306C29.7849 80.6306 30.0725 80.9162 30.0725 81.2625Z" fill="black"/>
      <path d="M33.8894 81.2625C33.8894 81.6087 33.6018 81.8943 33.2533 81.8943C32.9047 81.8943 32.6172 81.6087 32.6172 81.2625C32.6172 80.9162 32.9047 80.6306 33.2533 80.6306C33.6018 80.6306 33.8894 80.9162 33.8894 81.2625Z" fill="black"/>
      <path d="M37.7146 81.2625C37.7146 81.6087 37.427 81.8943 37.0785 81.8943C36.7299 81.8943 36.4424 81.6087 36.4424 81.2625C36.4424 80.9162 36.7299 80.6306 37.0785 80.6306C37.427 80.6306 37.7146 80.9162 37.7146 81.2625Z" fill="black"/>
      <path d="M31.9284 83.3308C31.9284 83.677 31.6409 83.9627 31.2923 83.9627C30.9438 83.9627 30.6562 83.677 30.6562 83.3308C30.6562 82.9846 30.9438 82.699 31.2923 82.699C31.6409 82.699 31.9284 82.9846 31.9284 83.3308Z" fill="black"/>
      <path d="M35.7453 83.3308C35.7453 83.677 35.4578 83.9627 35.1092 83.9627C34.7607 83.9627 34.4731 83.677 34.4731 83.3308C34.4731 82.9846 34.7607 82.699 35.1092 82.699C35.4578 82.699 35.7453 82.9846 35.7453 83.3308Z" fill="black"/>
      <path d="M39.5705 83.3308C39.5705 83.677 39.283 83.9627 38.9344 83.9627C38.5859 83.9627 38.2983 83.677 38.2983 83.3308C38.2983 82.9846 38.5859 82.699 38.9344 82.699C39.283 82.699 39.5705 82.9846 39.5705 83.3308Z" fill="black"/>
      <path d="M30.0725 85.616C30.0725 85.9622 29.7849 86.2478 29.4364 86.2478C29.0878 86.2478 28.8003 85.9622 28.8003 85.616C28.8003 85.2698 29.0878 84.9841 29.4364 84.9841C29.7849 84.9841 30.0725 85.2698 30.0725 85.616Z" fill="black"/>
      <path d="M33.8894 85.616C33.8894 85.9622 33.6018 86.2478 33.2533 86.2478C32.9047 86.2478 32.6172 85.9622 32.6172 85.616C32.6172 85.2698 32.9047 84.9841 33.2533 84.9841C33.6018 84.9841 33.8894 85.2698 33.8894 85.616Z" fill="black"/>
      <path d="M37.7146 85.616C37.7146 85.9622 37.427 86.2478 37.0785 86.2478C36.7299 86.2478 36.4424 85.9622 36.4424 85.616C36.4424 85.2698 36.7299 84.9841 37.0785 84.9841C37.427 84.9841 37.7146 85.2698 37.7146 85.616Z" fill="black"/>
    </g>
    <path d="M36.9477 7.37996C36.878 6.73947 35.9544 6.94719 34.7432 6.94719C33.532 6.94719 32.8784 6.78274 32.8087 7.42324C32.739 8.06374 32.2859 8.89465 32.3208 9.28415C32.3382 9.50919 32.6519 12.6684 33.288 12.4607C34.7432 11.976 36.4075 12.2356 36.5295 12.0192C36.8344 11.4826 37.4183 9.64767 37.4705 9.42263C37.5664 8.99852 37.0261 8.02046 36.9564 7.37996H36.9477Z" fill="black"/>
    <path d="M38.22 12.2182C38.1677 12.2182 38.1154 12.2095 38.0718 12.1835C37.9847 12.1403 37.915 12.0624 37.8889 11.9672C37.1656 9.44845 35.937 5.70933 35.4403 5.27656C35.2922 5.14673 35.2835 4.93034 35.4142 4.7832C35.5449 4.64471 35.7627 4.6274 35.9108 4.75723C36.6602 5.41504 37.976 9.76004 38.4465 11.3699C39.2133 10.9199 41.2088 9.55231 44.337 6.02957C44.4677 5.88243 44.6855 5.87378 44.8249 5.99495C44.9731 6.12478 44.9818 6.34117 44.8598 6.47965C40.6685 11.2055 38.4552 12.1489 38.3594 12.1922C38.3158 12.2095 38.2723 12.2182 38.2287 12.2182H38.22Z" fill="#83BDE5"/>
    <path d="M31.4838 12.2182C31.4402 12.2182 31.3967 12.2095 31.3531 12.1922C31.2573 12.1576 29.0527 11.2055 24.8527 6.47965C24.722 6.33251 24.7394 6.11613 24.8876 5.99495C25.0357 5.86512 25.2535 5.88243 25.3755 6.02957C28.5037 9.55231 30.4992 10.9199 31.266 11.3699C31.7365 9.7687 33.061 5.41504 33.8017 4.75723C33.9498 4.6274 34.1676 4.64471 34.2983 4.7832C34.429 4.93034 34.4116 5.14673 34.2722 5.27656C33.7755 5.70933 32.5469 9.44845 31.8236 11.9672C31.7975 12.0624 31.7278 12.1403 31.6407 12.1835C31.5971 12.2095 31.5448 12.2182 31.4925 12.2182H31.4838Z" fill="#83BDE5"/>
    <path d="M11.7216 34.073L11.3208 37.916L0.759802 35.4492C0.733661 36.3407 0.70752 36.8946 0.70752 36.8946C0.70752 36.8946 6.17971 39.0585 11.8959 39.5778L11.7216 34.0817V34.073Z" fill="#83BDE5"/>
    <path d="M58.1138 34.177L58.6889 37.9248L69.2499 35.4493C69.276 36.3408 69.3021 36.8948 69.3021 36.8948C69.3021 36.8948 63.8299 39.0586 58.1138 39.578V34.177Z" fill="#83BDE5"/>
  </g>
  <defs>
    <clipPath id="clip0_607_15864">
      <rect width="68.5854" height="92.5" fill="white" transform="translate(0.70752 0.75)"/>
    </clipPath>
  </defs>
</svg>`,
  clothe_4: `<svg xmlns="http://www.w3.org/2000/svg" width="70" height="96" viewBox="0 0 70 96" fill="none">
  <path d="M1.7721 84.7819H8.4129C8.48405 84.3782 12.2314 59.9903 12.7927 55.3359C13.3144 51.0219 13.8204 39.5047 13.8125 32.8794C13.9153 35.9427 14.1129 37.1617 14.4291 43.4704C14.6663 48.196 14.8165 64.6842 14.4845 75.766C14.4845 75.766 20.2398 85.336 34.8575 85.336C49.4751 85.336 55.2463 75.766 55.2463 75.766C54.9143 64.6842 55.0961 48.2989 55.27 43.6683C55.4835 38.1037 55.8155 35.9427 55.9183 32.8794C55.9183 39.5047 56.4163 51.014 56.9381 55.3359C57.5073 59.9903 61.0807 84.3703 61.1519 84.7819H67.9824C67.6108 73.5101 67.5397 60.3781 66.3617 50.6974C65.7293 45.5047 65.3103 25.4466 65.413 21.6472C65.5395 17.1828 64.6778 12.1484 59.5628 11.3094C56.1001 10.7395 51.665 9.21968 48.3683 8.18274C42.945 6.48089 42.9529 0.75 42.9529 0.75C42.9529 0.75 37.411 3.40964 35.1184 4.26452C34.8575 4.65239 34.8417 9.79752 34.8417 9.79752C34.8417 9.79752 34.7626 4.5574 34.565 4.26452C32.1854 3.20383 26.8569 0.75 26.8569 0.75C26.8569 0.75 26.7858 6.47297 21.3624 8.18274C18.0658 9.21968 13.6307 10.7395 10.168 11.3094C5.06088 12.1484 4.19916 17.1828 4.31774 21.6472C4.42052 25.4466 4.00151 45.5126 3.36906 50.6974C2.19111 60.386 2.14367 73.518 1.7721 84.7819Z" fill="#F4F4F4"/>
  <path d="M37.8775 11.2382C37.83 11.2382 37.7826 11.2302 37.7431 11.2065C37.664 11.1669 37.6008 11.0957 37.577 11.0086C36.9209 8.70517 35.8062 5.28563 35.3555 4.88985C35.2211 4.77112 35.2132 4.57323 35.3318 4.43866C35.4504 4.31201 35.648 4.29618 35.7824 4.41492C36.4623 5.0165 37.6561 8.99013 38.083 10.4624C38.7787 10.0508 40.5891 8.80015 43.4273 5.57851C43.5458 5.44394 43.7435 5.43603 43.87 5.54685C44.0044 5.66558 44.0123 5.86347 43.9016 5.99012C40.099 10.312 38.0909 11.1748 38.0039 11.2144C37.9644 11.2302 37.9249 11.2382 37.8854 11.2382H37.8775Z" fill="#8FC5D8"/>
  <path d="M31.7663 11.2382C31.7268 11.2382 31.6873 11.2302 31.6478 11.2144C31.5608 11.1827 29.5607 10.312 25.7501 5.99012C25.6315 5.85556 25.6473 5.65767 25.7817 5.54685C25.9161 5.42811 26.1138 5.44394 26.2244 5.57851C29.0626 8.80015 30.873 10.0508 31.5687 10.4624C31.9956 8.99804 33.1973 5.0165 33.8693 4.41492C34.0037 4.29618 34.2013 4.31201 34.3199 4.43866C34.4385 4.57323 34.4227 4.77112 34.2962 4.88985C33.8456 5.28563 32.7308 8.70517 32.0747 11.0086C32.051 11.0957 31.9877 11.1669 31.9086 11.2065C31.8691 11.2302 31.8217 11.2382 31.7743 11.2382H31.7663Z" fill="#8FC5D8"/>
  <path d="M6.08862 76.2647C5.13203 76.2647 4.03314 76.2014 3.02121 76.0114C2.84728 75.9798 2.7366 75.8135 2.76822 75.6394C2.79985 75.4652 2.96587 75.3544 3.13979 75.3861C5.59056 75.861 8.65797 75.489 8.6896 75.489C8.86352 75.4652 9.02164 75.5919 9.04535 75.766C9.06907 75.9402 8.94258 76.0985 8.76865 76.1222C8.6896 76.1302 7.54327 76.2726 6.08071 76.2726L6.08862 76.2647Z" fill="#8FC5D8"/>
  <path d="M63.6501 76.2647C62.1954 76.2647 61.0412 76.1222 60.9621 76.1143C60.7882 76.0906 60.6696 75.9323 60.6854 75.7581C60.7091 75.584 60.8672 75.4652 61.0412 75.4811C61.0728 75.4811 64.1481 75.8531 66.591 75.3782C66.7649 75.3465 66.9309 75.4573 66.9625 75.6315C66.9942 75.8056 66.8835 75.9718 66.7095 76.0035C65.6976 76.2014 64.6066 76.2568 63.6421 76.2568L63.6501 76.2647Z" fill="#8FC5D8"/>
  <path d="M34.6124 84.3703C34.478 84.3703 34.3594 84.2278 34.3594 84.0457V8.30939C34.3594 8.12733 34.4701 7.98485 34.6124 7.98485C34.7547 7.98485 34.8654 8.12733 34.8654 8.30939V84.0457C34.8654 84.2278 34.7547 84.3703 34.6124 84.3703Z" fill="#8FC5D8"/>
  <path d="M36.6046 78.4257C36.6046 78.766 36.3279 79.0431 35.988 79.0431C35.648 79.0431 35.3713 78.766 35.3713 78.4257C35.3713 78.0853 35.648 77.8083 35.988 77.8083C36.3279 77.8083 36.6046 78.0853 36.6046 78.4257Z" fill="#8FC5D8"/>
  <path d="M36.6046 69.0774C36.6046 69.4177 36.3279 69.6948 35.988 69.6948C35.648 69.6948 35.3713 69.4177 35.3713 69.0774C35.3713 68.737 35.648 68.46 35.988 68.46C36.3279 68.46 36.6046 68.737 36.6046 69.0774Z" fill="#8FC5D8"/>
  <path d="M36.6046 59.7291C36.6046 60.0694 36.3279 60.3465 35.988 60.3465C35.648 60.3465 35.3713 60.0694 35.3713 59.7291C35.3713 59.3887 35.648 59.1116 35.988 59.1116C36.3279 59.1116 36.6046 59.3887 36.6046 59.7291Z" fill="#8FC5D8"/>
  <path d="M36.6046 50.3728C36.6046 50.7132 36.3279 50.9902 35.988 50.9902C35.648 50.9902 35.3713 50.7132 35.3713 50.3728C35.3713 50.0325 35.648 49.7554 35.988 49.7554C36.3279 49.7554 36.6046 50.0325 36.6046 50.3728Z" fill="#8FC5D8"/>
  <path d="M36.6046 41.0245C36.6046 41.3649 36.3279 41.6419 35.988 41.6419C35.648 41.6419 35.3713 41.3649 35.3713 41.0245C35.3713 40.6841 35.648 40.4071 35.988 40.4071C36.3279 40.4071 36.6046 40.6841 36.6046 41.0245Z" fill="#8FC5D8"/>
  <path d="M36.6046 31.6762C36.6046 32.0166 36.3279 32.2936 35.988 32.2936C35.648 32.2936 35.3713 32.0166 35.3713 31.6762C35.3713 31.3358 35.648 31.0588 35.988 31.0588C36.3279 31.0588 36.6046 31.3358 36.6046 31.6762Z" fill="#8FC5D8"/>
  <path d="M36.6046 22.3279C36.6046 22.6683 36.3279 22.9453 35.988 22.9453C35.648 22.9453 35.3713 22.6683 35.3713 22.3279C35.3713 21.9875 35.648 21.7105 35.988 21.7105C36.3279 21.7105 36.6046 21.9875 36.6046 22.3279Z" fill="#8FC5D8"/>
  <path d="M36.6046 12.9717C36.6046 13.312 36.3279 13.5891 35.988 13.5891C35.648 13.5891 35.3713 13.312 35.3713 12.9717C35.3713 12.6313 35.648 12.3543 35.988 12.3543C36.3279 12.3543 36.6046 12.6313 36.6046 12.9717Z" fill="#8FC5D8"/>
  <path d="M36.6046 8.91097C36.6046 9.25134 36.3279 9.52839 35.988 9.52839C35.648 9.52839 35.3713 9.25134 35.3713 8.91097C35.3713 8.5706 35.648 8.29356 35.988 8.29356C36.3279 8.29356 36.6046 8.5706 36.6046 8.91097Z" fill="#8FC5D8"/>
  <path d="M34.3436 6.15635C34.2329 6.18009 29.284 4.98484 28.0902 4.47033C27.8767 4.39117 28.019 6.02178 28.0507 6.5917C28.0744 7.00332 28.0823 7.76321 28.019 8.16691C27.8767 9.04554 28.0586 11.0878 28.0586 11.0878C30.1378 10.3279 32.2249 9.70253 34.312 9.10886C34.3278 8.12733 34.3357 7.13788 34.3515 6.15635H34.3436Z" fill="#2B2B2B"/>
  <path d="M35.5136 9.15636C35.6243 9.13261 40.5733 10.4308 41.7671 10.9374C41.9805 11.0165 41.7592 9.2276 41.7354 8.81598C41.7038 8.19857 41.8698 7.54158 41.8382 7.12205C41.7671 5.96637 41.7987 4.61281 41.7987 4.61281C39.7195 5.23022 37.6324 5.72891 35.5453 6.19593C35.5295 7.17746 35.5216 8.16691 35.5057 9.14844L35.5136 9.15636Z" fill="#2B2B2B"/>
  <path d="M31.2446 8.62601C31.1497 8.62601 31.0627 8.53894 31.039 8.40437C31.0153 8.26189 31.0944 8.11941 31.205 8.09567C32.1537 7.87403 33.1103 7.66031 34.059 7.44659C32.8099 7.1458 30.7544 6.71044 29.5527 6.43339C29.4421 6.40965 29.363 6.26717 29.3788 6.11677C29.3946 5.96637 29.5053 5.86347 29.616 5.89513C29.8136 5.97429 34.5017 6.94791 35.1816 7.22495C35.2686 7.26453 35.3239 7.37535 35.3239 7.49408C35.3239 7.61282 35.2449 7.7078 35.15 7.73155C33.8614 8.02443 32.5648 8.30939 31.2762 8.6181C31.2604 8.6181 31.2525 8.6181 31.2367 8.6181L31.2446 8.62601Z" fill="black"/>
  <path d="M38.842 8.62601C38.9368 8.62601 39.0238 8.53894 39.0475 8.40437C39.0712 8.26189 38.9922 8.11941 38.8815 8.09567C37.9328 7.87403 36.9762 7.66031 36.0275 7.44659C37.2766 7.1458 39.3321 6.71044 40.5338 6.43339C40.6445 6.40965 40.7235 6.26717 40.7077 6.11677C40.6919 5.96637 40.5812 5.86347 40.4705 5.89513C40.2729 5.97429 35.5848 6.94791 34.897 7.22495C34.81 7.26453 34.7547 7.37535 34.7547 7.49408C34.7547 7.61282 34.8338 7.7078 34.9286 7.73155C36.2173 8.02443 37.5138 8.30939 38.8024 8.6181C38.8182 8.6181 38.8261 8.6181 38.842 8.6181V8.62601Z" fill="black"/>
  <path d="M33.5847 7.55741C33.5847 8.44395 33.4977 9.33841 34.9128 9.34633C36.0829 9.35425 36.2805 8.64976 36.2805 7.54949C36.2805 6.71835 36.3358 5.78431 34.9761 5.79223C33.5214 5.79223 33.5926 6.37798 33.5926 7.55741H33.5847Z" fill="black"/>
  <path d="M66.4883 22.7971C66.6664 16.3044 64.5856 12.6603 60.3024 11.9624C57.3471 11.4754 53.5901 10.2743 50.5782 9.30849C49.9871 9.12182 49.4203 8.93516 48.8778 8.76472C45.8092 7.79893 45.0157 6.02967 45.0157 6.02967C45.0157 6.02967 46.3597 35.6526 34.7166 50.7969C22.2799 25.0371 25.1138 5.6888 25.1138 5.6888C25.1138 5.6888 24.1746 7.79893 21.1059 8.76472C20.5634 8.93516 19.9967 9.11371 19.4056 9.30849C16.3936 10.2743 12.6367 11.4754 9.68138 11.9624C5.39819 12.6603 3.31732 16.3125 3.49545 22.7971C3.6088 26.782 0.621094 85.9387 0.621094 85.9387H8.71786C8.71786 85.9387 13.5759 53.9864 13.6488 44.7992C13.7379 47.6236 14.3289 64.4884 13.9646 75.9886C13.7136 83.9259 13.7621 88.8523 13.9484 93.2754C18.8955 95.4018 32.0366 95.2476 32.0366 95.2476L35 92.3015L37.9634 95.2476C37.9634 95.2476 51.1045 95.4018 56.0516 93.2754C56.2297 88.8442 56.2783 83.9259 56.0354 75.9886C55.6791 64.4884 56.4645 47.2502 56.6183 44.7992C57.1041 63.0519 61.2821 85.9387 61.2821 85.9387H69.3788C69.3788 85.9387 66.3911 26.782 66.5045 22.7971H66.4883Z" fill="black"/>
  <path d="M38.4735 74.8037C38.4735 75.315 38.0444 75.737 37.5181 75.737C36.9918 75.737 36.5626 75.3231 36.5626 74.8037C36.5626 74.2842 36.9918 73.8703 37.5181 73.8703C38.0444 73.8703 38.4735 74.2842 38.4735 74.8037Z" fill="#353535"/>
  <path d="M38.4735 65.9655C38.4735 66.4768 38.0444 66.8988 37.5181 66.8988C36.9918 66.8988 36.5626 66.4849 36.5626 65.9655C36.5626 65.4461 36.9918 65.0321 37.5181 65.0321C38.0444 65.0321 38.4735 65.4461 38.4735 65.9655Z" fill="#353535"/>
  <path d="M38.4735 57.1354C38.4735 57.6467 38.0444 58.0687 37.5181 58.0687C36.9918 58.0687 36.5626 57.6548 36.5626 57.1354C36.5626 56.616 36.9918 56.2021 37.5181 56.2021C38.0444 56.2021 38.4735 56.616 38.4735 57.1354Z" fill="#353535"/>
  <path d="M48.2058 76.4512C48.0601 76.4512 47.9305 76.3538 47.8981 76.2077C47.89 76.1671 47.6795 75.2744 47.4042 72.7098C47.3961 72.6286 47.4204 72.5475 47.4771 72.4825C47.5338 72.4176 47.6066 72.377 47.6957 72.3689C49.8009 72.166 54.2055 71.2976 54.2541 71.2895C54.4322 71.257 54.5942 71.3625 54.6346 71.533C54.667 71.7034 54.5618 71.8657 54.3836 71.9063C54.2136 71.9388 50.3029 72.7179 48.0843 72.9614C48.3353 75.2419 48.5297 76.0616 48.5297 76.0697C48.5702 76.2402 48.4649 76.4025 48.2868 76.4431C48.2625 76.4431 48.2382 76.4431 48.2139 76.4431L48.2058 76.4512Z" fill="#353535"/>
  <path d="M21.2436 76.4512C21.3893 76.4512 21.5189 76.3538 21.5512 76.2077C21.5593 76.1671 21.7698 75.2744 22.0451 72.7098C22.0532 72.6286 22.0289 72.5475 21.9723 72.4825C21.9156 72.4176 21.8427 72.377 21.7537 72.3689C19.6485 72.166 15.2439 71.2976 15.1953 71.2895C15.0171 71.257 14.8552 71.3625 14.8147 71.533C14.7823 71.7034 14.8876 71.8657 15.0657 71.9063C15.2358 71.9388 19.1465 72.7179 21.365 72.9614C21.114 75.2419 20.9197 76.0616 20.9197 76.0697C20.8792 76.2402 20.9845 76.4025 21.1626 76.4431C21.1869 76.4431 21.2112 76.4431 21.2355 76.4431L21.2436 76.4512Z" fill="#353535"/>
  <path d="M52.2299 38.2254C52.0518 38.2254 51.906 38.0874 51.906 37.9089V36.7483C51.906 36.7483 51.8736 36.6752 51.8331 36.6752H46.6755C46.4974 36.6752 46.3516 36.5373 46.3516 36.3587C46.3516 36.1802 46.4974 36.0422 46.6755 36.0422H51.8331C52.2218 36.0422 52.5457 36.3587 52.5457 36.7402V37.9007C52.5457 38.0712 52.3999 38.2173 52.2218 38.2173L52.2299 38.2254Z" fill="#353535"/>
  <path d="M35.7773 90.6946C35.6558 90.6946 35.5425 90.6296 35.4858 90.5079C34.7814 89.0389 34.7733 85.6627 34.7733 85.5166V51.016C34.7733 50.8375 34.919 50.6914 35.0971 50.6914C35.2753 50.6914 35.421 50.8375 35.421 51.016V85.5247C35.421 85.5247 35.421 88.8928 36.0687 90.2239C36.1497 90.3862 36.0849 90.5809 35.923 90.6621C35.8744 90.6865 35.8258 90.6946 35.7854 90.6946H35.7773Z" fill="#353535"/>
  <path d="M22.0451 23.3327C21.0897 17.1647 22.1747 8.44009 22.1747 8.44009L25.5753 4.63374C25.5753 4.63374 23.4216 25.8243 35.2995 50.9267C35.2429 51.6166 34.9028 51.2433 34.7733 51.0809C24.7899 38.7367 22.9034 28.9002 22.0451 23.3327Z" fill="#353535"/>
  <path d="M48.2463 22.6753C49.4284 16.7994 48.0277 8.43197 48.0277 8.43197L44.3194 4.80417C44.3194 4.80417 46.7727 25.8162 34.8947 50.9186C34.4575 51.8601 35.2915 51.2351 35.421 51.0728C45.4043 38.7286 47.0641 28.5512 48.2463 22.6753Z" fill="#353535"/>
</svg>`,
  clothe_5: `<svg width="66" height="90" viewBox="0 0 66 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5621 34.4388C12.6699 35.9771 12.8086 37.8599 12.9858 41.4188C13.217 45.9879 13.1862 61.9684 12.8548 72.6833C12.8548 72.6833 18.6413 81.9058 32.888 81.9058C47.1346 81.9058 52.9442 72.5303 52.9442 72.5303C52.6206 61.8154 52.6129 46.0951 52.7824 41.6178C52.9905 36.2373 53.3141 34.1479 53.4142 31.186C53.4142 32.1657 53.4451 34.4388 53.4451 34.4388L63.3152 32.0738C63.3152 32.0738 62.6295 21.8181 62.6757 20.3181C62.799 16.0015 61.9591 11.1338 56.974 10.3226C53.5992 9.77152 49.2766 8.30205 46.0636 7.29944C40.778 5.65393 40.7857 0.112793 40.7857 0.112793C40.7857 0.112793 35.3844 2.68437 33.15 3.51095C32.8957 3.88597 32.8803 8.86075 32.8803 8.86075C32.8803 8.86075 32.8032 3.79413 32.6106 3.51095C30.2914 2.48538 25.0982 0.112793 25.0982 0.112793C25.0982 0.112793 25.0288 5.64628 19.7432 7.29944C16.5302 8.30205 12.2076 9.77152 8.83281 10.3226C4.81847 10.9731 3.4932 14.2565 3.19271 17.7694C3.12336 18.619 2.66876 32.0815 2.66876 32.0815C2.66876 32.0815 7.48442 34.0102 12.5621 34.4541V34.4388Z" fill="#CFE9FF"/>
<path d="M32.4027 6.01287L31.6862 4.67351L31.1391 6.12002C31.1391 6.12002 31.2162 6.42616 31.7709 6.90833C31.8942 6.19655 32.4027 6.01287 32.4027 6.01287Z" fill="#353535"/>
<path d="M33.3198 6.01287L33.89 4.67351L34.4447 5.57662C34.4447 5.57662 34.5449 6.04348 34.2136 6.21951C34.0903 6.01287 33.3198 6.01287 33.3198 6.01287Z" fill="#353535"/>
<path d="M36.8791 70.3177C36.8252 68.1058 35.7465 31.767 35.2302 16.4752C35.0761 11.9826 33.9512 10.5897 33.9974 9.66363C34.1284 7.11501 31.2776 7.79617 31.6474 10.008C31.7784 10.804 30.0756 12.924 29.7905 16.5059C28.5885 31.7363 28.3728 68.1058 28.3188 70.3177C28.3111 70.7157 32.5797 75.7517 32.5797 75.7517C32.5797 75.7517 36.8868 70.7233 36.8791 70.3177Z" fill="#353535"/>
<path d="M36.8791 70.3177C36.8252 68.1058 35.7465 31.767 35.2302 16.4752C35.0761 11.9826 33.9512 10.5897 33.9974 9.66363C34.1284 7.11501 31.2776 7.79617 31.6474 10.008C31.7784 10.804 30.0756 12.924 29.7905 16.5059C28.5885 31.7363 28.3728 68.1058 28.3188 70.3177C28.3111 70.7157 32.5797 75.7517 32.5797 75.7517C32.5797 75.7517 36.8868 70.7233 36.8791 70.3177Z" fill="black"/>
<path d="M34.7143 5.97477C34.6526 5.40841 33.8359 5.59209 32.7649 5.59209C31.6939 5.59209 31.116 5.44667 31.0544 6.01303C30.9927 6.57939 30.5921 7.31413 30.6229 7.65854C30.6383 7.85753 30.9157 10.6511 31.4782 10.4674C32.7649 10.0388 34.2366 10.2684 34.3444 10.077C34.6141 9.60253 35.1303 7.97999 35.1766 7.78099C35.2613 7.40597 34.7836 6.54113 34.722 5.97477H34.7143Z" fill="black"/>
<path d="M35.839 10.2531C35.7928 10.2531 35.7466 10.2455 35.7081 10.2225C35.631 10.1842 35.5694 10.1154 35.5463 10.0312C34.9067 7.804 33.8203 4.49769 33.3811 4.11501C33.2501 4.00021 33.2424 3.80887 33.358 3.67876C33.4736 3.55631 33.6662 3.541 33.7972 3.6558C34.4598 4.23747 35.6233 8.07953 36.0394 9.50308C36.7174 9.1051 38.4819 7.89584 41.248 4.78087C41.3636 4.65076 41.5562 4.6431 41.6795 4.75025C41.8105 4.86506 41.8182 5.05639 41.7103 5.17885C38.0042 9.35766 36.0471 10.1919 35.9623 10.2302C35.9238 10.2455 35.8853 10.2531 35.8468 10.2531H35.839Z" fill="#83BDE5"/>
<path d="M29.8828 10.2531C29.8442 10.2531 29.8057 10.2455 29.7672 10.2302C29.6824 10.1996 27.7331 9.35766 24.0192 5.17885C23.9036 5.04874 23.9191 4.8574 24.05 4.75025C24.181 4.63545 24.3737 4.65076 24.4815 4.78087C27.2476 7.89584 29.0121 9.1051 29.6901 9.50308C30.1062 8.08718 31.2774 4.23747 31.9323 3.6558C32.0633 3.541 32.2559 3.55631 32.3715 3.67876C32.4871 3.80887 32.4717 4.00021 32.3484 4.11501C31.9092 4.49769 30.8228 7.804 30.1833 10.0312C30.1602 10.1154 30.0985 10.1842 30.0215 10.2225C29.9829 10.2455 29.9367 10.2531 29.8905 10.2531H29.8828Z" fill="#83BDE5"/>
<path d="M12.4078 29.5793L12.0534 32.9774L2.71489 30.7962C2.69178 31.5845 2.66866 32.0743 2.66866 32.0743C2.66866 32.0743 7.50744 33.9877 12.5619 34.4469L12.4078 29.5869V29.5793Z" fill="#83BDE5"/>
<path d="M53.4296 29.6708L53.9382 32.9848L63.2767 30.7959C63.2998 31.5842 63.3229 32.074 63.3229 32.074C63.3229 32.074 58.4841 33.9874 53.4296 34.4466V29.6708Z" fill="#83BDE5"/>
<path d="M62.9911 20.7631C63.1608 14.5687 61.1788 11.0921 57.0993 10.4262C54.2845 9.96158 50.7062 8.81562 47.8374 7.8942C47.2744 7.71611 46.7346 7.53802 46.2179 7.37542C43.2951 6.454 42.5393 4.76603 42.5393 4.76603C42.5393 4.76603 43.8195 33.028 32.7299 47.4764C20.8845 22.9001 23.5837 4.44082 23.5837 4.44082C23.5837 4.44082 22.6891 6.454 19.7663 7.37542C19.2496 7.53802 18.7098 7.70837 18.1468 7.8942C15.278 8.81562 11.6997 9.96158 8.88491 10.4262C4.80535 11.0921 2.82341 14.5764 2.99307 20.7631C3.10104 24.5649 0.255371 81.0037 0.255371 81.0037H7.9672C7.9672 81.0037 12.5943 50.5194 12.6637 41.7544C12.7485 44.4489 13.3115 60.5389 12.9645 71.5107C12.7254 79.0834 12.7717 83.7834 12.949 88.0033C17.661 90.032 30.1773 89.8849 30.1773 89.8849L32.9998 87.0742L35.8223 89.8849C35.8223 89.8849 48.3386 90.032 53.0506 88.0033C53.2202 83.7757 53.2665 79.0834 53.0351 71.5107C52.6958 60.5389 53.4439 44.0927 53.5904 41.7544C54.0531 59.1684 58.0324 81.0037 58.0324 81.0037H65.7442C65.7442 81.0037 62.8986 24.5649 63.0065 20.7631H62.9911Z" fill="#143351"/>
<path d="M36.3083 70.3802C36.3083 70.868 35.8995 71.2707 35.3983 71.2707C34.897 71.2707 34.4883 70.8758 34.4883 70.3802C34.4883 69.8847 34.897 69.4898 35.3983 69.4898C35.8995 69.4898 36.3083 69.8847 36.3083 70.3802Z" fill="#0A2033"/>
<path d="M36.3083 61.9485C36.3083 62.4363 35.8995 62.839 35.3983 62.839C34.897 62.839 34.4883 62.4441 34.4883 61.9485C34.4883 61.453 34.897 61.0581 35.3983 61.0581C35.8995 61.0581 36.3083 61.453 36.3083 61.9485Z" fill="#0A2033"/>
<path d="M36.3083 53.5237C36.3083 54.0115 35.8995 54.4142 35.3983 54.4142C34.897 54.4142 34.4883 54.0193 34.4883 53.5237C34.4883 53.0282 34.897 52.6333 35.3983 52.6333C35.8995 52.6333 36.3083 53.0282 36.3083 53.5237Z" fill="#0A2033"/>
<path d="M45.578 71.9532C45.4391 71.9532 45.3158 71.8602 45.2849 71.7209C45.2772 71.6822 45.0767 70.8304 44.8145 68.3836C44.8068 68.3062 44.8299 68.2288 44.8839 68.1668C44.9379 68.1049 45.0073 68.0662 45.0921 68.0584C47.0972 67.8649 51.2924 67.0363 51.3387 67.0286C51.5084 66.9976 51.6626 67.0983 51.7012 67.2609C51.732 67.4235 51.6317 67.5784 51.4621 67.6171C51.3001 67.648 47.5753 68.3914 45.4623 68.6237C45.7014 70.7994 45.8864 71.5815 45.8864 71.5892C45.925 71.7518 45.8247 71.9067 45.6551 71.9454C45.6319 71.9454 45.6088 71.9454 45.5857 71.9454L45.578 71.9532Z" fill="#0A2033"/>
<path d="M19.8974 71.9532C20.0362 71.9532 20.1596 71.8602 20.1904 71.7209C20.1981 71.6822 20.3986 70.8304 20.6608 68.3836C20.6685 68.3062 20.6454 68.2288 20.5914 68.1668C20.5374 68.1049 20.468 68.0662 20.3832 68.0584C18.3781 67.8649 14.1829 67.0363 14.1366 67.0286C13.967 66.9976 13.8127 67.0983 13.7742 67.2609C13.7433 67.4235 13.8436 67.5784 14.0132 67.6171C14.1752 67.648 17.9 68.3914 20.013 68.6237C19.774 70.7994 19.5889 71.5815 19.5889 71.5892C19.5503 71.7518 19.6506 71.9067 19.8202 71.9454C19.8434 71.9454 19.8665 71.9454 19.8896 71.9454L19.8974 71.9532Z" fill="#0A2033"/>
<path d="M49.4105 35.4828C49.2409 35.4828 49.102 35.3511 49.102 35.1808V34.0735C49.102 34.0735 49.0712 34.0038 49.0326 34.0038H44.1202C43.9505 34.0038 43.8117 33.8722 43.8117 33.7019C43.8117 33.5315 43.9505 33.3999 44.1202 33.3999H49.0326C49.4028 33.3999 49.7113 33.7019 49.7113 34.0658V35.173C49.7113 35.3356 49.5725 35.475 49.4028 35.475L49.4105 35.4828Z" fill="#0A2033"/>
<path d="M33.74 85.5423C33.6243 85.5423 33.5164 85.4803 33.4624 85.3642C32.7915 83.9627 32.7837 80.7416 32.7837 80.6023V47.6867C32.7837 47.5164 32.9226 47.377 33.0922 47.377C33.2619 47.377 33.4007 47.5164 33.4007 47.6867V80.61C33.4007 80.61 33.4007 83.8233 34.0176 85.0932C34.0948 85.2481 34.0331 85.4339 33.8788 85.5113C33.8326 85.5346 33.7863 85.5423 33.7477 85.5423H33.74Z" fill="#0A2033"/>
<path d="M19.4346 20.7165L21.9949 19.8183L19.4346 18.6104L20.7841 7.05784L24.0231 3.42637C24.0231 3.42637 21.9717 23.6434 33.285 47.5925C33.231 48.2506 32.9071 47.8945 32.7837 47.7396C23.2751 35.9625 19.4346 20.7088 19.4346 20.7088V20.7165Z" fill="#295777"/>
<path d="M46.7498 20.7161L44.1895 19.8179L46.7498 18.61L45.4002 7.05742L41.8682 3.5963C41.8682 3.5963 44.2049 23.6429 32.8916 47.592C32.4752 48.4902 33.2695 47.894 33.3929 47.7392C42.9016 35.9621 46.7421 20.7083 46.7421 20.7083L46.7498 20.7161Z" fill="#295777"/>
</svg>
`,
  clothe_6: `<svg xmlns="http://www.w3.org/2000/svg" width="74" height="82" viewBox="0 0 74 82" fill="none">
  <path d="M61.4431 46.0526C61.1841 46.1107 60.0371 46.1689 59.7735 46.227L58.5895 38.5694C58.5756 35.9354 58.5895 5.53151 58.5895 3.06035C58.5895 3.06035 68.0384 4.43672 69.375 10.7687L74 41.7731C70.8689 43.5 64.8518 45.2851 61.4385 46.0526H61.4431Z" fill="#2DA590"/>
  <path d="M12.5577 46.0526C12.8167 46.1107 13.9633 46.1689 14.2269 46.227L15.4105 38.5694C15.4244 35.9354 15.4105 5.53151 15.4105 3.06035C15.4105 3.06035 6.84218 4.8772 5.50595 11.2092L0 41.7731C3.13019 43.5 9.1455 45.2851 12.5577 46.0526Z" fill="#2DA590"/>
  <path d="M59.256 55.125C59.256 55.125 60.0045 8.02773 59.5355 3.50083L37.2202 15.364L14.905 3.50083C14.436 8.02773 15.1844 55.125 15.1844 55.125C15.1844 57 13.9102 75.6566 14.9334 78.2267C14.9381 78.2443 14.9429 78.2618 14.9523 78.2793C14.9618 78.2968 14.9713 78.3085 14.9808 78.326C14.9997 78.3611 15.0187 78.3903 15.0423 78.4253C15.0613 78.4487 15.0755 78.4662 15.0944 78.4779C16.4113 79.8681 24.9613 80.9546 35.5008 81.0246C35.586 81.0246 36.8602 81.0246 37.2202 81.0246C37.5802 81.0246 38.8544 81.0246 38.9397 81.0246C49.4792 80.9546 58.0292 79.8681 59.346 78.4779C59.365 78.4662 59.3792 78.4429 59.3981 78.4253C59.4218 78.3903 59.4408 78.3611 59.4597 78.326C59.4692 78.3085 59.4787 78.2968 59.4881 78.2793C59.4929 78.2618 59.5023 78.2443 59.5071 78.2267C60.5302 75.6508 59.256 56.9941 59.256 55.125Z" fill="#30AA94"/>
  <path d="M26.869 0.975342V3.12808C26.869 4.43365 32.151 10.5535 37.4405 10.5484C42.7224 10.5484 48.0119 4.42855 48.0119 3.12808V0.975342L59.7524 3.06252C59.9087 4.56807 59.9277 10.8276 59.8851 18.4769H14.9959C14.9533 10.8276 14.9722 4.56807 15.1285 3.06252L26.869 0.975342Z" fill="#267A63"/>
  <path d="M59.4689 44.555L59.7332 46.227C59.9975 46.1703 61.1427 46.1137 61.407 46.057C64.8335 45.3088 70.861 43.5688 74 41.8854L73.6383 40.0604C67.43 43.0643 61.6574 44.2036 59.4643 44.555H59.4689Z" fill="#267A63"/>
  <path d="M0 41.8375C3.13497 43.5395 9.15949 45.2987 12.5769 46.0551C12.8363 46.1124 13.9847 46.1697 14.2486 46.227L14.5357 44.399C11.8175 43.9005 6.26068 42.5997 0.435284 39.6199L0 41.8433V41.8375Z" fill="#267A63"/>
</svg>`,
  clothe_7: `<svg xmlns="http://www.w3.org/2000/svg" width="74" height="82" viewBox="0 0 74 82" fill="none">
  <path d="M61.4431 46.0526C61.1841 46.1107 60.0371 46.1689 59.7735 46.227L58.5895 38.5694C58.5756 35.9354 58.5895 5.53151 58.5895 3.06035C58.5895 3.06035 68.0384 4.43672 69.375 10.7687L74 41.7731C70.8689 43.5 64.8518 45.2851 61.4385 46.0526H61.4431Z" fill="#FFF5DA"/>
  <path d="M12.5577 46.0526C12.8167 46.1107 13.9633 46.1689 14.2269 46.227L15.4105 38.5694C15.4244 35.9354 15.4105 5.53151 15.4105 3.06035C15.4105 3.06035 6.84218 4.8772 5.50595 11.2092L0 41.7731C3.13019 43.5 9.1455 45.2851 12.5577 46.0526Z" fill="#FFF5DA"/>
  <path d="M59.256 55.125C59.256 55.125 60.0045 8.02773 59.5355 3.50083L37.2202 15.364L14.905 3.50083C14.436 8.02773 15.1844 55.125 15.1844 55.125C15.1844 57 13.9102 75.6566 14.9334 78.2267C14.9381 78.2443 14.9429 78.2618 14.9523 78.2793C14.9618 78.2968 14.9713 78.3085 14.9808 78.326C14.9997 78.3611 15.0187 78.3903 15.0423 78.4253C15.0613 78.4487 15.0755 78.4662 15.0944 78.4779C16.4113 79.8681 24.9613 80.9546 35.5008 81.0246C35.586 81.0246 36.8602 81.0246 37.2202 81.0246C37.5802 81.0246 38.8544 81.0246 38.9397 81.0246C49.4792 80.9546 58.0292 79.8681 59.346 78.4779C59.365 78.4662 59.3792 78.4429 59.3981 78.4253C59.4218 78.3903 59.4408 78.3611 59.4597 78.326C59.4692 78.3085 59.4787 78.2968 59.4881 78.2793C59.4929 78.2618 59.5023 78.2443 59.5071 78.2267C60.5302 75.6508 59.256 56.9941 59.256 55.125Z" fill="#FEF8E9"/>
  <path d="M26.869 0.975342V3.12808C26.869 4.43365 32.151 10.5535 37.4405 10.5484C42.7224 10.5484 48.0119 4.42855 48.0119 3.12808V0.975342L59.7524 3.06252C59.9087 4.56807 59.9277 10.8276 59.8851 18.4769H14.9959C14.9533 10.8276 14.9722 4.56807 15.1285 3.06252L26.869 0.975342Z" fill="#D01F3D"/>
  <path d="M59.4689 44.555L59.7332 46.227C59.9975 46.1703 61.1427 46.1137 61.407 46.057C64.8335 45.3088 70.861 43.5688 74 41.8854L73.6383 40.0604C67.43 43.0643 61.6574 44.2036 59.4643 44.555H59.4689Z" fill="#D01F3D"/>
  <path d="M0 41.8375C3.13497 43.5395 9.15949 45.2987 12.5769 46.0551C12.8363 46.1124 13.9847 46.1697 14.2486 46.227L14.5357 44.399C11.8175 43.9005 6.26068 42.5997 0.435284 39.6199L0 41.8433V41.8375Z" fill="#D01F3D"/>
  <path d="M44.9286 20.6793H57.2619V32.2913L51.0952 35.6555L44.9286 32.4736V20.6793Z" fill="#D01F3D"/>
</svg>`,
  clothe_8: `<svg xmlns="http://www.w3.org/2000/svg" width="78" height="74" viewBox="0 0 78 74" fill="none">
  <path d="M0.875 50.844C0.875 50.844 1.375 14.844 2.875 9.84399C4.375 4.84399 14.0295 3.59399 14.0295 3.59399C14.0295 5.69105 14.0447 30.1077 14.0295 32.344L12.614 53.0139C7.98295 49.5704 1.46814 51.2696 0.875 50.844Z" fill="#3A3A3A"/>
  <path d="M77.125 51.344C77.125 51.344 76.6875 14.844 75.375 9.59399C74.0625 4.34399 65.4384 3.67407 65.4384 3.67407C65.4384 5.77113 64.3598 28.1077 64.375 30.344L65.875 54.094C70.5061 50.6505 71.7819 51.7696 72.375 51.344H77.125Z" fill="#3A3A3A"/>
  <path d="M65.5297 3.67375L60.1078 2.39694C58.777 11.3346 50.2753 18.2293 39.9866 18.2293C29.6979 18.2293 21.1202 11.2727 19.8502 2.27313L13.9112 3.66601C12.614 16.094 14.839 53.1754 14.839 53.1754C14.839 53.9957 14.839 63.594 14.839 68.0402C25.698 73.8594 44.6369 76.1929 63.625 68.594C63.125 64.094 63.125 54.1565 63.125 53.344C63.125 53.344 66.0848 7.98393 65.5373 3.66601L65.5297 3.67375Z" fill="#1D1D1B"/>
  <path d="M17.0595 2.75296L25.9642 0.593994C25.9642 0.593994 26.0478 17.2079 39.9866 18.2371L34.8993 25.9366C34.8993 25.9366 19.5157 20.9532 17.0595 2.75296Z" fill="#ECECEC"/>
  <path d="M62.6476 2.75296L53.7429 0.593994C53.7429 0.593994 53.6593 17.2079 39.7205 18.2371L44.8078 25.9366C44.8078 25.9366 60.1914 20.9532 62.6476 2.75296Z" fill="#ECECEC"/>
  <path d="M39.8421 24.9926C40.4175 24.9926 40.8839 24.5179 40.8839 23.9324C40.8839 23.3469 40.4175 22.8723 39.8421 22.8723C39.2667 22.8723 38.8003 23.3469 38.8003 23.9324C38.8003 24.5179 39.2667 24.9926 39.8421 24.9926Z" fill="#ECECEC"/>
  <path d="M39.8421 47.7509C40.4175 47.7509 40.8839 47.2763 40.8839 46.6908C40.8839 46.1053 40.4175 45.6306 39.8421 45.6306C39.2667 45.6306 38.8003 46.1053 38.8003 46.6908C38.8003 47.2763 39.2667 47.7509 39.8421 47.7509Z" fill="#ECECEC"/>
  <path d="M39.8421 70.5092C40.4175 70.5092 40.8839 70.0345 40.8839 69.4491C40.8839 68.8636 40.4175 68.3889 39.8421 68.3889C39.2667 68.3889 38.8003 68.8636 38.8003 69.4491C38.8003 70.0345 39.2667 70.5092 39.8421 70.5092Z" fill="#ECECEC"/>
</svg>`,
  clothe_9: `<svg xmlns="http://www.w3.org/2000/svg" width="78" height="74" viewBox="0 0 78 74" fill="none">
  <path d="M0.875 50.844C0.875 50.844 1.375 14.844 2.875 9.84399C4.375 4.84399 14.0295 3.59399 14.0295 3.59399C14.0295 5.69105 14.0447 30.1077 14.0295 32.344L12.614 53.0139C7.98295 49.5704 1.46814 51.2696 0.875 50.844Z" fill="#E8E8E8"/>
  <path d="M77.125 51.344C77.125 51.344 76.6875 14.844 75.375 9.59399C74.0625 4.34399 65.4384 3.67407 65.4384 3.67407C65.4384 5.77113 64.3598 28.1077 64.375 30.344L65.875 54.094C70.5061 50.6505 71.7819 51.7696 72.375 51.344H77.125Z" fill="#E8E8E8"/>
  <path d="M65.5297 3.67375L60.1078 2.39694C58.777 11.3346 50.2753 18.2293 39.9866 18.2293C29.6979 18.2293 21.1202 11.2727 19.8502 2.27313L13.9112 3.66601C12.614 16.094 14.839 53.1754 14.839 53.1754C14.839 53.9957 14.839 63.594 14.839 68.0402C25.698 73.8594 44.6369 76.1929 63.625 68.594C63.125 64.094 63.125 54.1565 63.125 53.344C63.125 53.344 66.0848 7.98393 65.5373 3.66601L65.5297 3.67375Z" fill="#F0EDED"/>
  <path d="M17.0595 2.75296L25.9642 0.593994C25.9642 0.593994 26.0478 17.2079 39.9866 18.2371L34.8993 25.9366C34.8993 25.9366 19.5157 20.9532 17.0595 2.75296Z" fill="#0E0E0E"/>
  <path d="M62.6476 2.75296L53.7429 0.593994C53.7429 0.593994 53.6593 17.2079 39.7205 18.2371L44.8078 25.9366C44.8078 25.9366 60.1914 20.9532 62.6476 2.75296Z" fill="#0E0E0E"/>
  <path d="M39.8421 24.9926C40.4175 24.9926 40.8839 24.5179 40.8839 23.9324C40.8839 23.3469 40.4175 22.8723 39.8421 22.8723C39.2667 22.8723 38.8003 23.3469 38.8003 23.9324C38.8003 24.5179 39.2667 24.9926 39.8421 24.9926Z" fill="#0E0E0E"/>
  <path d="M39.8421 47.7509C40.4175 47.7509 40.8839 47.2763 40.8839 46.6908C40.8839 46.1053 40.4175 45.6306 39.8421 45.6306C39.2667 45.6306 38.8003 46.1053 38.8003 46.6908C38.8003 47.2763 39.2667 47.7509 39.8421 47.7509Z" fill="#0E0E0E"/>
  <path d="M39.8421 70.5092C40.4175 70.5092 40.8839 70.0345 40.8839 69.4491C40.8839 68.8636 40.4175 68.3889 39.8421 68.3889C39.2667 68.3889 38.8003 68.8636 38.8003 69.4491C38.8003 70.0345 39.2667 70.5092 39.8421 70.5092Z" fill="#0E0E0E"/>
</svg>`,
  clothe_10: `<svg xmlns="http://www.w3.org/2000/svg" width="78" height="74" viewBox="0 0 161 205" fill="none">
  <path d="M0.229004 203.651H16.3219C16.4943 202.672 25.5753 143.572 26.9356 132.293C28.2 121.839 29.4261 93.9287 29.407 77.8732C29.656 85.2967 30.135 88.2507 30.9013 103.539C31.4761 114.991 31.8401 154.947 31.0354 181.802C31.0354 181.802 44.9826 204.993 80.4062 204.993C115.83 204.993 129.815 181.802 129.815 181.802C129.011 154.947 129.451 115.24 129.873 104.018C130.39 90.5334 131.195 85.2967 131.444 77.8732C131.444 93.9287 132.651 121.82 133.915 132.293C135.295 143.572 143.954 202.653 144.126 203.651H160.679C159.779 176.335 159.606 144.512 156.752 121.052C155.219 108.469 154.204 59.8611 154.453 50.6537C154.759 39.8349 152.671 27.635 140.276 25.6017C131.884 24.2206 121.137 20.5376 113.148 18.0248C100.005 13.9006 100.024 0.0126953 100.024 0.0126953C100.024 0.0126953 86.5943 6.45791 81.0384 8.52958C80.4062 9.46951 80.3679 21.9379 80.3679 21.9379C80.3679 21.9379 80.1763 9.23933 79.6974 8.52958C73.9307 5.95917 61.0181 0.0126953 61.0181 0.0126953C61.0181 0.0126953 60.8457 13.8814 47.7031 18.0248C39.7141 20.5376 28.9664 24.2206 20.575 25.6017C8.19883 27.635 6.11058 39.8349 6.39795 50.6537C6.64701 59.8611 5.63162 108.488 4.09897 121.052C1.24439 144.531 1.12944 176.355 0.229004 203.651Z" fill="#252525"/>
  <path d="M87.7246 25.4291C87.6097 25.4291 87.4947 25.4099 87.3989 25.3524C87.2074 25.2564 87.0541 25.0838 86.9966 24.8728C85.4065 19.2908 82.7052 11.0041 81.6132 10.045C81.2875 9.75724 81.2683 9.27769 81.5557 8.95159C81.8431 8.64468 82.322 8.60631 82.6477 8.89405C84.2953 10.3519 87.1882 19.9813 88.2227 23.5492C89.9087 22.5518 94.2959 19.521 101.174 11.7138C101.461 11.3877 101.94 11.3685 102.247 11.6371C102.572 11.9248 102.591 12.4044 102.323 12.7113C93.1081 23.1848 88.2419 25.2756 88.0312 25.3715C87.9354 25.4099 87.8396 25.4291 87.7438 25.4291H87.7246Z" fill="#585B5C"/>
  <path d="M72.9153 25.4291C72.8195 25.4291 72.7237 25.4099 72.628 25.3715C72.4172 25.2948 67.5702 23.1848 58.3359 12.7113C58.0485 12.3852 58.0869 11.9056 58.4125 11.6371C58.7382 11.3494 59.2172 11.3877 59.4854 11.7138C66.3632 19.521 70.7505 22.5518 72.4364 23.5492C73.4709 20.0005 76.383 10.3519 78.0114 8.89405C78.3371 8.60631 78.8161 8.64468 79.1034 8.95159C79.3908 9.27769 79.3525 9.75724 79.046 10.045C77.9539 11.0041 75.2526 19.2908 73.6625 24.8728C73.605 25.0838 73.4518 25.2564 73.2602 25.3524C73.1644 25.4099 73.0494 25.4291 72.9345 25.4291H72.9153Z" fill="#585B5C"/>
  <path d="M10.6894 183.011C8.37125 183.011 5.70826 182.857 3.25601 182.397C2.83452 182.32 2.56631 181.917 2.64294 181.495C2.71957 181.073 3.1219 180.805 3.54338 180.882C9.48243 182.032 16.9158 181.131 16.9925 181.131C17.4139 181.073 17.7971 181.38 17.8546 181.802C17.912 182.224 17.6055 182.608 17.184 182.665C16.9925 182.685 14.2145 183.03 10.6702 183.03L10.6894 183.011Z" fill="#585B5C"/>
  <path d="M150.18 183.011C146.655 183.011 143.858 182.665 143.667 182.646C143.245 182.589 142.958 182.205 142.996 181.783C143.054 181.361 143.437 181.073 143.858 181.112C143.935 181.112 151.387 182.013 157.307 180.862C157.729 180.786 158.131 181.054 158.208 181.476C158.284 181.898 158.016 182.301 157.595 182.378C155.142 182.857 152.499 182.992 150.161 182.992L150.18 183.011Z" fill="#585B5C"/>
  <path d="M79.8123 202.653C79.4866 202.653 79.1992 202.308 79.1992 201.867V18.3317C79.1992 17.8905 79.4674 17.5452 79.8123 17.5452C80.1571 17.5452 80.4253 17.8905 80.4253 18.3317V201.867C80.4253 202.308 80.1571 202.653 79.8123 202.653Z" fill="#585B5C"/>
  <path d="M84.6402 188.247C84.6402 189.072 83.9696 189.744 83.1458 189.744C82.322 189.744 81.6515 189.072 81.6515 188.247C81.6515 187.423 82.322 186.751 83.1458 186.751C83.9696 186.751 84.6402 187.423 84.6402 188.247Z" fill="#585B5C"/>
  <path d="M84.6402 165.593C84.6402 166.418 83.9696 167.09 83.1458 167.09C82.322 167.09 81.6515 166.418 81.6515 165.593C81.6515 164.768 82.322 164.097 83.1458 164.097C83.9696 164.097 84.6402 164.768 84.6402 165.593Z" fill="#585B5C"/>
  <path d="M84.6402 142.939C84.6402 143.764 83.9696 144.435 83.1458 144.435C82.322 144.435 81.6515 143.764 81.6515 142.939C81.6515 142.114 82.322 141.443 83.1458 141.443C83.9696 141.443 84.6402 142.114 84.6402 142.939Z" fill="#585B5C"/>
  <path d="M84.6402 120.266C84.6402 121.091 83.9696 121.762 83.1458 121.762C82.322 121.762 81.6515 121.091 81.6515 120.266C81.6515 119.441 82.322 118.77 83.1458 118.77C83.9696 118.77 84.6402 119.441 84.6402 120.266Z" fill="#585B5C"/>
  <path d="M84.6402 97.6116C84.6402 98.4365 83.9696 99.1079 83.1458 99.1079C82.322 99.1079 81.6515 98.4365 81.6515 97.6116C81.6515 96.7868 82.322 96.1154 83.1458 96.1154C83.9696 96.1154 84.6402 96.7868 84.6402 97.6116Z" fill="#585B5C"/>
  <path d="M84.6402 74.9575C84.6402 75.7823 83.9696 76.4537 83.1458 76.4537C82.322 76.4537 81.6515 75.7823 81.6515 74.9575C81.6515 74.1327 82.322 73.4613 83.1458 73.4613C83.9696 73.4613 84.6402 74.1327 84.6402 74.9575Z" fill="#585B5C"/>
  <path d="M84.6402 52.3033C84.6402 53.1282 83.9696 53.7995 83.1458 53.7995C82.322 53.7995 81.6515 53.1282 81.6515 52.3033C81.6515 51.4785 82.322 50.8071 83.1458 50.8071C83.9696 50.8071 84.6402 51.4785 84.6402 52.3033Z" fill="#585B5C"/>
  <path d="M84.6402 29.63C84.6402 30.4548 83.9696 31.1262 83.1458 31.1262C82.322 31.1262 81.6515 30.4548 81.6515 29.63C81.6515 28.8052 82.322 28.1338 83.1458 28.1338C83.9696 28.1338 84.6402 28.8052 84.6402 29.63Z" fill="#585B5C"/>
  <path d="M84.6402 19.7895C84.6402 20.6144 83.9696 21.2857 83.1458 21.2857C82.322 21.2857 81.6515 20.6144 81.6515 19.7895C81.6515 18.9647 82.322 18.2933 83.1458 18.2933C83.9696 18.2933 84.6402 18.9647 84.6402 19.7895Z" fill="#585B5C"/>
</svg>`,
  clothe_11: `<svg xmlns="http://www.w3.org/2000/svg" width="78" height="74" viewBox="0 0 153 175" fill="none">
  <path d="M24.9448 86.0926C25.2151 89.9483 25.5627 94.6671 26.0069 103.587C26.5862 115.039 27.509 148.091 26.6786 174.946C26.6786 174.946 39.4422 174.946 75.1489 174.946C110.856 174.946 125.156 174.562 125.156 174.562C124.345 147.707 125.325 115.307 125.75 104.086C126.272 90.6004 127.083 85.3637 127.334 77.9402C127.334 80.3955 127.411 86.0926 127.411 86.0926L152.149 80.1653C152.149 80.1653 150.43 54.4612 150.546 50.7015C150.855 39.8828 148.75 27.6829 136.256 25.6496C127.797 24.2685 116.964 20.5855 108.911 18.0726C95.6631 13.9484 95.6824 0.0605469 95.6824 0.0605469C95.6824 0.0605469 82.1451 6.50576 76.5448 8.57744C75.9075 9.51736 75.8689 21.9858 75.8689 21.9858C75.8689 21.9858 75.6758 9.28718 75.193 8.57744C69.3803 6.00702 56.3644 0.0605469 56.3644 0.0605469C56.3644 0.0605469 56.1906 13.9293 42.943 18.0726C34.8901 20.5855 24.0564 24.2685 15.598 25.6496C5.53681 27.2801 2.21525 35.5092 1.4621 44.3138C1.2883 46.4431 0.148926 80.1845 0.148926 80.1845C0.148926 80.1845 12.2185 85.0184 24.9448 86.131V86.0926Z" fill="#F4F4F4"/>
  <path d="M83.2845 25.4769C83.1686 25.4769 83.0527 25.4578 82.9562 25.4002C82.7631 25.3043 82.6086 25.1317 82.5506 24.9207C80.9478 19.3386 78.2249 11.0519 77.1241 10.0928C76.7959 9.8051 76.7765 9.32554 77.0662 8.99944C77.3559 8.69253 77.8387 8.65416 78.167 8.9419C79.8278 10.3997 82.7438 20.0292 83.7866 23.5971C85.486 22.5996 89.9083 19.5688 96.8411 11.7617C97.1308 11.4356 97.6135 11.4164 97.9225 11.6849C98.2508 11.9727 98.2701 12.4522 97.9998 12.7592C88.711 23.2326 83.8059 25.3235 83.5935 25.4194C83.4969 25.4578 83.4004 25.4769 83.3038 25.4769H83.2845Z" fill="#8FC5D8"/>
  <path d="M68.3568 25.4769C68.2602 25.4769 68.1637 25.4578 68.0671 25.4194C67.8547 25.3427 62.9689 23.2326 53.6608 12.7592C53.3711 12.4331 53.4098 11.9535 53.7381 11.6849C54.0663 11.3972 54.5491 11.4356 54.8195 11.7617C61.7523 19.5688 66.1746 22.5996 67.874 23.5971C68.9168 20.0484 71.8521 10.3997 73.4936 8.9419C73.8219 8.65416 74.3047 8.69253 74.5944 8.99944C74.884 9.32554 74.8454 9.8051 74.5364 10.0928C73.4357 11.0519 70.7128 19.3386 69.1099 24.9207C69.052 25.1317 68.8975 25.3043 68.7044 25.4002C68.6078 25.4578 68.492 25.4769 68.3761 25.4769H68.3568Z" fill="#8FC5D8"/>
  <path d="M24.5585 73.9119L23.6702 82.4288L0.264794 76.9619C0.20686 78.9377 0.148926 80.1653 0.148926 80.1653C0.148926 80.1653 12.2765 84.9609 24.9448 86.1118L24.5585 73.9311V73.9119Z" fill="#8FC5D8"/>
  <path d="M127.372 74.1421L128.647 82.448L152.052 76.9619C152.11 78.9377 152.168 80.1653 152.168 80.1653C152.168 80.1653 140.041 84.9609 127.372 86.1118V74.1421Z" fill="#8FC5D8"/>
</svg>`,
  clothe_12: `<svg xmlns="http://www.w3.org/2000/svg" width="72" height="76" viewBox="0 0 72 76" fill="none">
  <path d="M71.4545 17.7256C71.4176 15.0612 67.7768 4.20012 62.3755 3.31199C58.7162 2.71065 54.0246 1.10091 50.5405 0C49.5358 0.379306 47.8582 8.20597 36.0048 8.20597C24.1514 8.20597 22.4739 0.379306 21.4692 0C17.985 1.10091 13.2935 2.71065 9.63419 3.31199C4.22365 4.20012 0.582822 15.0612 0.545953 17.7256C2.74888 22.4253 13.7543 28.781 13.7543 28.781C13.7543 28.781 14.0124 64.0935 13.7267 74.9083C13.7267 74.9083 16.6025 76 35.6638 76C35.7836 76 35.885 76 36.0048 76C36.1154 76 36.226 76 36.3459 76C55.4072 76 58.283 74.9083 58.283 74.9083C57.9973 64.0935 58.2553 28.781 58.2553 28.781C58.2553 28.781 69.2608 22.4253 71.4637 17.7256H71.4545Z" fill="black"/>
  <path d="M36.0048 10.8796C48.3007 10.8796 51.4991 3.18247 52.9278 0.758612C52.0982 0.490323 51.2871 0.231284 50.5497 0C49.545 0.379306 47.8675 8.20597 36.014 8.20597C24.1606 8.20597 22.4739 0.379306 21.4692 0C20.7226 0.231284 19.9207 0.490323 19.0911 0.758612C20.5106 3.18247 23.7182 10.8796 36.0048 10.8796Z" fill="#1C75BC"/>
  <path d="M13.7543 28.781C13.7543 28.781 1.94698 15.7181 1.12664 14.8207C0.121958 15.2278 0.0850887 16.9115 0.380042 17.8459C0.674995 18.7803 6.41736 25.4598 13.7543 28.781Z" fill="#1C75BC"/>
  <path d="M58.2461 28.781C58.2461 28.781 70.0535 15.7181 70.8738 14.8207C71.8785 15.2278 71.9153 16.9115 71.6204 17.8459C71.3254 18.7803 65.5831 25.4598 58.2461 28.781Z" fill="#1C75BC"/>
</svg>`,
  clothe_13: `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="88" viewBox="0 0 76 88" fill="none">
  <path d="M12.393 43.2946C12.5282 45.2224 12.702 47.5818 12.9241 52.0417C13.2138 57.7676 13.6751 74.2938 13.26 87.7213C13.26 87.7213 19.6417 87.7213 37.4951 87.7213C55.3485 87.7213 62.4986 87.5295 62.4986 87.5295C62.093 74.102 62.5834 57.9018 62.7958 52.2911C63.0565 45.5485 63.462 42.9301 63.5875 39.2184C63.5875 40.4461 63.6262 43.2946 63.6262 43.2946L75.9951 40.331C75.9951 40.331 75.1358 27.4789 75.1937 25.599C75.3482 20.1897 74.2957 14.0897 68.0485 13.0731C63.8193 12.3825 58.4024 10.541 54.376 9.2846C47.7522 7.22252 47.7619 0.278564 47.7619 0.278564C47.7619 0.278564 40.9932 3.50117 38.1931 4.53701C37.8744 5.00697 37.8551 11.2412 37.8551 11.2412C37.8551 11.2412 37.7586 4.89188 37.5172 4.53701C34.6108 3.2518 28.1029 0.278564 28.1029 0.278564C28.1029 0.278564 28.016 7.21292 21.3921 9.2846C17.3657 10.541 11.9489 12.3825 7.71968 13.0731C2.68906 13.8883 1.02828 18.0029 0.651705 22.4052C0.564803 23.4698 -0.00488281 40.3406 -0.00488281 40.3406C-0.00488281 40.3406 6.02993 42.7575 12.393 43.3138V43.2946Z" fill="#CFE9FF"/>
  <path d="M41.563 12.9867C41.5051 12.9867 41.4472 12.9771 41.3989 12.9483C41.3023 12.9004 41.2251 12.8141 41.1961 12.7086C40.3947 9.91754 39.0332 5.77419 38.4829 5.29464C38.3187 5.15077 38.3091 4.91099 38.4539 4.74794C38.5987 4.59449 38.8401 4.5753 39.0043 4.71917C39.8347 5.44809 41.2927 10.2628 41.8141 12.0468C42.6638 11.548 44.8749 10.0326 48.3413 6.12906C48.4862 5.96601 48.7276 5.95642 48.882 6.0907C49.0462 6.23456 49.0558 6.47434 48.9207 6.6278C44.2763 11.8645 41.8237 12.91 41.7175 12.9579C41.6692 12.9771 41.621 12.9867 41.5727 12.9867H41.563Z" fill="#83BDE5"/>
  <path d="M34.0989 12.9867C34.0506 12.9867 34.0023 12.9771 33.954 12.9579C33.8478 12.9196 31.4049 11.8645 26.7509 6.6278C26.6061 6.46475 26.6254 6.22497 26.7895 6.0907C26.9537 5.94683 27.1951 5.96601 27.3302 6.12906C30.7966 10.0326 33.0078 11.548 33.8575 12.0468C34.3789 10.2724 35.8466 5.44809 36.6673 4.71917C36.8314 4.5753 37.0728 4.59449 37.2177 4.74794C37.3625 4.91099 37.3432 5.15077 37.1887 5.29464C36.6383 5.77419 35.2769 9.91754 34.4755 12.7086C34.4465 12.8141 34.3692 12.9004 34.2727 12.9483C34.2244 12.9771 34.1665 12.9867 34.1085 12.9867H34.0989Z" fill="#83BDE5"/>
  <path d="M12.1999 37.2043L11.7558 41.4628L0.0530514 38.7293C0.0240843 39.7172 -0.00488281 40.331 -0.00488281 40.331C-0.00488281 40.331 6.0589 42.7288 12.393 43.3043L12.1999 37.2139V37.2043Z" fill="#83BDE5"/>
  <path d="M63.6069 37.3196L64.2442 41.4725L75.9469 38.7295C75.9759 39.7174 76.0049 40.3312 76.0049 40.3312C76.0049 40.3312 69.9411 42.729 63.6069 43.3044V37.3196Z" fill="#83BDE5"/>
</svg>`,
  clothe_14: `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="88" viewBox="0 0 153 175" fill="none">
  <path d="M24.9638 86.0926C25.2342 89.9483 25.5818 94.6671 26.0259 103.587C26.6053 115.039 27.528 148.091 26.6976 174.946C26.6976 174.946 39.4612 174.946 75.168 174.946C110.875 174.946 125.175 174.562 125.175 174.562C124.364 147.707 125.344 115.307 125.769 104.086C126.291 90.6004 127.102 85.3637 127.353 77.9402C127.353 80.3955 127.43 86.0926 127.43 86.0926L152.168 80.1653C152.168 80.1653 150.449 54.4612 150.565 50.7015C150.874 39.8828 148.769 27.6829 136.275 25.6496C127.816 24.2685 116.983 20.5855 108.93 18.0726C95.6821 13.9484 95.7015 0.0605469 95.7015 0.0605469C95.7015 0.0605469 82.1642 6.50576 76.5639 8.57744C75.9266 9.51736 75.888 21.9858 75.888 21.9858C75.888 21.9858 75.6948 9.28718 75.2121 8.57744C69.3993 6.00702 56.3834 0.0605469 56.3834 0.0605469C56.3834 0.0605469 56.2096 13.9293 42.962 18.0726C34.9092 20.5855 24.0755 24.2685 15.6171 25.6496C5.55585 27.2801 2.23429 35.5092 1.48114 44.3138C1.30734 46.4431 0.167969 80.1845 0.167969 80.1845C0.167969 80.1845 12.2376 85.0184 24.9638 86.131V86.0926Z" fill="#232323"/>
  <path d="M83.3035 25.4769C83.1877 25.4769 83.0718 25.4578 82.9752 25.4002C82.7821 25.3043 82.6276 25.1317 82.5697 24.9207C80.9668 19.3386 78.2439 11.0519 77.1432 10.0928C76.8149 9.8051 76.7956 9.32554 77.0853 8.99944C77.3749 8.69253 77.8577 8.65416 78.186 8.9419C79.8468 10.3997 82.7628 20.0292 83.8056 23.5971C85.505 22.5996 89.9273 19.5688 96.8601 11.7617C97.1498 11.4356 97.6326 11.4164 97.9416 11.6849C98.2699 11.9727 98.2892 12.4522 98.0188 12.7592C88.73 23.2326 83.8249 25.3235 83.6125 25.4194C83.5159 25.4578 83.4194 25.4769 83.3228 25.4769H83.3035Z" fill="#494949"/>
  <path d="M68.3758 25.4769C68.2793 25.4769 68.1827 25.4578 68.0862 25.4194C67.8737 25.3427 62.9879 23.2326 53.6799 12.7592C53.3902 12.4331 53.4288 11.9535 53.7571 11.6849C54.0854 11.3972 54.5682 11.4356 54.8385 11.7617C61.7713 19.5688 66.1936 22.5996 67.893 23.5971C68.9359 20.0484 71.8712 10.3997 73.5127 8.9419C73.841 8.65416 74.3237 8.69253 74.6134 8.99944C74.9031 9.32554 74.8645 9.8051 74.5555 10.0928C73.4547 11.0519 70.7318 19.3386 69.129 24.9207C69.071 25.1317 68.9165 25.3043 68.7234 25.4002C68.6269 25.4578 68.511 25.4769 68.3951 25.4769H68.3758Z" fill="#494949"/>
  <path d="M24.5776 73.9119L23.6892 82.4288L0.283837 76.9619C0.225903 78.9377 0.167969 80.1653 0.167969 80.1653C0.167969 80.1653 12.2955 84.9609 24.9638 86.1118L24.5776 73.9311V73.9119Z" fill="#494949"/>
  <path d="M127.391 74.1421L128.666 82.448L152.071 76.9619C152.129 78.9377 152.187 80.1653 152.187 80.1653C152.187 80.1653 140.06 84.9609 127.391 86.1118V74.1421Z" fill="#494949"/>
</svg>`,
  clothe_15: `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="88" viewBox="0 0 163 173" fill="none">
  <path d="M161.36 40.6188C161.277 34.5889 153.037 10.0085 140.813 7.99849C132.531 6.63756 121.914 2.99447 114.028 0.50293C111.755 1.36136 107.958 19.0743 81.132 19.0743C54.3058 19.0743 50.5093 1.36136 48.2355 0.50293C40.3504 2.99447 29.7326 6.63756 21.4511 7.99849C9.20617 10.0085 0.966402 34.5889 0.882961 40.6188C5.86854 51.255 30.7756 65.6389 30.7756 65.6389C30.7756 65.6389 31.3597 145.557 30.713 170.032C30.713 170.032 37.2214 172.503 80.3602 172.503C80.6313 172.503 80.8608 172.503 81.132 172.503C81.3823 172.503 81.6326 172.503 81.9038 172.503C125.043 172.503 131.551 170.032 131.551 170.032C130.904 145.557 131.488 65.6389 131.488 65.6389C131.488 65.6389 156.395 51.255 161.381 40.6188H161.36Z" fill="#FE862D"/>
  <path d="M81.132 25.1252C108.959 25.1252 116.198 7.70536 119.431 2.21979C117.554 1.61261 115.718 1.02636 114.049 0.50293C111.776 1.36136 107.979 19.0743 81.1529 19.0743C54.3267 19.0743 50.5093 1.36136 48.2355 0.50293C46.5458 1.02636 44.731 1.61261 42.8536 2.21979C46.0661 7.70536 53.3254 25.1252 81.132 25.1252Z" fill="#2C251F"/>
  <path d="M30.7756 65.6389C30.7756 65.6389 4.05371 36.0754 2.19715 34.0445C-0.0766065 34.9657 -0.160047 38.7763 0.507478 40.891C1.175 43.0057 14.1709 58.1224 30.7756 65.6389Z" fill="#2C251F"/>
  <path d="M131.468 65.6389C131.468 65.6389 158.189 36.0754 160.046 34.0445C162.32 34.9657 162.403 38.7763 161.736 40.891C161.068 43.0057 148.072 58.1224 131.468 65.6389Z" fill="#2C251F"/>
</svg>`,
  clothe_16: `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="88" viewBox="0 0 163 173" fill="none">
  <path d="M161.316 40.6188C161.233 34.5889 152.993 10.0085 140.769 7.99849C132.487 6.63756 121.87 2.99447 113.985 0.50293C111.711 1.36136 107.914 19.0743 81.0881 19.0743C54.2619 19.0743 50.4653 1.36136 48.1916 0.50293C40.3064 2.99447 29.6886 6.63756 21.4071 7.99849C9.16222 10.0085 0.922456 34.5889 0.839016 40.6188C5.82459 51.255 30.7316 65.6389 30.7316 65.6389C30.7316 65.6389 31.3157 145.557 30.669 170.032C30.669 170.032 37.1774 172.503 80.3162 172.503C80.5874 172.503 80.8169 172.503 81.0881 172.503C81.3384 172.503 81.5887 172.503 81.8599 172.503C124.999 172.503 131.507 170.032 131.507 170.032C130.86 145.557 131.444 65.6389 131.444 65.6389C131.444 65.6389 156.352 51.255 161.337 40.6188H161.316Z" fill="#C9E6FF"/>
  <path d="M81.0881 25.1252C108.916 25.1252 116.154 7.70536 119.387 2.21979C117.51 1.61261 115.674 1.02636 114.005 0.50293C111.732 1.36136 107.935 19.0743 81.1089 19.0743C54.2827 19.0743 50.4653 1.36136 48.1916 0.50293C46.5019 1.02636 44.6871 1.61261 42.8097 2.21979C46.0221 7.70536 53.2815 25.1252 81.0881 25.1252Z" fill="#0071CE"/>
  <path d="M30.7316 65.6389C30.7316 65.6389 4.00976 36.0754 2.15321 34.0445C-0.120552 34.9657 -0.203992 38.7763 0.463533 40.891C1.13106 43.0057 14.1269 58.1224 30.7316 65.6389Z" fill="#0071CE"/>
  <path d="M131.424 65.6389C131.424 65.6389 158.145 36.0754 160.002 34.0445C162.276 34.9657 162.359 38.7763 161.692 40.891C161.024 43.0057 148.028 58.1224 131.424 65.6389Z" fill="#0071CE"/>
</svg>`,
  clothe_17: `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="88" viewBox="0 0 156 145"   fill="none">
  <path d="M0.0556641 100.072C0.0556641 100.072 1.07206 28.3807 4.12124 18.4236C7.17042 8.46649 26.796 5.97721 26.796 5.97721C26.796 10.1533 26.8269 58.7772 26.796 63.2307L23.9186 104.393C14.5046 97.5358 1.26139 100.92 0.0556641 100.072Z" fill="#E8E8E8"/>
  <path d="M155.056 101.068C155.056 101.068 154.166 28.3807 151.498 17.9258C148.83 7.47078 131.299 6.13668 131.299 6.13668C131.299 10.3128 129.107 54.7943 129.138 59.2478L132.187 106.544C141.601 99.6867 144.194 101.915 145.4 101.068H155.056Z" fill="#E8E8E8"/>
  <path d="M131.485 6.13603L120.463 3.59336C117.758 21.392 100.476 35.1224 79.5612 35.1224C58.6465 35.1224 41.2098 21.2687 38.6283 3.3468L26.5556 6.12062C23.9186 30.87 28.4414 104.715 28.4414 104.715C28.4414 106.348 28.4414 125.463 28.4414 134.317C50.5155 145.905 89.0143 150.553 127.613 135.42C126.597 126.458 126.597 106.669 126.597 105.051C126.597 105.051 132.613 14.7194 131.5 6.12062L131.485 6.13603Z" fill="#F0EDED"/>
  <path d="M32.9553 4.30234L51.0566 0.00292969C51.0566 0.00292969 51.2267 33.0884 79.5613 35.1379L69.2199 50.4709C69.2199 50.4709 37.9482 40.5469 32.9553 4.30234Z" fill="#C11717"/>
  <path d="M125.626 4.30234L107.525 0.00292969C107.525 0.00292969 107.355 33.0884 79.0202 35.1379L89.3616 50.4709C89.3616 50.4709 120.633 40.5469 125.626 4.30234Z" fill="#C11717"/>
  <path d="M79.2675 48.5909C80.4371 48.5909 81.3852 47.6457 81.3852 46.4797C81.3852 45.3137 80.4371 44.3685 79.2675 44.3685C78.0979 44.3685 77.1497 45.3137 77.1497 46.4797C77.1497 47.6457 78.0979 48.5909 79.2675 48.5909Z" fill="#C11717"/>
  <path d="M79.2675 93.9124C80.4371 93.9124 81.3852 92.9672 81.3852 91.8012C81.3852 90.6352 80.4371 89.69 79.2675 89.69C78.0979 89.69 77.1497 90.6352 77.1497 91.8012C77.1497 92.9672 78.0979 93.9124 79.2675 93.9124Z" fill="#C11717"/>
  <path d="M79.2675 139.234C80.4371 139.234 81.3852 138.289 81.3852 137.123C81.3852 135.957 80.4371 135.011 79.2675 135.011C78.0979 135.011 77.1497 135.957 77.1497 137.123C77.1497 138.289 78.0979 139.234 79.2675 139.234Z" fill="#C11717"/>
</svg>`,
  clothe_18: `<svg width="76" height="88" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#CDCDCD"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#737373"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#737373"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#737373"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#737373"/>
</svg>
`,
  clothe_19: `<svg width="76" height="88" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#B11C1C"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2A2929"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2A2929"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2A2929"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2A2929"/>
</svg>
`,
  clothe_20: `<svg width="76" height="88" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#E9B90C"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#827B35"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#827B35"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#827B35"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#827B35"/>
</svg>
`,
  clothe_21: `<svg width="76" height="88" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#098C4D"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#0C5D10"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#0C5D10"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#0C5D10"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#0C5D10"/>
</svg>
`,
  clothe_22: `<svg width="76" height="88" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#143351"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#486C90"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#486C90"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#486C90"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#486C90"/>
</svg>
`,
  clothe_23: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#B5B5B5"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#B5B5B5"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#A7A7A7"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#414141"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#414141"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#414141"/>
</svg>
`,
  clothe_24: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#7F1818"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#7F1818"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#902020"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#7A1818"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#7A1818"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#7A1818"/>
</svg>
`,
  clothe_25: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#333333"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#333333"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#333333"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#333333"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#333333"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#333333"/>
</svg>
`,
  clothe_26: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#E5E5E5"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#E5E5E5"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#E5E5E5"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#E5E5E5"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#E5E5E5"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#E5E5E5"/>
</svg>
`,
  clothe_27: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#FFA25D"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#FFA25D"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#FFA25D"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#FFA25D"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#FFA25D"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#FFA25D"/>
</svg>
`,
  clothe_28: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#7D3906"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#7D3906"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#7D3906"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#7D3906"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#7D3906"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#7D3906"/>
</svg>
`,
  clothe_29: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#098C4D"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#098C4D"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#098C4D"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#098C4D"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#098C4D"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#098C4D"/>
</svg>
`,
  clothe_30: `<svg width="76" height="88" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#1A446C"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#1A446C"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#1A446C"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#1A446C"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#1A446C"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#1A446C"/>
</svg>
`,
  clothe_31: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#854312"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#F9954B"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#F9954B"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#F9954B"/>
</svg>
`,
  clothe_32: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E9B90C"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E9B90C"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#E9B90C"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#E9B90C"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#E9B90C"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#E9B90C"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#E9B90C"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#E9B90C"/>
</svg>
`,
  clothe_33: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#F9954B"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#F9954B"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#F9954B"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#F9954B"/>
</svg>
`,
  clothe_34: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#ECECEC"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#8F8F8F"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#8F8F8F"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#8F8F8F"/>
</svg>
`,
  clothe_35: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#C11717"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#341D1D"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#341D1D"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#341D1D"/>
</svg>
`,
  clothe_36: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#098C4D"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#045C07"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#045C07"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#045C07"/>
</svg>
`,
  clothe_37: `<svg width="76" height="88" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#0071CE"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#113552"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#113552"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#113552"/>
</svg>
`,
  clothe_38: `<svg width="76" height="88" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="black"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="black"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="black"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#333333"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="#606060"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="#606060"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="#606060"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="#606060"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="#606060"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="#606060"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="#606060"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#636567"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#636567"/>
</svg>
`,
  clothe_39: `<svg width="76" height="88" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="#353535"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="black"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#B11C1C"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="black"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="black"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="black"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="black"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="black"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="black"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="black"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#6B1010"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#6B1010"/>
</svg>
`,
  clothe_40: `<svg width="76" height="88" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="#353535"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="#353535"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#0B0B0B"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="#353535"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="#353535"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="#353535"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="#353535"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="#353535"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="#353535"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="#353535"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#303030"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#303030"/>
</svg>
`,
  clothe_41: `<svg width="76" height="88" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#CDCDCD"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#6A6A6A"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#6A6A6A"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#6A6A6A"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#6A6A6A"/>
</svg>
`,
  clothe_42: `<svg width="76" height="88" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#B11C1C"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_43: `<svg width="76" height="88" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#E9B90C"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_44: `<svg width="76" height="88" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#098C4D"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_45: `<svg width="76" height="88" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#143351"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#ACACAC"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#ACACAC"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#ACACAC"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#ECECEC"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="#ECECEC"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#B3B3B3"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#B3B3B3"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#B3B3B3"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#B3B3B3"/>
</svg>
`,
  clothe_46: `<svg width="76" height="88" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#E9B90C"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#585B5C"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#585B5C"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#585B5C"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#585B5C"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#585B5C"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#585B5C"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#585B5C"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#585B5C"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#585B5C"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#585B5C"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#585B5C"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#585B5C"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#585B5C"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#585B5C"/>
</svg>
`,
  clothe_47: `<svg width="76" height="88" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#B11C1C"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#303030"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#303030"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#303030"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#303030"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#303030"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#303030"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#303030"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#303030"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#303030"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#303030"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#303030"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#303030"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#303030"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#303030"/>
</svg>
`,
  clothe_48: `<svg width="76" height="88" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#CFE9FF"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#5391C6"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#5391C6"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#5391C6"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#5391C6"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#5391C6"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#5391C6"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#5391C6"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#5391C6"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#5391C6"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#5391C6"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#5391C6"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#5391C6"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#5391C6"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#5391C6"/>
</svg>
`,
  clothe_49: `<svg width="76" height="88" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#143351"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#060606"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#060606"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#060606"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#060606"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#060606"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#060606"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#060606"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#060606"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#060606"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#060606"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#060606"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#060606"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#060606"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#060606"/>
</svg>
`,
  clothe_50: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C9E6FF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C9E6FF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C9E6FF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#434343"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#434343"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#434343"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#434343"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#434343"/>
</svg>
`,
  clothe_51: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C11717"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C11717"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C11717"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#272727"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#272727"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#272727"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#272727"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#272727"/>
</svg>
`,
  clothe_52: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FF8DDF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FF8DDF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FF8DDF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#A415A7"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#A415A7"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#A415A7"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#A415A7"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#A415A7"/>
</svg>
`,
  clothe_53: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FE862D"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FE862D"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FE862D"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#393939"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#393939"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#393939"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#393939"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#393939"/>
</svg>
`,
  clothe_54: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#A7A7A7"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#A7A7A7"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#A7A7A7"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#A7A7A7"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#A7A7A7"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#A7A7A7"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#A7A7A7"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#A7A7A7"/>
</svg>
`,
  clothe_55: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#7D3906"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#7D3906"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#7D3906"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#7D3906"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#7D3906"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#7D3906"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#7D3906"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#7D3906"/>
</svg>
`,
  clothe_56: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#030303"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#030303"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#030303"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#030303"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#030303"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#030303"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#030303"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#030303"/>
</svg>
`,
  clothe_57: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E3E3E3"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E3E3E3"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#E3E3E3"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#E3E3E3"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#E3E3E3"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#E3E3E3"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#E3E3E3"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#E3E3E3"/>
</svg>
`,
  clothe_58: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C9E6FF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C9E6FF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C9E6FF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#C9E6FF"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#C9E6FF"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#C9E6FF"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#C9E6FF"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#C9E6FF"/>
</svg>
`,
  clothe_59: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#0071CE"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#0071CE"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#0071CE"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#0071CE"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#0071CE"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#0071CE"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#0071CE"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#0071CE"/>
</svg>
`,
  clothe_60: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#0B6614"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#0B6614"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#0B6614"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#0B6614"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#0B6614"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#0B6614"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#0B6614"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#0B6614"/>
</svg>
`,
  clothe_61: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FFB176"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FFB176"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FFB176"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#FFB176"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#FFB176"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#FFB176"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#FFB176"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#FFB176"/>
</svg>
`,
  clothe_62: `<svg width="76" height="88" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#19436B"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#19436B"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#19436B"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#19436B"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#19436B"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#19436B"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#19436B"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#19436B"/>
</svg>
`,
};

export const topClothes = {
  clothe_1: `<svg xmlns="http://www.w3.org/2000/svg" width="161" height="205" viewBox="0 0 161 205" fill="none">
  <path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#F4F4F4"/>
  <path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#8FC5D8"/>
  <path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#8FC5D8"/>
  <path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#8FC5D8"/>
  <path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#8FC5D8"/>
  <path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#8FC5D8"/>
  <path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#8FC5D8"/>
  <path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#8FC5D8"/>
  <path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#8FC5D8"/>
  <path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#8FC5D8"/>
  <path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#8FC5D8"/>
  <path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#8FC5D8"/>
  <path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#8FC5D8"/>
  <path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#8FC5D8"/>
  <path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#8FC5D8"/>
</svg>`,
  clothe_2: `<svg xmlns="http://www.w3.org/2000/svg" width="163" height="206" viewBox="0 0 163 206" fill="none">
  <path d="M0.850586 204.651H17.0437C17.2172 203.667 26.3548 144.273 27.7235 132.938C28.9958 122.432 30.2296 94.3829 30.2103 78.2476C30.4609 85.708 30.9429 88.6768 31.714 104.041C32.2923 115.55 32.6586 155.705 31.8489 182.693C31.8489 182.693 45.883 206 81.5272 206C117.171 206 131.244 182.693 131.244 182.693C130.434 155.705 130.878 115.8 131.302 104.523C131.822 90.9708 131.752 85.9604 132.003 78.5C132.003 94.6353 134.097 122.412 135.369 132.938C136.757 144.273 145.471 203.648 145.644 204.651H162.3C161.394 177.199 161.221 145.218 158.348 121.641C156.806 108.995 155.784 60.146 156.035 50.8928C156.343 40.0202 154.242 27.7597 141.77 25.7163C133.326 24.3283 122.511 20.627 114.473 18.1016C101.248 13.957 101.267 0 101.267 0C101.267 0 87.7538 6.47726 82.1633 8.55923C81.5272 9.50383 81.4886 22.0343 81.4886 22.0343C81.4886 22.0343 81.2958 9.2725 80.8139 8.55923C75.0114 5.97604 62.0183 0 62.0183 0C62.0183 0 61.8448 13.9377 48.6204 18.1016C40.5816 20.627 29.7669 24.3283 21.3234 25.7163C8.87005 27.7597 6.7688 40.0202 7.05796 50.8928C7.30857 60.146 6.28686 109.015 4.74465 121.641C1.8723 145.237 1.75663 177.219 0.850586 204.651Z" fill="#FFDEC0"/>
  <path d="M80.2166 14.8625L78.4238 11.489L77.0551 15.1324C77.0551 15.1324 77.2478 15.9035 78.6358 17.118C78.9443 15.3252 80.2166 14.8625 80.2166 14.8625Z" fill="#BF062E"/>
  <path d="M82.5299 14.8625L83.9564 11.489L85.3444 13.7637C85.3444 13.7637 85.595 14.9396 84.7661 15.383C84.4576 14.8625 82.5299 14.8625 82.5299 14.8625Z" fill="#BF062E"/>
  <path d="M91.4361 176.814C91.3012 171.243 88.6023 79.7132 87.3107 41.1967C86.9252 29.8807 84.1106 26.3722 84.2263 24.0396C84.554 17.6202 77.4213 19.3359 78.3466 24.9071C78.6744 26.912 74.414 32.2519 73.7008 41.2738C70.6935 79.6361 70.1537 171.243 70.0187 176.814C69.9995 177.817 80.6792 190.501 80.6792 190.501C80.6792 190.501 91.4554 177.836 91.4361 176.814Z" fill="#D60B2D"/>
  <path d="M91.4361 176.814C91.3012 171.243 88.6023 79.7132 87.3107 41.1967C86.9252 29.8807 84.1106 26.3722 84.2263 24.0396C84.554 17.6202 77.4213 19.3359 78.3466 24.9071C78.6744 26.912 74.414 32.2519 73.7008 41.2738C70.6935 79.6361 70.1537 171.243 70.0187 176.814C69.9995 177.817 80.6792 190.501 80.6792 190.501C80.6792 190.501 91.4554 177.836 91.4361 176.814Z" fill="#D60B2D"/>
  <path d="M85.9998 14.7668C85.8456 13.3402 83.8022 13.8029 81.1226 13.8029C78.443 13.8029 76.9972 13.4366 76.843 14.8631C76.6888 16.2897 75.6863 18.1403 75.7634 19.0078C75.802 19.509 76.496 26.5453 77.9033 26.0827C81.1226 25.0031 84.8046 25.5815 85.0745 25.0995C85.7492 23.9043 87.0408 19.8175 87.1565 19.3163C87.3685 18.3717 86.1733 16.1933 86.0191 14.7668H85.9998Z" fill="#EA1840"/>
  <path d="M88.8912 25.5239C88.7755 25.5239 88.6598 25.5046 88.5634 25.4468C88.3707 25.3504 88.2164 25.1769 88.1586 24.9648C86.5586 19.355 83.8404 11.0271 82.7416 10.0633C82.4139 9.77409 82.3946 9.29215 82.6838 8.96444C82.9729 8.65599 83.4549 8.61743 83.7826 8.9066C85.4405 10.3717 88.3514 20.049 89.3924 23.6347C91.0888 22.6322 95.5033 19.5864 102.424 11.7404C102.713 11.4127 103.195 11.3934 103.504 11.6633C103.831 11.9525 103.851 12.4344 103.581 12.7428C94.3082 23.2684 89.4116 25.3696 89.1996 25.466C89.1032 25.5046 89.0068 25.5239 88.9104 25.5239H88.8912Z" fill="#EFB88B"/>
  <path d="M74.0089 25.5239C73.9125 25.5239 73.8161 25.5046 73.7197 25.466C73.5077 25.3889 68.6304 23.2684 59.3387 12.7428C59.0495 12.4151 59.0881 11.9332 59.4158 11.6633C59.7435 11.3741 60.2254 11.4127 60.4953 11.7404C67.416 19.5864 71.8305 22.6322 73.5269 23.6347C74.5679 20.0683 77.4981 10.3717 79.1367 8.9066C79.4644 8.61743 79.9464 8.65599 80.2355 8.96444C80.5247 9.29215 80.4861 9.77409 80.1777 10.0633C79.0789 11.0271 76.3607 19.355 74.7607 24.9648C74.7029 25.1769 74.5487 25.3504 74.3559 25.4468C74.2595 25.5046 74.1438 25.5239 74.0282 25.5239H74.0089Z" fill="#EFB88B"/>
  <path d="M11.3761 183.889C9.04352 183.889 6.36394 183.735 3.89641 183.272C3.47231 183.195 3.20242 182.79 3.27953 182.366C3.35664 181.942 3.76147 181.672 4.18557 181.749C10.1616 182.906 17.6413 182 17.7184 182C18.1425 181.942 18.5281 182.251 18.5859 182.675C18.6437 183.099 18.3353 183.484 17.9112 183.542C17.7184 183.561 14.9232 183.908 11.3568 183.908L11.3761 183.889Z" fill="#EFB88B"/>
  <path d="M151.755 183.889C148.208 183.889 145.394 183.542 145.201 183.522C144.777 183.464 144.488 183.079 144.526 182.655C144.584 182.231 144.97 181.941 145.394 181.98C145.471 181.98 152.97 182.886 158.927 181.729C159.351 181.652 159.755 181.922 159.833 182.346C159.91 182.77 159.64 183.175 159.216 183.252C156.748 183.734 154.088 183.869 151.736 183.869L151.755 183.889Z" fill="#EFB88B"/>
</svg>`,
  clothe_3: `<svg xmlns="http://www.w3.org/2000/svg" width="153" height="205" viewBox="0 0 153 205" fill="none">
  <path d="M24.7988 86.0321C25.0691 89.8877 25.4167 94.6065 25.8609 103.526C26.4402 114.978 26.363 155.03 25.5326 181.885C25.5326 181.885 40.0355 205 75.7422 205C111.449 205 126.01 181.502 126.01 181.502C125.199 154.647 125.179 115.247 125.604 104.025C126.126 90.5399 126.937 85.3032 127.188 77.8797C127.188 80.335 127.265 86.0321 127.265 86.0321L152.003 80.1048C152.003 80.1048 150.284 54.4007 150.4 50.641C150.709 39.8222 148.604 27.6223 136.11 25.589C127.651 24.2079 116.818 20.5249 108.765 18.0121C95.5171 13.8879 95.5364 0 95.5364 0C95.5364 0 81.9991 6.44521 76.3988 8.51689C75.7615 9.45682 75.7229 21.9252 75.7229 21.9252C75.7229 21.9252 75.5298 9.22663 75.047 8.51689C69.2343 5.94648 56.2184 0 56.2184 0C56.2184 0 56.0446 13.8687 42.797 18.0121C34.7441 20.5249 23.9104 24.2079 15.452 25.589C5.39081 27.2195 2.06925 35.4487 1.3161 44.2533C1.1423 46.3825 0.00292969 80.124 0.00292969 80.124C0.00292969 80.124 12.0726 84.9579 24.7988 86.0705V86.0321Z" fill="#CFE9FF"/>
  <path d="M74.5256 14.7895L72.7297 11.4326L71.3585 15.058C71.3585 15.058 71.5517 15.8253 72.9421 17.0338C73.2511 15.2498 74.5256 14.7895 74.5256 14.7895Z" fill="#353535"/>
  <path d="M76.8237 14.7895L78.2527 11.4326L79.6431 13.6961C79.6431 13.6961 79.8942 14.8662 79.0638 15.3074C78.7548 14.7895 76.8237 14.7895 76.8237 14.7895Z" fill="#353535"/>
  <path d="M85.7455 175.958C85.6104 170.415 82.9067 79.3375 81.6129 41.0115C81.2267 29.7516 78.4072 26.2604 78.5231 23.9394C78.8514 17.5517 71.7061 19.2589 72.6331 24.8026C72.9614 26.7975 68.6936 32.111 67.979 41.0882C64.9665 79.2608 64.4257 170.415 64.2906 175.958C64.2713 176.956 74.9698 189.578 74.9698 189.578C74.9698 189.578 85.7648 176.975 85.7455 175.958Z" fill="#353535"/>
  <path d="M85.7455 175.958C85.6104 170.415 82.9067 79.3375 81.6129 41.0115C81.2267 29.7516 78.4072 26.2604 78.5231 23.9394C78.8514 17.5517 71.7061 19.2589 72.6331 24.8026C72.9614 26.7975 68.6936 32.111 67.979 41.0882C64.9665 79.2608 64.4257 170.415 64.2906 175.958C64.2713 176.956 74.9698 189.578 74.9698 189.578C74.9698 189.578 85.7648 176.975 85.7455 175.958Z" fill="black"/>
  <path d="M80.319 14.6936C80.1645 13.2741 78.1175 13.7344 75.4333 13.7344C72.749 13.7344 71.3006 13.37 71.1461 14.7895C70.9916 16.2089 69.9874 18.0504 70.0647 18.9136C70.1033 19.4124 70.7985 26.4139 72.2082 25.9535C75.4333 24.8793 79.1217 25.4548 79.3921 24.9752C80.068 23.7859 81.3618 19.7193 81.4777 19.2205C81.6901 18.2806 80.4928 16.113 80.3383 14.6936H80.319Z" fill="black"/>
  <path d="M83.1385 25.4164C83.0226 25.4164 82.9068 25.3972 82.8102 25.3397C82.6171 25.2438 82.4626 25.0711 82.4046 24.8601C80.8018 19.2781 78.0789 10.9914 76.9781 10.0323C76.6499 9.74455 76.6305 9.26499 76.9202 8.9389C77.2099 8.63198 77.6927 8.59362 78.021 8.88135C79.6818 10.3392 82.5978 19.9687 83.6406 23.5365C85.34 22.5391 89.7623 19.5083 96.6951 11.7011C96.9848 11.375 97.4675 11.3559 97.7765 11.6244C98.1048 11.9121 98.1241 12.3917 97.8538 12.6986C88.565 23.1721 83.6599 25.2629 83.4475 25.3588C83.3509 25.3972 83.2544 25.4164 83.1578 25.4164H83.1385Z" fill="#83BDE5"/>
  <path d="M68.2108 25.4164C68.1142 25.4164 68.0177 25.3972 67.9211 25.3588C67.7087 25.2821 62.8229 23.1721 53.5148 12.6986C53.2251 12.3725 53.2638 11.893 53.5921 11.6244C53.9204 11.3367 54.4031 11.375 54.6735 11.7011C61.6063 19.5083 66.0286 22.5391 67.728 23.5365C68.7708 19.9878 71.7061 10.3392 73.3476 8.88135C73.6759 8.59362 74.1587 8.63198 74.4484 8.9389C74.738 9.26499 74.6994 9.74455 74.3904 10.0323C73.2897 10.9914 70.5668 19.2781 68.9639 24.8601C68.906 25.0711 68.7515 25.2438 68.5584 25.3397C68.4618 25.3972 68.346 25.4164 68.2301 25.4164H68.2108Z" fill="#83BDE5"/>
  <path d="M24.4125 73.8514L23.5242 82.3683L0.118798 76.9014C0.0608639 78.8771 0.00292969 80.1048 0.00292969 80.1048C0.00292969 80.1048 12.1305 84.9003 24.7988 86.0513L24.4125 73.8706V73.8514Z" fill="#83BDE5"/>
  <path d="M127.226 74.0816L128.501 82.3875L151.906 76.9014C151.964 78.8771 152.022 80.1048 152.022 80.1048C152.022 80.1048 139.895 84.9003 127.226 86.0513V74.0816Z" fill="#83BDE5"/>
</svg>`,
  clothe_4: `<svg xmlns="http://www.w3.org/2000/svg" width="167" height="229" viewBox="0 0 167 229" fill="none">
  <path d="M2.78929 203.638H18.8822C19.0546 202.66 28.1356 143.559 29.4959 132.28C30.7603 121.826 31.9864 93.916 31.9673 77.8605C32.2163 85.284 32.6953 88.238 33.4616 103.526C34.0364 114.978 34.4004 154.934 33.5957 181.79C33.5957 181.79 47.5429 204.981 82.9665 204.981C118.39 204.981 132.376 181.79 132.376 181.79C131.571 154.934 132.012 115.227 132.433 104.006C132.95 90.5207 133.755 85.284 134.004 77.8605C134.004 93.916 135.211 121.807 136.475 132.28C137.855 143.559 146.514 202.641 146.687 203.638H163.239C162.339 176.323 162.167 144.499 159.312 121.04C157.779 108.456 156.764 59.8484 157.013 50.641C157.32 39.8222 155.231 27.6223 142.836 25.589C134.445 24.2079 123.697 20.5249 115.708 18.0121C102.565 13.8879 102.585 0 102.585 0C102.585 0 89.1546 6.44521 83.5987 8.51689C82.9665 9.45682 82.9282 21.9252 82.9282 21.9252C82.9282 21.9252 82.7366 9.22663 82.2576 8.51689C76.491 5.94648 63.5784 0 63.5784 0C63.5784 0 63.4059 13.8687 50.2634 18.0121C42.2744 20.5249 31.5266 24.2079 23.1353 25.589C10.7591 27.6223 8.67087 39.8222 8.95824 50.641C9.2073 59.8484 8.19191 108.475 6.65926 121.04C3.80468 144.519 3.68973 176.342 2.78929 203.638Z" fill="#F4F4F4"/>
  <path d="M90.2849 25.4164C90.17 25.4164 90.055 25.3972 89.9592 25.3397C89.7676 25.2438 89.6144 25.0711 89.5569 24.8601C87.9668 19.2781 85.2655 10.9914 84.1735 10.0323C83.8478 9.74455 83.8286 9.26499 84.116 8.9389C84.4034 8.63198 84.8823 8.59362 85.208 8.88135C86.8556 10.3392 89.7485 19.9687 90.783 23.5365C92.469 22.5391 96.8562 19.5083 103.734 11.7011C104.021 11.375 104.5 11.3559 104.807 11.6244C105.133 11.9121 105.152 12.3917 104.883 12.6986C95.6684 23.1721 90.8022 25.2629 90.5915 25.3588C90.4957 25.3972 90.3999 25.4164 90.3041 25.4164H90.2849Z" fill="#8FC5D8"/>
  <path d="M75.4756 25.4164C75.3798 25.4164 75.284 25.3972 75.1882 25.3588C74.9775 25.2821 70.1305 23.1721 60.8962 12.6986C60.6088 12.3725 60.6471 11.893 60.9728 11.6244C61.2985 11.3367 61.7775 11.375 62.0457 11.7011C68.9235 19.5083 73.3107 22.5391 74.9967 23.5365C76.0312 19.9878 78.9433 10.3392 80.5717 8.88135C80.8974 8.59362 81.3764 8.63198 81.6637 8.9389C81.9511 9.26499 81.9128 9.74455 81.6063 10.0323C80.5142 10.9914 77.8129 19.2781 76.2228 24.8601C76.1653 25.0711 76.012 25.2438 75.8205 25.3397C75.7247 25.3972 75.6097 25.4164 75.4948 25.4164H75.4756Z" fill="#8FC5D8"/>
  <path d="M13.2497 182.998C10.9315 182.998 8.26855 182.845 5.81629 182.384C5.39481 182.307 5.1266 181.905 5.20323 181.483C5.27986 181.061 5.68219 180.792 6.10367 180.869C12.0427 182.02 19.4761 181.118 19.5527 181.118C19.9742 181.061 20.3574 181.368 20.4149 181.79C20.4723 182.212 20.1658 182.595 19.7443 182.653C19.5527 182.672 16.7748 183.017 13.2305 183.017L13.2497 182.998Z" fill="#8FC5D8"/>
  <path d="M152.741 182.998C149.216 182.998 146.419 182.653 146.227 182.634C145.805 182.576 145.518 182.192 145.556 181.77C145.614 181.348 145.997 181.061 146.419 181.099C146.495 181.099 153.948 182.001 159.868 180.85C160.289 180.773 160.691 181.041 160.768 181.463C160.845 181.885 160.576 182.288 160.155 182.365C157.703 182.845 155.059 182.979 152.722 182.979L152.741 182.998Z" fill="#8FC5D8"/>
  <path d="M82.3726 202.641C82.0469 202.641 81.7595 202.295 81.7595 201.854V18.319C81.7595 17.8778 82.0277 17.5325 82.3726 17.5325C82.7174 17.5325 82.9856 17.8778 82.9856 18.319V201.854C82.9856 202.295 82.7174 202.641 82.3726 202.641Z" fill="#8FC5D8"/>
  <path d="M87.2005 188.235C87.2005 189.06 86.5299 189.731 85.7061 189.731C84.8823 189.731 84.2118 189.06 84.2118 188.235C84.2118 187.41 84.8823 186.739 85.7061 186.739C86.5299 186.739 87.2005 187.41 87.2005 188.235Z" fill="#8FC5D8"/>
  <path d="M87.2005 165.581C87.2005 166.405 86.5299 167.077 85.7061 167.077C84.8823 167.077 84.2118 166.405 84.2118 165.581C84.2118 164.756 84.8823 164.084 85.7061 164.084C86.5299 164.084 87.2005 164.756 87.2005 165.581Z" fill="#8FC5D8"/>
  <path d="M87.2005 142.926C87.2005 143.751 86.5299 144.423 85.7061 144.423C84.8823 144.423 84.2118 143.751 84.2118 142.926C84.2118 142.102 84.8823 141.43 85.7061 141.43C86.5299 141.43 87.2005 142.102 87.2005 142.926Z" fill="#8FC5D8"/>
  <path d="M87.2005 120.253C87.2005 121.078 86.5299 121.749 85.7061 121.749C84.8823 121.749 84.2118 121.078 84.2118 120.253C84.2118 119.428 84.8823 118.757 85.7061 118.757C86.5299 118.757 87.2005 119.428 87.2005 120.253Z" fill="#8FC5D8"/>
  <path d="M87.2005 97.599C87.2005 98.4238 86.5299 99.0952 85.7061 99.0952C84.8823 99.0952 84.2118 98.4238 84.2118 97.599C84.2118 96.7741 84.8823 96.1027 85.7061 96.1027C86.5299 96.1027 87.2005 96.7741 87.2005 97.599Z" fill="#8FC5D8"/>
  <path d="M87.2005 74.9448C87.2005 75.7696 86.5299 76.441 85.7061 76.441C84.8823 76.441 84.2118 75.7696 84.2118 74.9448C84.2118 74.12 84.8823 73.4486 85.7061 73.4486C86.5299 73.4486 87.2005 74.12 87.2005 74.9448Z" fill="#8FC5D8"/>
  <path d="M87.2005 52.2906C87.2005 53.1155 86.5299 53.7868 85.7061 53.7868C84.8823 53.7868 84.2118 53.1155 84.2118 52.2906C84.2118 51.4658 84.8823 50.7944 85.7061 50.7944C86.5299 50.7944 87.2005 51.4658 87.2005 52.2906Z" fill="#8FC5D8"/>
  <path d="M87.2005 29.6173C87.2005 30.4421 86.5299 31.1135 85.7061 31.1135C84.8823 31.1135 84.2118 30.4421 84.2118 29.6173C84.2118 28.7925 84.8823 28.1211 85.7061 28.1211C86.5299 28.1211 87.2005 28.7925 87.2005 29.6173Z" fill="#8FC5D8"/>
  <path d="M87.2005 19.7768C87.2005 20.6017 86.5299 21.273 85.7061 21.273C84.8823 21.273 84.2118 20.6017 84.2118 19.7768C84.2118 18.952 84.8823 18.2806 85.7061 18.2806C86.5299 18.2806 87.2005 18.952 87.2005 19.7768Z" fill="#8FC5D8"/>
  <path d="M81.7212 13.1014C81.453 13.159 69.4599 10.2625 66.567 9.01563C66.0498 8.8238 66.3946 12.7753 66.4712 14.1565C66.5287 15.1539 66.5479 16.9954 66.3946 17.9737C66.0498 20.1029 66.4904 25.0519 66.4904 25.0519C71.529 23.2104 76.5868 21.6951 81.6446 20.2564C81.6829 17.8778 81.702 15.48 81.7404 13.1014H81.7212Z" fill="#2B2B2B"/>
  <path d="M84.5566 20.3715C84.8248 20.3139 96.8179 23.4598 99.7108 24.6875C100.228 24.8793 99.6916 20.5441 99.6341 19.5466C99.5575 18.0504 99.9598 16.4583 99.8832 15.4417C99.7108 12.6411 99.7874 9.36091 99.7874 9.36091C94.7488 10.8571 89.691 12.0656 84.6332 13.1973C84.5949 15.5759 84.5758 17.9737 84.5375 20.3523L84.5566 20.3715Z" fill="#2B2B2B"/>
  <path d="M74.2112 19.0863C73.9813 19.0863 73.7705 18.8753 73.7131 18.5492C73.6556 18.2039 73.8472 17.8586 74.1154 17.8011C76.4144 17.264 78.7325 16.746 81.0315 16.2281C78.0045 15.4992 73.0234 14.4442 70.1113 13.7728C69.8431 13.7153 69.6515 13.37 69.6898 13.0055C69.7281 12.6411 69.9964 12.3917 70.2646 12.4684C70.7435 12.6602 82.1044 15.0197 83.752 15.691C83.9627 15.7869 84.0968 16.0555 84.0968 16.3432C84.0968 16.631 83.9052 16.8611 83.6753 16.9187C80.5525 17.6284 77.4106 18.319 74.2878 19.0671C74.2495 19.0671 74.2303 19.0671 74.192 19.0671L74.2112 19.0863Z" fill="black"/>
  <path d="M92.6222 19.0863C92.8521 19.0863 93.0629 18.8753 93.1203 18.5492C93.1778 18.2039 92.9862 17.8586 92.718 17.8011C90.419 17.264 88.1009 16.746 85.8019 16.2281C88.8289 15.4992 93.81 14.4442 96.7221 13.7728C96.9903 13.7153 97.1819 13.37 97.1436 13.0055C97.1053 12.6411 96.837 12.3917 96.5688 12.4684C96.0899 12.6602 84.729 15.0197 83.0623 15.691C82.8515 15.7869 82.7174 16.0555 82.7174 16.3432C82.7174 16.631 82.909 16.8611 83.1389 16.9187C86.2617 17.6284 89.4036 18.319 92.5264 19.0671C92.5648 19.0671 92.5839 19.0671 92.6222 19.0671V19.0863Z" fill="black"/>
  <path d="M79.882 16.4967C79.882 18.6451 79.6713 20.8127 83.1006 20.8319C85.936 20.851 86.415 19.1438 86.415 16.4775C86.415 14.4634 86.5491 12.1999 83.2539 12.2191C79.7287 12.2191 79.9012 13.6385 79.9012 16.4967H79.882Z" fill="black"/>
  <path d="M159.619 53.4276C160.05 37.6936 155.008 28.8628 144.628 27.1714C137.466 25.9914 128.362 23.0806 121.063 20.7401C119.631 20.2878 118.257 19.8354 116.943 19.4224C109.506 17.082 107.583 12.7944 107.583 12.7944C107.583 12.7944 110.84 84.5811 82.6251 121.281C52.4868 58.8559 59.3543 11.9684 59.3543 11.9684C59.3543 11.9684 57.0782 17.082 49.6417 19.4224C48.3271 19.8354 46.9536 20.2681 45.5213 20.7401C38.2222 23.0806 29.1179 25.9914 21.9562 27.1714C11.5765 28.8628 6.53387 37.7133 6.96554 53.4276C7.24024 63.0844 0 206.441 0 206.441H19.6212C19.6212 206.441 31.394 129.01 31.5706 106.746C31.7864 113.591 33.2188 154.46 32.3358 182.329C31.7275 201.564 31.8453 213.502 32.2966 224.221C44.2851 229.374 76.1304 229 76.1304 229L83.3118 221.861L90.4932 229C90.4932 229 122.338 229.374 134.327 224.221C134.759 213.482 134.876 201.564 134.288 182.329C133.424 154.46 135.328 112.686 135.701 106.746C136.878 150.979 147.002 206.441 147.002 206.441H166.624C166.624 206.441 159.383 63.0844 159.658 53.4276H159.619Z" fill="black"/>
  <path d="M91.7293 179.457C91.7293 180.696 90.6894 181.719 89.414 181.719C88.1386 181.719 87.0987 180.716 87.0987 179.457C87.0987 178.199 88.1386 177.196 89.414 177.196C90.6894 177.196 91.7293 178.199 91.7293 179.457Z" fill="#353535"/>
  <path d="M91.7293 158.039C91.7293 159.278 90.6894 160.301 89.414 160.301C88.1386 160.301 87.0987 159.298 87.0987 158.039C87.0987 156.781 88.1386 155.778 89.414 155.778C90.6894 155.778 91.7293 156.781 91.7293 158.039Z" fill="#353535"/>
  <path d="M91.7293 136.641C91.7293 137.88 90.6894 138.903 89.414 138.903C88.1386 138.903 87.0987 137.9 87.0987 136.641C87.0987 135.382 88.1386 134.379 89.414 134.379C90.6894 134.379 91.7293 135.382 91.7293 136.641Z" fill="#353535"/>
  <path d="M115.314 183.45C114.961 183.45 114.647 183.214 114.568 182.86C114.549 182.762 114.039 180.598 113.372 174.383C113.352 174.187 113.411 173.99 113.548 173.832C113.685 173.675 113.862 173.577 114.078 173.557C119.179 173.065 129.853 170.961 129.971 170.941C130.403 170.863 130.795 171.118 130.893 171.531C130.972 171.944 130.717 172.338 130.285 172.436C129.873 172.515 120.396 174.403 115.02 174.993C115.628 180.519 116.099 182.506 116.099 182.526C116.197 182.939 115.942 183.332 115.51 183.43C115.451 183.43 115.393 183.43 115.334 183.43L115.314 183.45Z" fill="#353535"/>
  <path d="M49.9753 183.45C50.3285 183.45 50.6424 183.214 50.7209 182.86C50.7405 182.762 51.2507 180.598 51.9178 174.383C51.9374 174.187 51.8786 173.99 51.7412 173.832C51.6039 173.675 51.4273 173.577 51.2114 173.557C46.1099 173.065 35.436 170.961 35.3182 170.941C34.8866 170.863 34.4941 171.118 34.396 171.531C34.3175 171.944 34.5726 172.338 35.0043 172.436C35.4163 172.515 44.8934 174.403 50.2696 174.993C49.6614 180.519 49.1904 182.506 49.1904 182.526C49.0923 182.939 49.3474 183.332 49.7791 183.43C49.838 183.43 49.8968 183.43 49.9557 183.43L49.9753 183.45Z" fill="#353535"/>
  <path d="M125.066 90.8157C124.634 90.8157 124.281 90.4813 124.281 90.0486V87.2362C124.281 87.2362 124.202 87.0592 124.104 87.0592H111.606C111.174 87.0592 110.821 86.7248 110.821 86.2921C110.821 85.8595 111.174 85.5251 111.606 85.5251H124.104C125.046 85.5251 125.831 86.2921 125.831 87.2165V90.029C125.831 90.442 125.478 90.796 125.046 90.796L125.066 90.8157Z" fill="#353535"/>
  <path d="M85.1954 217.967C84.9011 217.967 84.6264 217.809 84.4891 217.514C82.782 213.954 82.7624 205.773 82.7624 205.419V121.812C82.7624 121.379 83.1156 121.025 83.5472 121.025C83.9789 121.025 84.3321 121.379 84.3321 121.812V205.438C84.3321 205.438 84.3321 213.6 85.9018 216.826C86.098 217.219 85.941 217.691 85.5486 217.888C85.4309 217.947 85.3132 217.967 85.215 217.967H85.1954Z" fill="#353535"/>
  <path d="M51.9178 54.7257C49.6025 39.7783 52.2318 18.6357 52.2318 18.6357L60.4727 9.41163C60.4727 9.41163 55.2534 60.7636 84.0378 121.595C83.9004 123.267 83.0763 122.362 82.7624 121.969C58.5694 92.0547 53.9977 68.2176 51.9178 54.7257Z" fill="#353535"/>
  <path d="M115.412 53.1326C118.277 38.8933 114.882 18.616 114.882 18.616L105.896 9.82465C105.896 9.82465 111.841 60.744 83.0567 121.576C81.9972 123.857 84.0182 122.343 84.3321 121.949C108.525 92.0351 112.547 67.3719 115.412 53.1326Z" fill="#353535"/>
</svg>`,
  clothe_5: `<svg xmlns="http://www.w3.org/2000/svg" width="166" height="225" viewBox="0 0 166 225" fill="none">
  <path d="M31.796 86.0321C32.0664 89.8877 32.414 94.6065 32.8581 103.526C33.4375 114.978 33.3602 155.03 32.5298 181.885C32.5298 181.885 47.0327 205 82.7395 205C118.446 205 133.007 181.502 133.007 181.502C132.196 154.647 132.177 115.247 132.602 104.025C133.123 90.5399 133.934 85.3032 134.185 77.8797C134.185 80.335 134.262 86.0321 134.262 86.0321L159 80.1048C159 80.1048 157.281 54.4007 157.397 50.641C157.706 39.8222 155.601 27.6223 143.107 25.589C134.649 24.2079 123.815 20.5249 115.762 18.0121C102.514 13.8879 102.534 0 102.534 0C102.534 0 88.9964 6.44521 83.3961 8.51689C82.7588 9.45682 82.7202 21.9252 82.7202 21.9252C82.7202 21.9252 82.5271 9.22663 82.0443 8.51689C76.2315 5.94648 63.2157 0 63.2157 0C63.2157 0 63.0419 13.8687 49.7942 18.0121C41.7414 20.5249 30.9077 24.2079 22.4493 25.589C12.3881 27.2195 9.0665 35.4487 8.31335 44.2533C8.13955 46.3825 7.00018 80.124 7.00018 80.124C7.00018 80.124 19.0698 84.9579 31.796 86.0705V86.0321Z" fill="#CFE9FF"/>
  <path d="M81.5233 14.7895L79.7274 11.4326L78.3563 15.0581C78.3563 15.0581 78.5494 15.8253 79.9398 17.0338C80.2488 15.2499 81.5233 14.7895 81.5233 14.7895Z" fill="#353535"/>
  <path d="M83.8214 14.7895L85.2504 11.4326L86.6409 13.6961C86.6409 13.6961 86.8919 14.8662 86.0615 15.3074C85.7525 14.7895 83.8214 14.7895 83.8214 14.7895Z" fill="#353535"/>
  <path d="M92.7432 175.958C92.6081 170.415 89.9045 79.3375 88.6106 41.0115C88.2244 29.7516 85.4049 26.2604 85.5208 23.9394C85.8491 17.5517 78.7039 19.2589 79.6308 24.8026C79.9591 26.7975 75.6913 32.111 74.9768 41.0883C71.9642 79.2608 71.4235 170.415 71.2883 175.958C71.269 176.956 81.9675 189.578 81.9675 189.578C81.9675 189.578 92.7626 176.975 92.7432 175.958Z" fill="#353535"/>
  <path d="M92.7432 175.958C92.6081 170.415 89.9045 79.3375 88.6106 41.0115C88.2244 29.7516 85.4049 26.2604 85.5208 23.9394C85.8491 17.5517 78.7039 19.2589 79.6308 24.8026C79.9591 26.7975 75.6913 32.111 74.9768 41.0883C71.9642 79.2608 71.4235 170.415 71.2883 175.958C71.269 176.956 81.9675 189.578 81.9675 189.578C81.9675 189.578 92.7626 176.975 92.7432 175.958Z" fill="black"/>
  <path d="M87.3167 14.6936C87.1623 13.2741 85.1152 13.7345 82.431 13.7345C79.7467 13.7345 78.2983 13.37 78.1438 14.7895C77.9893 16.209 76.9852 18.0505 77.0624 18.9137C77.101 19.4124 77.7962 26.4139 79.206 25.9535C82.431 24.8793 86.1194 25.4548 86.3898 24.9752C87.0657 23.7859 88.3596 19.7193 88.4754 19.2206C88.6879 18.2807 87.4905 16.1131 87.3361 14.6936H87.3167Z" fill="black"/>
  <path d="M90.1357 25.4163C90.0199 25.4163 89.904 25.3971 89.8074 25.3395C89.6143 25.2436 89.4598 25.071 89.4019 24.86C87.7991 19.278 85.0761 10.9913 83.9754 10.0321C83.6471 9.74441 83.6278 9.26486 83.9175 8.93876C84.2071 8.63184 84.6899 8.59348 85.0182 8.88121C86.679 10.3391 89.595 19.9685 90.6378 23.5364C92.3372 22.5389 96.7595 19.5081 103.692 11.701C103.982 11.3749 104.465 11.3557 104.774 11.6243C105.102 11.912 105.121 12.3916 104.851 12.6985C95.5622 23.1719 90.6571 25.2628 90.4447 25.3587C90.3482 25.3971 90.2516 25.4163 90.1551 25.4163H90.1357Z" fill="#83BDE5"/>
  <path d="M75.208 25.4163C75.1115 25.4163 75.0149 25.3971 74.9184 25.3587C74.7059 25.282 69.8202 23.1719 60.5121 12.6985C60.2224 12.3724 60.261 11.8928 60.5893 11.6243C60.9176 11.3365 61.4004 11.3749 61.6707 11.701C68.6035 19.5081 73.0258 22.5389 74.7253 23.5364C75.7681 19.9877 78.7034 10.3391 80.3449 8.88121C80.6732 8.59348 81.1559 8.63184 81.4456 8.93876C81.7353 9.26486 81.6967 9.74441 81.3877 10.0321C80.2869 10.9913 77.564 19.278 75.9612 24.86C75.9032 25.071 75.7488 25.2436 75.5556 25.3395C75.4591 25.3971 75.3432 25.4163 75.2273 25.4163H75.208Z" fill="#83BDE5"/>
  <path d="M31.4098 73.8513L30.5215 82.3681L7.11605 76.9012C7.05811 78.877 7.00018 80.1047 7.00018 80.1047C7.00018 80.1047 19.1277 84.9002 31.796 86.0511L31.4098 73.8704V73.8513Z" fill="#83BDE5"/>
  <path d="M134.224 74.0815L135.498 82.3873L158.904 76.9012C158.962 78.877 159.019 80.1047 159.019 80.1047C159.019 80.1047 146.892 84.9002 134.224 86.0511V74.0815Z" fill="#83BDE5"/>
  <path d="M158.188 51.7582C158.613 36.2331 153.645 27.5195 143.421 25.8506C136.366 24.6862 127.397 21.814 120.207 19.5047C118.796 19.0583 117.443 18.612 116.148 18.2044C108.823 15.895 106.929 11.6644 106.929 11.6644C106.929 11.6644 110.137 82.4981 82.343 118.711C52.6546 57.1144 59.4195 10.8494 59.4195 10.8494C59.4195 10.8494 57.1774 15.895 49.852 18.2044C48.557 18.612 47.204 19.0389 45.793 19.5047C38.6029 21.814 29.6345 24.6862 22.5796 25.8506C12.3549 27.5195 7.38752 36.2525 7.81275 51.7582C8.08334 61.2868 0.951172 202.741 0.951172 202.741H20.2796C20.2796 202.741 31.8766 126.337 32.0505 104.369C32.2632 111.123 33.6741 151.449 32.8044 178.948C32.2052 197.928 32.3211 209.708 32.7657 220.284C44.5753 225.369 75.9453 225 75.9453 225L83.0195 217.955L90.0937 225C90.0937 225 121.464 225.369 133.273 220.284C133.699 209.688 133.814 197.928 133.235 178.948C132.384 151.449 134.259 110.23 134.626 104.369C135.786 148.014 145.759 202.741 145.759 202.741H165.088C165.088 202.741 157.956 61.2868 158.226 51.7582H158.188Z" fill="#143351"/>
  <path d="M91.3114 176.115C91.3114 177.338 90.287 178.347 89.0306 178.347C87.7743 178.347 86.7499 177.357 86.7499 176.115C86.7499 174.873 87.7743 173.883 89.0306 173.883C90.287 173.883 91.3114 174.873 91.3114 176.115Z" fill="#0A2033"/>
  <path d="M91.3114 154.981C91.3114 156.204 90.287 157.213 89.0306 157.213C87.7743 157.213 86.7499 156.223 86.7499 154.981C86.7499 153.739 87.7743 152.75 89.0306 152.75C90.287 152.75 91.3114 153.739 91.3114 154.981Z" fill="#0A2033"/>
  <path d="M91.3114 133.867C91.3114 135.09 90.287 136.099 89.0306 136.099C87.7743 136.099 86.7499 135.109 86.7499 133.867C86.7499 132.625 87.7743 131.635 89.0306 131.635C90.287 131.635 91.3114 132.625 91.3114 133.867Z" fill="#0A2033"/>
  <path d="M114.544 180.055C114.196 180.055 113.887 179.822 113.81 179.472C113.79 179.375 113.288 177.241 112.631 171.108C112.611 170.914 112.669 170.72 112.805 170.565C112.94 170.41 113.114 170.313 113.326 170.293C118.352 169.808 128.866 167.731 128.982 167.712C129.408 167.634 129.794 167.887 129.891 168.294C129.968 168.702 129.717 169.09 129.292 169.187C128.886 169.265 119.55 171.128 114.254 171.71C114.853 177.163 115.317 179.123 115.317 179.142C115.414 179.55 115.163 179.938 114.737 180.035C114.679 180.035 114.621 180.035 114.563 180.035L114.544 180.055Z" fill="#0A2033"/>
  <path d="M50.1806 180.055C50.5285 180.055 50.8377 179.822 50.9151 179.472C50.9344 179.375 51.4369 177.241 52.0941 171.108C52.1134 170.914 52.0554 170.72 51.9201 170.565C51.7848 170.41 51.6109 170.313 51.3983 170.293C46.3729 169.808 35.8582 167.731 35.7423 167.712C35.317 167.634 34.9305 167.887 34.8338 168.294C34.7565 168.702 35.0078 169.09 35.433 169.187C35.8389 169.265 45.1745 171.128 50.4705 171.71C49.8713 177.163 49.4074 179.123 49.4074 179.142C49.3108 179.55 49.5621 179.938 49.9873 180.035C50.0453 180.035 50.1033 180.035 50.1612 180.035L50.1806 180.055Z" fill="#0A2033"/>
  <path d="M124.15 88.65C123.725 88.65 123.377 88.3201 123.377 87.8931V85.118C123.377 85.118 123.3 84.9433 123.203 84.9433H110.891C110.466 84.9433 110.118 84.6134 110.118 84.1865C110.118 83.7595 110.466 83.4296 110.891 83.4296H123.203C124.131 83.4296 124.904 84.1865 124.904 85.0986V87.8737C124.904 88.2813 124.556 88.6306 124.131 88.6306L124.15 88.65Z" fill="#0A2033"/>
  <path d="M84.875 214.113C84.5851 214.113 84.3145 213.958 84.1792 213.667C82.4976 210.154 82.4783 202.081 82.4783 201.732V119.235C82.4783 118.808 82.8262 118.458 83.2514 118.458C83.6767 118.458 84.0246 118.808 84.0246 119.235V201.751C84.0246 201.751 84.0246 209.805 85.5709 212.987C85.7641 213.376 85.6095 213.841 85.2229 214.035C85.107 214.094 84.991 214.113 84.8944 214.113H84.875Z" fill="#0A2033"/>
  <path d="M49.0209 51.6418L55.4379 49.3907L49.0209 46.3632L52.4033 17.4088L60.5213 8.30711C60.5213 8.30711 55.3799 58.9775 83.7346 119.002C83.5993 120.651 82.7876 119.759 82.4783 119.37C58.6464 89.8532 49.0209 51.6224 49.0209 51.6224V51.6418Z" fill="#295777"/>
  <path d="M117.482 51.6418L111.065 49.3907L117.482 46.3632L114.1 17.4088L105.247 8.73405C105.247 8.73405 111.104 58.9775 82.7489 119.002C81.7052 121.253 83.696 119.759 84.0052 119.37C107.837 89.8532 117.463 51.6224 117.463 51.6224L117.482 51.6418Z" fill="#295777"/>
</svg>`,
  clothe_6: `<svg xmlns="http://www.w3.org/2000/svg" width="168" height="182" viewBox="0 0 168 182" fill="none">
  <path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#2DA590"/>
  <path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#2DA590"/>
  <path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#30AA94"/>
  <path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#267A63"/>
  <path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#267A63"/>
  <path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#267A63"/>
</svg>`,
  clothe_7: `<svg xmlns="http://www.w3.org/2000/svg" width="168" height="182" viewBox="0 0 168 182" fill="none">
  <path d="M139.495 102.604C138.907 102.736 136.303 102.868 135.705 103L133.017 85.6152C132.985 79.6354 133.017 10.6103 133.017 5.00014C133.017 5.00014 154.468 8.12487 157.503 22.5001L168.003 92.8886C160.894 96.8091 147.234 100.862 139.485 102.604H139.495Z" fill="#FFF5DA"/>
  <path d="M28.5124 102.604C29.1002 102.736 31.7034 102.868 32.3017 103L34.9889 85.6152C35.0204 79.6354 34.9889 10.6103 34.9889 5.00014C34.9889 5.00014 15.5365 9.12487 12.5029 23.5001L0.00292969 92.8886C7.1093 96.8091 20.7657 100.862 28.5124 102.604Z" fill="#FFF5DA"/>
  <path d="M134.53 123.201C134.53 123.201 136.229 16.2774 135.165 6.00014L84.5029 32.9328L33.8412 6.00014C32.7766 16.2774 34.4757 123.201 34.4757 123.201C34.4757 127.458 31.5829 169.813 33.9058 175.648C33.9165 175.688 33.9273 175.728 33.9488 175.768C33.9703 175.807 33.9918 175.834 34.0133 175.874C34.0563 175.953 34.0993 176.019 34.1531 176.099C34.1961 176.152 34.2284 176.192 34.2714 176.218C37.261 179.374 56.6718 181.841 80.5993 182C80.7928 182 83.6856 182 84.5029 182C85.3202 182 88.213 182 88.4066 182C112.334 181.841 131.745 179.374 134.734 176.218C134.777 176.192 134.81 176.139 134.853 176.099C134.907 176.019 134.95 175.953 134.993 175.874C135.014 175.834 135.036 175.807 135.057 175.768C135.068 175.728 135.089 175.688 135.1 175.648C137.423 169.8 134.53 127.444 134.53 123.201Z" fill="#FEF8E9"/>
  <path d="M61.0029 0.266602V5.15391C61.0029 8.11789 72.9944 22.0115 85.0029 22C96.9944 22 109.003 8.10631 109.003 5.15391V0.266603L135.657 5.00507C136.012 8.42306 136.055 22.634 135.958 40H34.0477C33.9509 22.634 33.9939 8.42306 34.3487 5.00507L61.0029 0.266602Z" fill="#D01F3D"/>
  <path d="M135.013 99.2042L135.613 103C136.213 102.871 138.813 102.743 139.413 102.614C147.192 100.916 160.877 96.9652 168.003 93.1435L167.182 89.0002C153.087 95.82 139.982 98.4064 135.003 99.2042H135.013Z" fill="#D01F3D"/>
  <path d="M0.00292969 93.0348C7.12016 96.8987 20.7975 100.893 28.556 102.61C29.1447 102.74 31.7519 102.87 32.3511 103L33.0029 98.8501C26.8319 97.7183 14.2164 94.7651 0.991143 88.0002L0.00292969 93.0479V93.0348Z" fill="#D01F3D"/>
  <path d="M102.003 45H130.003V71.3623L116.003 79L102.003 71.7762V45Z" fill="#D01F3D"/>
</svg>`,
  clothe_8: `<svg xmlns="http://www.w3.org/2000/svg" width="155" height="145" viewBox="0 0 155 145" fill="none">
  <path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#3A3A3A"/>
  <path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#3A3A3A"/>
  <path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#1D1D1B"/>
  <path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#ECECEC"/>
  <path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#ECECEC"/>
  <path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#ECECEC"/>
  <path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#ECECEC"/>
  <path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#ECECEC"/>
</svg>`,
  clothe_9: `<svg xmlns="http://www.w3.org/2000/svg" width="155" height="145" viewBox="0 0 155 145" fill="none">
  <path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E8E8E8"/>
  <path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E8E8E8"/>
  <path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#F0EDED"/>
  <path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#0E0E0E"/>
  <path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#0E0E0E"/>
  <path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#0E0E0E"/>
  <path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#0E0E0E"/>
  <path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#0E0E0E"/>
</svg>`,
  clothe_10: `<svg xmlns="http://www.w3.org/2000/svg" width="161" height="205" viewBox="0 0 161 205" fill="none">
  <path d="M0.229004 203.651H16.3219C16.4943 202.672 25.5753 143.572 26.9356 132.293C28.2 121.839 29.4261 93.9287 29.407 77.8732C29.656 85.2967 30.135 88.2507 30.9013 103.539C31.4761 114.991 31.8401 154.947 31.0354 181.802C31.0354 181.802 44.9826 204.993 80.4062 204.993C115.83 204.993 129.815 181.802 129.815 181.802C129.011 154.947 129.451 115.24 129.873 104.018C130.39 90.5334 131.195 85.2967 131.444 77.8732C131.444 93.9287 132.651 121.82 133.915 132.293C135.295 143.572 143.954 202.653 144.126 203.651H160.679C159.779 176.335 159.606 144.512 156.752 121.052C155.219 108.469 154.204 59.8611 154.453 50.6537C154.759 39.8349 152.671 27.635 140.276 25.6017C131.884 24.2206 121.137 20.5376 113.148 18.0248C100.005 13.9006 100.024 0.0126953 100.024 0.0126953C100.024 0.0126953 86.5943 6.45791 81.0384 8.52958C80.4062 9.46951 80.3679 21.9379 80.3679 21.9379C80.3679 21.9379 80.1763 9.23933 79.6974 8.52958C73.9307 5.95917 61.0181 0.0126953 61.0181 0.0126953C61.0181 0.0126953 60.8457 13.8814 47.7031 18.0248C39.7141 20.5376 28.9664 24.2206 20.575 25.6017C8.19883 27.635 6.11058 39.8349 6.39795 50.6537C6.64701 59.8611 5.63162 108.488 4.09897 121.052C1.24439 144.531 1.12944 176.355 0.229004 203.651Z" fill="#252525"/>
  <path d="M87.7246 25.4291C87.6097 25.4291 87.4947 25.4099 87.3989 25.3524C87.2074 25.2564 87.0541 25.0838 86.9966 24.8728C85.4065 19.2908 82.7052 11.0041 81.6132 10.045C81.2875 9.75724 81.2683 9.27769 81.5557 8.95159C81.8431 8.64468 82.322 8.60631 82.6477 8.89405C84.2953 10.3519 87.1882 19.9813 88.2227 23.5492C89.9087 22.5518 94.2959 19.521 101.174 11.7138C101.461 11.3877 101.94 11.3685 102.247 11.6371C102.572 11.9248 102.591 12.4044 102.323 12.7113C93.1081 23.1848 88.2419 25.2756 88.0312 25.3715C87.9354 25.4099 87.8396 25.4291 87.7438 25.4291H87.7246Z" fill="#585B5C"/>
  <path d="M72.9153 25.4291C72.8195 25.4291 72.7237 25.4099 72.628 25.3715C72.4172 25.2948 67.5702 23.1848 58.3359 12.7113C58.0485 12.3852 58.0869 11.9056 58.4125 11.6371C58.7382 11.3494 59.2172 11.3877 59.4854 11.7138C66.3632 19.521 70.7505 22.5518 72.4364 23.5492C73.4709 20.0005 76.383 10.3519 78.0114 8.89405C78.3371 8.60631 78.8161 8.64468 79.1034 8.95159C79.3908 9.27769 79.3525 9.75724 79.046 10.045C77.9539 11.0041 75.2526 19.2908 73.6625 24.8728C73.605 25.0838 73.4518 25.2564 73.2602 25.3524C73.1644 25.4099 73.0494 25.4291 72.9345 25.4291H72.9153Z" fill="#585B5C"/>
  <path d="M10.6894 183.011C8.37125 183.011 5.70826 182.857 3.25601 182.397C2.83452 182.32 2.56631 181.917 2.64294 181.495C2.71957 181.073 3.1219 180.805 3.54338 180.882C9.48243 182.032 16.9158 181.131 16.9925 181.131C17.4139 181.073 17.7971 181.38 17.8546 181.802C17.912 182.224 17.6055 182.608 17.184 182.665C16.9925 182.685 14.2145 183.03 10.6702 183.03L10.6894 183.011Z" fill="#585B5C"/>
  <path d="M150.18 183.011C146.655 183.011 143.858 182.665 143.667 182.646C143.245 182.589 142.958 182.205 142.996 181.783C143.054 181.361 143.437 181.073 143.858 181.112C143.935 181.112 151.387 182.013 157.307 180.862C157.729 180.786 158.131 181.054 158.208 181.476C158.284 181.898 158.016 182.301 157.595 182.378C155.142 182.857 152.499 182.992 150.161 182.992L150.18 183.011Z" fill="#585B5C"/>
  <path d="M79.8123 202.653C79.4866 202.653 79.1992 202.308 79.1992 201.867V18.3317C79.1992 17.8905 79.4674 17.5452 79.8123 17.5452C80.1571 17.5452 80.4253 17.8905 80.4253 18.3317V201.867C80.4253 202.308 80.1571 202.653 79.8123 202.653Z" fill="#585B5C"/>
  <path d="M84.6402 188.247C84.6402 189.072 83.9696 189.744 83.1458 189.744C82.322 189.744 81.6515 189.072 81.6515 188.247C81.6515 187.423 82.322 186.751 83.1458 186.751C83.9696 186.751 84.6402 187.423 84.6402 188.247Z" fill="#585B5C"/>
  <path d="M84.6402 165.593C84.6402 166.418 83.9696 167.09 83.1458 167.09C82.322 167.09 81.6515 166.418 81.6515 165.593C81.6515 164.768 82.322 164.097 83.1458 164.097C83.9696 164.097 84.6402 164.768 84.6402 165.593Z" fill="#585B5C"/>
  <path d="M84.6402 142.939C84.6402 143.764 83.9696 144.435 83.1458 144.435C82.322 144.435 81.6515 143.764 81.6515 142.939C81.6515 142.114 82.322 141.443 83.1458 141.443C83.9696 141.443 84.6402 142.114 84.6402 142.939Z" fill="#585B5C"/>
  <path d="M84.6402 120.266C84.6402 121.091 83.9696 121.762 83.1458 121.762C82.322 121.762 81.6515 121.091 81.6515 120.266C81.6515 119.441 82.322 118.77 83.1458 118.77C83.9696 118.77 84.6402 119.441 84.6402 120.266Z" fill="#585B5C"/>
  <path d="M84.6402 97.6116C84.6402 98.4365 83.9696 99.1079 83.1458 99.1079C82.322 99.1079 81.6515 98.4365 81.6515 97.6116C81.6515 96.7868 82.322 96.1154 83.1458 96.1154C83.9696 96.1154 84.6402 96.7868 84.6402 97.6116Z" fill="#585B5C"/>
  <path d="M84.6402 74.9575C84.6402 75.7823 83.9696 76.4537 83.1458 76.4537C82.322 76.4537 81.6515 75.7823 81.6515 74.9575C81.6515 74.1327 82.322 73.4613 83.1458 73.4613C83.9696 73.4613 84.6402 74.1327 84.6402 74.9575Z" fill="#585B5C"/>
  <path d="M84.6402 52.3033C84.6402 53.1282 83.9696 53.7995 83.1458 53.7995C82.322 53.7995 81.6515 53.1282 81.6515 52.3033C81.6515 51.4785 82.322 50.8071 83.1458 50.8071C83.9696 50.8071 84.6402 51.4785 84.6402 52.3033Z" fill="#585B5C"/>
  <path d="M84.6402 29.63C84.6402 30.4548 83.9696 31.1262 83.1458 31.1262C82.322 31.1262 81.6515 30.4548 81.6515 29.63C81.6515 28.8052 82.322 28.1338 83.1458 28.1338C83.9696 28.1338 84.6402 28.8052 84.6402 29.63Z" fill="#585B5C"/>
  <path d="M84.6402 19.7895C84.6402 20.6144 83.9696 21.2857 83.1458 21.2857C82.322 21.2857 81.6515 20.6144 81.6515 19.7895C81.6515 18.9647 82.322 18.2933 83.1458 18.2933C83.9696 18.2933 84.6402 18.9647 84.6402 19.7895Z" fill="#585B5C"/>
</svg>`,
  clothe_11: `<svg xmlns="http://www.w3.org/2000/svg" width="153" height="175" viewBox="0 0 153 175" fill="none">
  <path d="M24.9448 86.0926C25.2151 89.9483 25.5627 94.6671 26.0069 103.587C26.5862 115.039 27.509 148.091 26.6786 174.946C26.6786 174.946 39.4422 174.946 75.1489 174.946C110.856 174.946 125.156 174.562 125.156 174.562C124.345 147.707 125.325 115.307 125.75 104.086C126.272 90.6004 127.083 85.3637 127.334 77.9402C127.334 80.3955 127.411 86.0926 127.411 86.0926L152.149 80.1653C152.149 80.1653 150.43 54.4612 150.546 50.7015C150.855 39.8828 148.75 27.6829 136.256 25.6496C127.797 24.2685 116.964 20.5855 108.911 18.0726C95.6631 13.9484 95.6824 0.0605469 95.6824 0.0605469C95.6824 0.0605469 82.1451 6.50576 76.5448 8.57744C75.9075 9.51736 75.8689 21.9858 75.8689 21.9858C75.8689 21.9858 75.6758 9.28718 75.193 8.57744C69.3803 6.00702 56.3644 0.0605469 56.3644 0.0605469C56.3644 0.0605469 56.1906 13.9293 42.943 18.0726C34.8901 20.5855 24.0564 24.2685 15.598 25.6496C5.53681 27.2801 2.21525 35.5092 1.4621 44.3138C1.2883 46.4431 0.148926 80.1845 0.148926 80.1845C0.148926 80.1845 12.2185 85.0184 24.9448 86.131V86.0926Z" fill="#F4F4F4"/>
  <path d="M83.2845 25.4769C83.1686 25.4769 83.0527 25.4578 82.9562 25.4002C82.7631 25.3043 82.6086 25.1317 82.5506 24.9207C80.9478 19.3386 78.2249 11.0519 77.1241 10.0928C76.7959 9.8051 76.7765 9.32554 77.0662 8.99944C77.3559 8.69253 77.8387 8.65416 78.167 8.9419C79.8278 10.3997 82.7438 20.0292 83.7866 23.5971C85.486 22.5996 89.9083 19.5688 96.8411 11.7617C97.1308 11.4356 97.6135 11.4164 97.9225 11.6849C98.2508 11.9727 98.2701 12.4522 97.9998 12.7592C88.711 23.2326 83.8059 25.3235 83.5935 25.4194C83.4969 25.4578 83.4004 25.4769 83.3038 25.4769H83.2845Z" fill="#8FC5D8"/>
  <path d="M68.3568 25.4769C68.2602 25.4769 68.1637 25.4578 68.0671 25.4194C67.8547 25.3427 62.9689 23.2326 53.6608 12.7592C53.3711 12.4331 53.4098 11.9535 53.7381 11.6849C54.0663 11.3972 54.5491 11.4356 54.8195 11.7617C61.7523 19.5688 66.1746 22.5996 67.874 23.5971C68.9168 20.0484 71.8521 10.3997 73.4936 8.9419C73.8219 8.65416 74.3047 8.69253 74.5944 8.99944C74.884 9.32554 74.8454 9.8051 74.5364 10.0928C73.4357 11.0519 70.7128 19.3386 69.1099 24.9207C69.052 25.1317 68.8975 25.3043 68.7044 25.4002C68.6078 25.4578 68.492 25.4769 68.3761 25.4769H68.3568Z" fill="#8FC5D8"/>
  <path d="M24.5585 73.9119L23.6702 82.4288L0.264794 76.9619C0.20686 78.9377 0.148926 80.1653 0.148926 80.1653C0.148926 80.1653 12.2765 84.9609 24.9448 86.1118L24.5585 73.9311V73.9119Z" fill="#8FC5D8"/>
  <path d="M127.372 74.1421L128.647 82.448L152.052 76.9619C152.11 78.9377 152.168 80.1653 152.168 80.1653C152.168 80.1653 140.041 84.9609 127.372 86.1118V74.1421Z" fill="#8FC5D8"/>
</svg>`,
  clothe_12: `<svg xmlns="http://www.w3.org/2000/svg" width="162" height="172" viewBox="0 0 162 172" fill="none">
  <path d="M161.231 40.1159C161.148 34.0859 152.908 9.50554 140.684 7.49556C132.403 6.13463 121.785 2.49154 113.9 0C111.626 0.85843 107.829 18.5714 81.0031 18.5714C54.1769 18.5714 50.3804 0.85843 48.1066 0C40.2215 2.49154 29.6037 6.13463 21.3222 7.49556C9.07726 9.50554 0.837495 34.0859 0.754055 40.1159C5.73963 50.752 30.6467 65.136 30.6467 65.136C30.6467 65.136 31.2307 145.054 30.5841 169.529C30.5841 169.529 37.0925 172 80.2313 172C80.5024 172 80.7319 172 81.0031 172C81.2534 172 81.5037 172 81.7749 172C124.914 172 131.422 169.529 131.422 169.529C130.775 145.054 131.36 65.136 131.36 65.136C131.36 65.136 156.267 50.752 161.252 40.1159H161.231Z" fill="black"/>
  <path d="M81.0031 24.6223C108.831 24.6223 116.069 7.20243 119.302 1.71686C117.425 1.10968 115.589 0.523433 113.92 0C111.647 0.85843 107.85 18.5714 81.0239 18.5714C54.1978 18.5714 50.3804 0.85843 48.1066 0C46.4169 0.523433 44.6021 1.10968 42.7247 1.71686C45.9372 7.20243 53.1965 24.6223 81.0031 24.6223Z" fill="#1C75BC"/>
  <path d="M30.6467 65.136C30.6467 65.136 3.9248 35.5725 2.06824 33.5416C-0.205513 34.4628 -0.288953 38.2734 0.378572 40.3881C1.0461 42.5027 14.042 57.6195 30.6467 65.136Z" fill="#1C75BC"/>
  <path d="M131.339 65.136C131.339 65.136 158.061 35.5725 159.917 33.5416C162.191 34.4628 162.274 38.2734 161.607 40.3881C160.939 42.5027 147.943 57.6195 131.339 65.136Z" fill="#1C75BC"/>
</svg>`,
  clothe_13: `<svg xmlns="http://www.w3.org/2000/svg" width="152" height="175" viewBox="0 0 152 175" fill="none">
  <path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#CFE9FF"/>
</svg>`,
  clothe_14: `<svg xmlns="http://www.w3.org/2000/svg" width="153" height="175" viewBox="0 0 153 175" fill="none">
  <path d="M24.9638 86.0926C25.2342 89.9483 25.5818 94.6671 26.0259 103.587C26.6053 115.039 27.528 148.091 26.6976 174.946C26.6976 174.946 39.4612 174.946 75.168 174.946C110.875 174.946 125.175 174.562 125.175 174.562C124.364 147.707 125.344 115.307 125.769 104.086C126.291 90.6004 127.102 85.3637 127.353 77.9402C127.353 80.3955 127.43 86.0926 127.43 86.0926L152.168 80.1653C152.168 80.1653 150.449 54.4612 150.565 50.7015C150.874 39.8828 148.769 27.6829 136.275 25.6496C127.816 24.2685 116.983 20.5855 108.93 18.0726C95.6821 13.9484 95.7015 0.0605469 95.7015 0.0605469C95.7015 0.0605469 82.1642 6.50576 76.5639 8.57744C75.9266 9.51736 75.888 21.9858 75.888 21.9858C75.888 21.9858 75.6948 9.28718 75.2121 8.57744C69.3993 6.00702 56.3834 0.0605469 56.3834 0.0605469C56.3834 0.0605469 56.2096 13.9293 42.962 18.0726C34.9092 20.5855 24.0755 24.2685 15.6171 25.6496C5.55585 27.2801 2.23429 35.5092 1.48114 44.3138C1.30734 46.4431 0.167969 80.1845 0.167969 80.1845C0.167969 80.1845 12.2376 85.0184 24.9638 86.131V86.0926Z" fill="#232323"/>
  <path d="M83.3035 25.4769C83.1877 25.4769 83.0718 25.4578 82.9752 25.4002C82.7821 25.3043 82.6276 25.1317 82.5697 24.9207C80.9668 19.3386 78.2439 11.0519 77.1432 10.0928C76.8149 9.8051 76.7956 9.32554 77.0853 8.99944C77.3749 8.69253 77.8577 8.65416 78.186 8.9419C79.8468 10.3997 82.7628 20.0292 83.8056 23.5971C85.505 22.5996 89.9273 19.5688 96.8601 11.7617C97.1498 11.4356 97.6326 11.4164 97.9416 11.6849C98.2699 11.9727 98.2892 12.4522 98.0188 12.7592C88.73 23.2326 83.8249 25.3235 83.6125 25.4194C83.5159 25.4578 83.4194 25.4769 83.3228 25.4769H83.3035Z" fill="#494949"/>
  <path d="M68.3758 25.4769C68.2793 25.4769 68.1827 25.4578 68.0862 25.4194C67.8737 25.3427 62.9879 23.2326 53.6799 12.7592C53.3902 12.4331 53.4288 11.9535 53.7571 11.6849C54.0854 11.3972 54.5682 11.4356 54.8385 11.7617C61.7713 19.5688 66.1936 22.5996 67.893 23.5971C68.9359 20.0484 71.8712 10.3997 73.5127 8.9419C73.841 8.65416 74.3237 8.69253 74.6134 8.99944C74.9031 9.32554 74.8645 9.8051 74.5555 10.0928C73.4547 11.0519 70.7318 19.3386 69.129 24.9207C69.071 25.1317 68.9165 25.3043 68.7234 25.4002C68.6269 25.4578 68.511 25.4769 68.3951 25.4769H68.3758Z" fill="#494949"/>
  <path d="M24.5776 73.9119L23.6892 82.4288L0.283837 76.9619C0.225903 78.9377 0.167969 80.1653 0.167969 80.1653C0.167969 80.1653 12.2955 84.9609 24.9638 86.1118L24.5776 73.9311V73.9119Z" fill="#494949"/>
  <path d="M127.391 74.1421L128.666 82.448L152.071 76.9619C152.129 78.9377 152.187 80.1653 152.187 80.1653C152.187 80.1653 140.06 84.9609 127.391 86.1118V74.1421Z" fill="#494949"/>
</svg>`,
  clothe_15: `<svg xmlns="http://www.w3.org/2000/svg" width="163" height="173" viewBox="0 0 163 173" fill="none">
  <path d="M161.36 40.6188C161.277 34.5889 153.037 10.0085 140.813 7.99849C132.531 6.63756 121.914 2.99447 114.028 0.50293C111.755 1.36136 107.958 19.0743 81.132 19.0743C54.3058 19.0743 50.5093 1.36136 48.2355 0.50293C40.3504 2.99447 29.7326 6.63756 21.4511 7.99849C9.20617 10.0085 0.966402 34.5889 0.882961 40.6188C5.86854 51.255 30.7756 65.6389 30.7756 65.6389C30.7756 65.6389 31.3597 145.557 30.713 170.032C30.713 170.032 37.2214 172.503 80.3602 172.503C80.6313 172.503 80.8608 172.503 81.132 172.503C81.3823 172.503 81.6326 172.503 81.9038 172.503C125.043 172.503 131.551 170.032 131.551 170.032C130.904 145.557 131.488 65.6389 131.488 65.6389C131.488 65.6389 156.395 51.255 161.381 40.6188H161.36Z" fill="#FE862D"/>
  <path d="M81.132 25.1252C108.959 25.1252 116.198 7.70536 119.431 2.21979C117.554 1.61261 115.718 1.02636 114.049 0.50293C111.776 1.36136 107.979 19.0743 81.1529 19.0743C54.3267 19.0743 50.5093 1.36136 48.2355 0.50293C46.5458 1.02636 44.731 1.61261 42.8536 2.21979C46.0661 7.70536 53.3254 25.1252 81.132 25.1252Z" fill="#2C251F"/>
  <path d="M30.7756 65.6389C30.7756 65.6389 4.05371 36.0754 2.19715 34.0445C-0.0766065 34.9657 -0.160047 38.7763 0.507478 40.891C1.175 43.0057 14.1709 58.1224 30.7756 65.6389Z" fill="#2C251F"/>
  <path d="M131.468 65.6389C131.468 65.6389 158.189 36.0754 160.046 34.0445C162.32 34.9657 162.403 38.7763 161.736 40.891C161.068 43.0057 148.072 58.1224 131.468 65.6389Z" fill="#2C251F"/>
</svg>`,
  clothe_16: `<svg xmlns="http://www.w3.org/2000/svg" width="163" height="173" viewBox="0 0 163 173" fill="none">
  <path d="M161.316 40.6188C161.233 34.5889 152.993 10.0085 140.769 7.99849C132.487 6.63756 121.87 2.99447 113.985 0.50293C111.711 1.36136 107.914 19.0743 81.0881 19.0743C54.2619 19.0743 50.4653 1.36136 48.1916 0.50293C40.3064 2.99447 29.6886 6.63756 21.4071 7.99849C9.16222 10.0085 0.922456 34.5889 0.839016 40.6188C5.82459 51.255 30.7316 65.6389 30.7316 65.6389C30.7316 65.6389 31.3157 145.557 30.669 170.032C30.669 170.032 37.1774 172.503 80.3162 172.503C80.5874 172.503 80.8169 172.503 81.0881 172.503C81.3384 172.503 81.5887 172.503 81.8599 172.503C124.999 172.503 131.507 170.032 131.507 170.032C130.86 145.557 131.444 65.6389 131.444 65.6389C131.444 65.6389 156.352 51.255 161.337 40.6188H161.316Z" fill="#C9E6FF"/>
  <path d="M81.0881 25.1252C108.916 25.1252 116.154 7.70536 119.387 2.21979C117.51 1.61261 115.674 1.02636 114.005 0.50293C111.732 1.36136 107.935 19.0743 81.1089 19.0743C54.2827 19.0743 50.4653 1.36136 48.1916 0.50293C46.5019 1.02636 44.6871 1.61261 42.8097 2.21979C46.0221 7.70536 53.2815 25.1252 81.0881 25.1252Z" fill="#0071CE"/>
  <path d="M30.7316 65.6389C30.7316 65.6389 4.00976 36.0754 2.15321 34.0445C-0.120552 34.9657 -0.203992 38.7763 0.463533 40.891C1.13106 43.0057 14.1269 58.1224 30.7316 65.6389Z" fill="#0071CE"/>
  <path d="M131.424 65.6389C131.424 65.6389 158.145 36.0754 160.002 34.0445C162.276 34.9657 162.359 38.7763 161.692 40.891C161.024 43.0057 148.028 58.1224 131.424 65.6389Z" fill="#0071CE"/>
</svg>`,
  clothe_17: `<svg xmlns="http://www.w3.org/2000/svg" width="156" height="145" viewBox="0 0 156 145" fill="none">
  <path d="M0.0556641 100.072C0.0556641 100.072 1.07206 28.3807 4.12124 18.4236C7.17042 8.46649 26.796 5.97721 26.796 5.97721C26.796 10.1533 26.8269 58.7772 26.796 63.2307L23.9186 104.393C14.5046 97.5358 1.26139 100.92 0.0556641 100.072Z" fill="#E8E8E8"/>
  <path d="M155.056 101.068C155.056 101.068 154.166 28.3807 151.498 17.9258C148.83 7.47078 131.299 6.13668 131.299 6.13668C131.299 10.3128 129.107 54.7943 129.138 59.2478L132.187 106.544C141.601 99.6867 144.194 101.915 145.4 101.068H155.056Z" fill="#E8E8E8"/>
  <path d="M131.485 6.13603L120.463 3.59336C117.758 21.392 100.476 35.1224 79.5612 35.1224C58.6465 35.1224 41.2098 21.2687 38.6283 3.3468L26.5556 6.12062C23.9186 30.87 28.4414 104.715 28.4414 104.715C28.4414 106.348 28.4414 125.463 28.4414 134.317C50.5155 145.905 89.0143 150.553 127.613 135.42C126.597 126.458 126.597 106.669 126.597 105.051C126.597 105.051 132.613 14.7194 131.5 6.12062L131.485 6.13603Z" fill="#F0EDED"/>
  <path d="M32.9553 4.30234L51.0566 0.00292969C51.0566 0.00292969 51.2267 33.0884 79.5613 35.1379L69.2199 50.4709C69.2199 50.4709 37.9482 40.5469 32.9553 4.30234Z" fill="#C11717"/>
  <path d="M125.626 4.30234L107.525 0.00292969C107.525 0.00292969 107.355 33.0884 79.0202 35.1379L89.3616 50.4709C89.3616 50.4709 120.633 40.5469 125.626 4.30234Z" fill="#C11717"/>
  <path d="M79.2675 48.5909C80.4371 48.5909 81.3852 47.6457 81.3852 46.4797C81.3852 45.3137 80.4371 44.3685 79.2675 44.3685C78.0979 44.3685 77.1497 45.3137 77.1497 46.4797C77.1497 47.6457 78.0979 48.5909 79.2675 48.5909Z" fill="#C11717"/>
  <path d="M79.2675 93.9124C80.4371 93.9124 81.3852 92.9672 81.3852 91.8012C81.3852 90.6352 80.4371 89.69 79.2675 89.69C78.0979 89.69 77.1497 90.6352 77.1497 91.8012C77.1497 92.9672 78.0979 93.9124 79.2675 93.9124Z" fill="#C11717"/>
  <path d="M79.2675 139.234C80.4371 139.234 81.3852 138.289 81.3852 137.123C81.3852 135.957 80.4371 135.011 79.2675 135.011C78.0979 135.011 77.1497 135.957 77.1497 137.123C77.1497 138.289 78.0979 139.234 79.2675 139.234Z" fill="#C11717"/>
</svg>`,
  clothe_18: `<svg width="153" height="175" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#CDCDCD"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#737373"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#737373"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#737373"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#737373"/>
</svg>
`,
  clothe_19: `<svg width="153" height="175" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#B11C1C"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2A2929"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2A2929"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2A2929"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2A2929"/>
</svg>
`,
  clothe_20: `<svg width="153" height="175" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#E9B90C"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#827B35"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#827B35"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#827B35"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#827B35"/>
</svg>
`,
  clothe_21: `<svg width="153" height="175" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#098C4D"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#0C5D10"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#0C5D10"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#0C5D10"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#0C5D10"/>
</svg>
`,
  clothe_22: `<svg width="153" height="175" viewBox="0 0 153 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 27.3601 148.03 26.5297 174.885C26.5297 174.885 39.2932 174.885 75 174.885C110.707 174.885 125.007 174.502 125.007 174.502C124.196 147.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#143351"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3589C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#486C90"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3589C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#486C90"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#486C90"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#486C90"/>
</svg>
`,
  clothe_23: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#B5B5B5"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#B5B5B5"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#A7A7A7"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#414141"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#414141"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#414141"/>
</svg>
`,
  clothe_24: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#7F1818"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#7F1818"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#902020"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#7A1818"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#7A1818"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#7A1818"/>
</svg>
`,
  clothe_25: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#333333"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#333333"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#333333"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#333333"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#333333"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#333333"/>
</svg>
`,
  clothe_26: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#E5E5E5"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#E5E5E5"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#E5E5E5"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#E5E5E5"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#E5E5E5"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#E5E5E5"/>
</svg>
`,
  clothe_27: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#FFA25D"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#FFA25D"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#FFA25D"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#FFA25D"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#FFA25D"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#FFA25D"/>
</svg>
`,
  clothe_28: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#7D3906"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#7D3906"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#7D3906"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#7D3906"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#7D3906"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#7D3906"/>
</svg>
`,
  clothe_29: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#098C4D"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#098C4D"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#098C4D"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#098C4D"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#098C4D"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#098C4D"/>
</svg>
`,
  clothe_30: `<svg width="168" height="182" viewBox="0 0 168 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.492 102.338C138.904 102.47 136.3 102.602 135.702 102.734L133.014 85.3486C132.983 79.3688 133.014 10.3437 133.014 4.73354C133.014 4.73354 154.465 7.85827 157.5 22.2335L168 92.622C160.891 96.5425 147.231 100.595 139.482 102.338H139.492Z" fill="#1A446C"/>
<path d="M28.5094 102.338C29.0973 102.47 31.7005 102.602 32.2988 102.734L34.986 85.3486C35.0175 79.3688 34.986 10.3437 34.986 4.73354C34.986 4.73354 15.5336 8.85827 12.5 23.2335L0 92.622C7.10637 96.5425 20.7628 100.595 28.5094 102.338Z" fill="#1A446C"/>
<path d="M134.527 122.934C134.527 122.934 136.226 16.0108 135.162 5.73354L84.5 32.6661L33.8383 5.73354C32.7737 16.0108 34.4728 122.934 34.4728 122.934C34.4728 127.191 31.58 169.547 33.9028 175.382C33.9136 175.421 33.9243 175.461 33.9458 175.501C33.9674 175.541 33.9889 175.567 34.0104 175.607C34.0534 175.687 34.0964 175.753 34.1502 175.832C34.1932 175.885 34.2254 175.925 34.2685 175.952C37.2581 179.108 56.6689 181.574 80.5963 181.734C80.7899 181.734 83.6827 181.734 84.5 181.734C85.3173 181.734 88.2101 181.734 88.4037 181.734C112.331 181.574 131.742 179.108 134.732 175.952C134.775 175.925 134.807 175.872 134.85 175.832C134.904 175.753 134.947 175.687 134.99 175.607C135.011 175.567 135.033 175.541 135.054 175.501C135.065 175.461 135.086 175.421 135.097 175.382C137.42 169.533 134.527 127.178 134.527 122.934Z" fill="#1A446C"/>
<path d="M61 0V4.88731C61 7.85129 72.9914 21.7449 85 21.7334C96.9914 21.7334 109 7.83971 109 4.88731V9.7649e-07L135.654 4.73847C136.009 8.15646 136.052 22.3674 135.955 39.7334H34.0447C33.948 22.3674 33.991 8.15646 34.3458 4.73847L61 0Z" fill="#1A446C"/>
<path d="M135.011 98.9376L135.611 102.734C136.211 102.605 138.811 102.476 139.411 102.348C147.189 100.649 160.874 96.6986 168 92.8769L167.179 88.7336C153.084 95.5534 139.979 98.1398 135 98.9376H135.011Z" fill="#1A446C"/>
<path d="M0 92.7682C7.11724 96.6321 20.7945 100.626 28.553 102.343C29.1418 102.473 31.749 102.603 32.3482 102.734L33 98.5835C26.8289 97.4517 14.2134 94.4985 0.988213 87.7336L0 92.7813V92.7682Z" fill="#1A446C"/>
</svg>
`,
  clothe_31: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#854312"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#F9954B"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#F9954B"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#F9954B"/>
</svg>
`,
  clothe_32: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E9B90C"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E9B90C"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#E9B90C"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#E9B90C"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#E9B90C"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#E9B90C"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#E9B90C"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#E9B90C"/>
</svg>
`,
  clothe_33: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#F9954B"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#F9954B"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#F9954B"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#F9954B"/>
</svg>
`,
  clothe_34: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#ECECEC"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#8F8F8F"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#8F8F8F"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#8F8F8F"/>
</svg>
`,
  clothe_35: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#C11717"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#341D1D"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#341D1D"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#341D1D"/>
</svg>
`,
  clothe_36: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#098C4D"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#045C07"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#045C07"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#045C07"/>
</svg>
`,
  clothe_37: `<svg width="162" height="172" viewBox="0 0 162 172" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M161.217 40.1159C161.133 34.0859 152.893 9.50554 140.669 7.49556C132.388 6.13463 121.77 2.49154 113.885 0C111.611 0.85843 107.815 18.5714 80.9884 18.5714C54.1623 18.5714 50.3657 0.85843 48.092 0C40.2068 2.49154 29.589 6.13463 21.3075 7.49556C9.06261 9.50554 0.822847 34.0859 0.739406 40.1159C5.72498 50.752 30.632 65.136 30.632 65.136C30.632 65.136 31.2161 145.054 30.5694 169.529C30.5694 169.529 37.0778 172 80.2166 172C80.4878 172 80.7173 172 80.9884 172C81.2388 172 81.4891 172 81.7603 172C124.899 172 131.407 169.529 131.407 169.529C130.761 145.054 131.345 65.136 131.345 65.136C131.345 65.136 156.252 50.752 161.237 40.1159H161.217Z" fill="#0071CE"/>
<path d="M80.9884 24.6223C108.816 24.6223 116.054 7.20243 119.288 1.71686C117.41 1.10968 115.575 0.523433 113.906 0C111.632 0.85843 107.835 18.5714 81.0093 18.5714C54.1831 18.5714 50.3657 0.85843 48.092 0C46.4023 0.523433 44.5875 1.10968 42.71 1.71686C45.9225 7.20243 53.1818 24.6223 80.9884 24.6223Z" fill="#113552"/>
<path d="M30.632 65.136C30.632 65.136 3.91015 35.5725 2.0536 33.5416C-0.220161 34.4628 -0.303602 38.2734 0.363923 40.3881C1.03145 42.5027 14.0273 57.6195 30.632 65.136Z" fill="#113552"/>
<path d="M131.324 65.136C131.324 65.136 158.046 35.5725 159.902 33.5416C162.176 34.4628 162.26 38.2734 161.592 40.3881C160.925 42.5027 147.929 57.6195 131.324 65.136Z" fill="#113552"/>
</svg>
`,
  clothe_38: `<svg width="165" height="225" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="black"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="black"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="black"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#333333"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="#606060"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="#606060"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="#606060"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="#606060"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="#606060"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="#606060"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="#606060"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#636567"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#636567"/>
</svg>
`,
  clothe_39: `<svg width="165" height="225" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="#353535"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="black"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="black"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#B11C1C"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="black"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="black"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="black"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="black"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="black"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="black"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="black"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#6B1010"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#6B1010"/>
</svg>
`,
  clothe_40: `<svg width="165" height="225" viewBox="0 0 165 225" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8448 86.0321C31.1152 89.8877 31.4628 94.6065 31.907 103.526C32.4863 114.978 32.4091 155.03 31.5787 181.885C31.5787 181.885 46.0815 205 81.7883 205C117.495 205 132.056 181.502 132.056 181.502C131.245 154.647 131.225 115.247 131.65 104.025C132.172 90.5399 132.983 85.3032 133.234 77.8797C133.234 80.335 133.311 86.0321 133.311 86.0321L158.049 80.1048C158.049 80.1048 156.33 54.4007 156.446 50.641C156.755 39.8222 154.65 27.6223 142.156 25.589C133.697 24.2079 122.864 20.5249 114.811 18.0121C101.563 13.8879 101.582 0 101.582 0C101.582 0 88.0452 6.44521 82.4449 8.51689C81.8076 9.45682 81.769 21.9252 81.769 21.9252C81.769 21.9252 81.5759 9.22663 81.0931 8.51689C75.2804 5.94648 62.2645 0 62.2645 0C62.2645 0 62.0907 13.8687 48.8431 18.0121C40.7902 20.5249 29.9565 24.2079 21.4981 25.589C11.4369 27.2195 8.11532 35.4487 7.36218 44.2533C7.18838 46.3825 6.04901 80.124 6.04901 80.124C6.04901 80.124 18.1186 84.9579 30.8448 86.0705V86.0321Z" fill="#E9E9E9"/>
<path d="M80.5722 14.7895L78.7762 11.4326L77.4051 15.0581C77.4051 15.0581 77.5982 15.8253 78.9886 17.0338C79.2976 15.2499 80.5722 14.7895 80.5722 14.7895Z" fill="#353535"/>
<path d="M82.8702 14.7895L84.2993 11.4326L85.6897 13.6961C85.6897 13.6961 85.9407 14.8662 85.1103 15.3074C84.8013 14.7895 82.8702 14.7895 82.8702 14.7895Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M91.7921 175.958C91.6569 170.415 88.9533 79.3375 87.6594 41.0115C87.2732 29.7516 84.4537 26.2604 84.5696 23.9394C84.8979 17.5517 77.7527 19.2589 78.6796 24.8026C79.0079 26.7975 74.7401 32.111 74.0256 41.0883C71.013 79.2608 70.4723 170.415 70.3371 175.958C70.3178 176.956 81.0163 189.578 81.0163 189.578C81.0163 189.578 91.8114 176.975 91.7921 175.958Z" fill="#353535"/>
<path d="M86.3656 14.6936C86.2111 13.2741 84.1641 13.7345 81.4798 13.7345C78.7955 13.7345 77.3472 13.37 77.1927 14.7895C77.0382 16.209 76.034 18.0505 76.1112 18.9137C76.1498 19.4124 76.8451 26.4139 78.2548 25.9535C81.4798 24.8793 85.1683 25.4548 85.4386 24.9752C86.1145 23.7859 87.4084 19.7193 87.5243 19.2206C87.7367 18.2807 86.5394 16.1131 86.3849 14.6936H86.3656Z" fill="#353535"/>
<path d="M89.1846 25.4163C89.0687 25.4163 88.9528 25.3971 88.8563 25.3395C88.6632 25.2436 88.5087 25.071 88.4507 24.86C86.8479 19.278 84.125 10.9913 83.0242 10.0321C82.6959 9.74441 82.6766 9.26486 82.9663 8.93876C83.256 8.63184 83.7388 8.59348 84.067 8.88121C85.7278 10.3391 88.6438 19.9685 89.6867 23.5364C91.3861 22.5389 95.8084 19.5081 102.741 11.701C103.031 11.3749 103.514 11.3557 103.823 11.6243C104.151 11.912 104.17 12.3916 103.9 12.6985C94.6111 23.1719 89.706 25.2628 89.4935 25.3587C89.397 25.3971 89.3004 25.4163 89.2039 25.4163H89.1846Z" fill="#BABABA"/>
<path d="M74.2569 25.4163C74.1603 25.4163 74.0637 25.3971 73.9672 25.3587C73.7548 25.282 68.869 23.1719 59.5609 12.6985C59.2712 12.3724 59.3098 11.8928 59.6381 11.6243C59.9664 11.3365 60.4492 11.3749 60.7196 11.701C67.6524 19.5081 72.0747 22.5389 73.7741 23.5364C74.8169 19.9877 77.7522 10.3391 79.3937 8.88121C79.722 8.59348 80.2048 8.63184 80.4944 8.93876C80.7841 9.26486 80.7455 9.74441 80.4365 10.0321C79.3358 10.9913 76.6129 19.278 75.01 24.86C74.9521 25.071 74.7976 25.2436 74.6045 25.3395C74.5079 25.3971 74.392 25.4163 74.2762 25.4163H74.2569Z" fill="#BABABA"/>
<path d="M30.4586 73.8513L29.5703 82.3681L6.16487 76.9012C6.10694 78.877 6.04901 80.1047 6.04901 80.1047C6.04901 80.1047 18.1766 84.9002 30.8448 86.0511L30.4586 73.8704V73.8513Z" fill="#BABABA"/>
<path d="M133.272 74.0815L134.547 82.3873L157.952 76.9012C158.01 78.877 158.068 80.1047 158.068 80.1047C158.068 80.1047 145.941 84.9002 133.272 86.0511V74.0815Z" fill="#BABABA"/>
<path d="M157.236 51.7582C157.662 36.2331 152.694 27.5195 142.47 25.8506C135.415 24.6862 126.446 21.814 119.256 19.5047C117.845 19.0583 116.492 18.612 115.197 18.2044C107.872 15.895 105.978 11.6644 105.978 11.6644C105.978 11.6644 109.186 82.4981 81.3918 118.711C51.7034 57.1144 58.4684 10.8494 58.4684 10.8494C58.4684 10.8494 56.2263 15.895 48.9008 18.2044C47.6058 18.612 46.2528 19.0389 44.8419 19.5047C37.6517 21.814 28.6833 24.6862 21.6285 25.8506C11.4037 27.5195 6.43635 36.2525 6.86158 51.7582C7.13217 61.2868 0 202.741 0 202.741H19.3284C19.3284 202.741 30.9254 126.337 31.0994 104.369C31.312 111.123 32.723 151.449 31.8532 178.948C31.254 197.928 31.37 209.708 31.8145 220.284C43.6242 225.369 74.9941 225 74.9941 225L82.0683 217.955L89.1425 225C89.1425 225 120.512 225.369 132.322 220.284C132.747 209.688 132.863 197.928 132.283 178.948C131.433 151.449 133.308 110.23 133.675 104.369C134.835 148.014 144.808 202.741 144.808 202.741H164.137C164.137 202.741 157.004 61.2868 157.275 51.7582H157.236Z" fill="#0B0B0B"/>
<path d="M90.3602 176.115C90.3602 177.338 89.3358 178.347 88.0795 178.347C86.8231 178.347 85.7987 177.357 85.7987 176.115C85.7987 174.873 86.8231 173.883 88.0795 173.883C89.3358 173.883 90.3602 174.873 90.3602 176.115Z" fill="#353535"/>
<path d="M90.3602 154.981C90.3602 156.204 89.3358 157.213 88.0795 157.213C86.8231 157.213 85.7987 156.223 85.7987 154.981C85.7987 153.739 86.8231 152.75 88.0795 152.75C89.3358 152.75 90.3602 153.739 90.3602 154.981Z" fill="#353535"/>
<path d="M90.3602 133.867C90.3602 135.09 89.3358 136.099 88.0795 136.099C86.8231 136.099 85.7987 135.109 85.7987 133.867C85.7987 132.625 86.8231 131.635 88.0795 131.635C89.3358 131.635 90.3602 132.625 90.3602 133.867Z" fill="#353535"/>
<path d="M113.593 180.055C113.245 180.055 112.936 179.822 112.858 179.472C112.839 179.375 112.337 177.241 111.679 171.108C111.66 170.914 111.718 170.72 111.853 170.565C111.989 170.41 112.163 170.313 112.375 170.293C117.401 169.808 127.915 167.731 128.031 167.712C128.456 167.634 128.843 167.887 128.94 168.294C129.017 168.702 128.766 169.09 128.34 169.187C127.935 169.265 118.599 171.128 113.303 171.71C113.902 177.163 114.366 179.123 114.366 179.142C114.463 179.55 114.211 179.938 113.786 180.035C113.728 180.035 113.67 180.035 113.612 180.035L113.593 180.055Z" fill="#353535"/>
<path d="M49.2294 180.055C49.5773 180.055 49.8866 179.822 49.9639 179.472C49.9832 179.375 50.4857 177.241 51.1429 171.108C51.1622 170.914 51.1043 170.72 50.969 170.565C50.8337 170.41 50.6597 170.313 50.4471 170.293C45.4217 169.808 34.9071 167.731 34.7911 167.712C34.3659 167.634 33.9793 167.887 33.8827 168.294C33.8054 168.702 34.0566 169.09 34.4818 169.187C34.8877 169.265 44.2234 171.128 49.5193 171.71C48.9202 177.163 48.4563 179.123 48.4563 179.142C48.3596 179.55 48.6109 179.938 49.0361 180.035C49.0941 180.035 49.1521 180.035 49.2101 180.035L49.2294 180.055Z" fill="#353535"/>
<path d="M123.199 88.65C122.774 88.65 122.426 88.3201 122.426 87.8931V85.118C122.426 85.118 122.349 84.9433 122.252 84.9433H109.94C109.515 84.9433 109.167 84.6134 109.167 84.1865C109.167 83.7595 109.515 83.4296 109.94 83.4296H122.252C123.18 83.4296 123.953 84.1865 123.953 85.0986V87.8737C123.953 88.2813 123.605 88.6306 123.18 88.6306L123.199 88.65Z" fill="#353535"/>
<path d="M83.9239 214.113C83.6339 214.113 83.3633 213.958 83.228 213.667C81.5465 210.154 81.5271 202.081 81.5271 201.732V119.235C81.5271 118.808 81.875 118.458 82.3003 118.458C82.7255 118.458 83.0734 118.808 83.0734 119.235V201.751C83.0734 201.751 83.0734 209.805 84.6197 212.987C84.813 213.376 84.6583 213.841 84.2718 214.035C84.1558 214.094 84.0398 214.113 83.9432 214.113H83.9239Z" fill="#353535"/>
<path d="M48.0697 51.6418L54.4867 49.3907L48.0697 46.3632L51.4522 17.4088L59.5701 8.30711C59.5701 8.30711 54.4287 58.9775 82.7835 119.002C82.6482 120.651 81.8364 119.759 81.5271 119.37C57.6952 89.8532 48.0697 51.6224 48.0697 51.6224V51.6418Z" fill="#303030"/>
<path d="M116.531 51.6418L110.114 49.3907L116.531 46.3632L113.148 17.4088L104.296 8.73405C104.296 8.73405 110.152 58.9775 81.7977 119.002C80.754 121.253 82.7448 119.759 83.0541 119.37C106.886 89.8532 116.512 51.6224 116.512 51.6224L116.531 51.6418Z" fill="#303030"/>
</svg>
`,
  clothe_41: `<svg width="153" height="205" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#CDCDCD"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#6A6A6A"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#6A6A6A"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#6A6A6A"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#6A6A6A"/>
</svg>
`,
  clothe_42: `<svg width="153" height="205" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#B11C1C"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_43: `<svg width="153" height="205" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#E9B90C"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_44: `<svg width="153" height="205" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#098C4D"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#353535"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#353535"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="black"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="black"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#2F2F2F"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#2F2F2F"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#2F2F2F"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#2F2F2F"/>
</svg>
`,
  clothe_45: `<svg width="153" height="205" viewBox="0 0 153 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7958 86.0321C25.0662 89.8877 25.4138 94.6065 25.858 103.526C26.4373 114.978 26.3601 155.03 25.5297 181.885C25.5297 181.885 40.0325 205 75.7393 205C111.446 205 126.007 181.502 126.007 181.502C125.196 154.647 125.176 115.247 125.601 104.025C126.123 90.5399 126.934 85.3032 127.185 77.8797C127.185 80.335 127.262 86.0321 127.262 86.0321L152 80.1048C152 80.1048 150.281 54.4007 150.397 50.641C150.706 39.8222 148.601 27.6223 136.107 25.589C127.648 24.2079 116.815 20.5249 108.762 18.0121C95.5142 13.8879 95.5335 0 95.5335 0C95.5335 0 81.9962 6.44521 76.3959 8.51689C75.7586 9.45682 75.72 21.9252 75.72 21.9252C75.72 21.9252 75.5269 9.22663 75.0441 8.51689C69.2314 5.94648 56.2155 0 56.2155 0C56.2155 0 56.0417 13.8687 42.7941 18.0121C34.7412 20.5249 23.9075 24.2079 15.4491 25.589C5.38788 27.2195 2.06632 35.4487 1.31317 44.2533C1.13937 46.3825 0 80.124 0 80.124C0 80.124 12.0696 84.9579 24.7958 86.0705V86.0321Z" fill="#143351"/>
<path d="M74.5227 14.7895L72.7267 11.4326L71.3556 15.058C71.3556 15.058 71.5487 15.8253 72.9391 17.0338C73.2481 15.2498 74.5227 14.7895 74.5227 14.7895Z" fill="#ACACAC"/>
<path d="M76.8207 14.7895L78.2498 11.4326L79.6402 13.6961C79.6402 13.6961 79.8912 14.8662 79.0609 15.3074C78.7519 14.7895 76.8207 14.7895 76.8207 14.7895Z" fill="#ACACAC"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#ACACAC"/>
<path d="M85.7426 175.958C85.6074 170.415 82.9038 79.3375 81.61 41.0115C81.2237 29.7516 78.4043 26.2604 78.5201 23.9394C78.8484 17.5517 71.7032 19.2589 72.6302 24.8026C72.9585 26.7975 68.6906 32.111 67.9761 41.0882C64.9635 79.2608 64.4228 170.415 64.2876 175.958C64.2683 176.956 74.9668 189.578 74.9668 189.578C74.9668 189.578 85.7619 176.975 85.7426 175.958Z" fill="#ECECEC"/>
<path d="M80.3161 14.6936C80.1616 13.2741 78.1146 13.7344 75.4303 13.7344C72.746 13.7344 71.2977 13.37 71.1432 14.7895C70.9887 16.2089 69.9845 18.0504 70.0617 18.9136C70.1004 19.4124 70.7956 26.4139 72.2053 25.9535C75.4303 24.8793 79.1188 25.4548 79.3892 24.9752C80.065 23.7859 81.3589 19.7193 81.4748 19.2205C81.6872 18.2806 80.4899 16.113 80.3354 14.6936H80.3161Z" fill="#ECECEC"/>
<path d="M83.1356 25.4164C83.0197 25.4164 82.9038 25.3972 82.8073 25.3397C82.6142 25.2438 82.4597 25.0711 82.4017 24.8601C80.7989 19.2781 78.076 10.9914 76.9752 10.0323C76.6469 9.74455 76.6276 9.26499 76.9173 8.9389C77.207 8.63198 77.6898 8.59362 78.018 8.88135C79.6788 10.3392 82.5948 19.9687 83.6377 23.5365C85.3371 22.5391 89.7594 19.5083 96.6922 11.7011C96.9818 11.375 97.4646 11.3559 97.7736 11.6244C98.1019 11.9121 98.1212 12.3917 97.8508 12.6986C88.5621 23.1721 83.657 25.2629 83.4445 25.3588C83.348 25.3972 83.2514 25.4164 83.1549 25.4164H83.1356Z" fill="#B3B3B3"/>
<path d="M68.2079 25.4164C68.1113 25.4164 68.0147 25.3972 67.9182 25.3588C67.7058 25.2821 62.82 23.1721 53.5119 12.6986C53.2222 12.3725 53.2608 11.893 53.5891 11.6244C53.9174 11.3367 54.4002 11.375 54.6706 11.7011C61.6034 19.5083 66.0257 22.5391 67.7251 23.5365C68.7679 19.9878 71.7032 10.3392 73.3447 8.88135C73.673 8.59362 74.1558 8.63198 74.4454 8.9389C74.7351 9.26499 74.6965 9.74455 74.3875 10.0323C73.2868 10.9914 70.5638 19.2781 68.961 24.8601C68.9031 25.0711 68.7486 25.2438 68.5555 25.3397C68.4589 25.3972 68.343 25.4164 68.2272 25.4164H68.2079Z" fill="#B3B3B3"/>
<path d="M24.4096 73.8514L23.5213 82.3683L0.115868 76.9014C0.0579342 78.8771 0 80.1048 0 80.1048C0 80.1048 12.1276 84.9003 24.7958 86.0513L24.4096 73.8706V73.8514Z" fill="#B3B3B3"/>
<path d="M127.223 74.0816L128.498 82.3875L151.903 76.9014C151.961 78.8771 152.019 80.1048 152.019 80.1048C152.019 80.1048 139.892 84.9003 127.223 86.0513V74.0816Z" fill="#B3B3B3"/>
</svg>
`,
  clothe_46: `<svg width="161" height="205" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#E9B90C"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#585B5C"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#585B5C"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#585B5C"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#585B5C"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#585B5C"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#585B5C"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#585B5C"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#585B5C"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#585B5C"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#585B5C"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#585B5C"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#585B5C"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#585B5C"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#585B5C"/>
</svg>
`,
  clothe_47: `<svg width="161" height="205" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#B11C1C"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#303030"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#303030"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#303030"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#303030"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#303030"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#303030"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#303030"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#303030"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#303030"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#303030"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#303030"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#303030"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#303030"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#303030"/>
</svg>
`,
  clothe_48: `<svg width="161" height="205" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#CFE9FF"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#5391C6"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#5391C6"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#5391C6"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#5391C6"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#5391C6"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#5391C6"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#5391C6"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#5391C6"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#5391C6"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#5391C6"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#5391C6"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#5391C6"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#5391C6"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#5391C6"/>
</svg>
`,
  clothe_49: `<svg width="161" height="205" viewBox="0 0 161 205" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 203.638H16.0929C16.2653 202.66 25.3463 143.559 26.7066 132.28C27.971 121.826 29.1971 93.916 29.178 77.8605C29.427 85.284 29.906 88.238 30.6723 103.526C31.2471 114.978 31.6111 154.934 30.8064 181.79C30.8064 181.79 44.7536 204.981 80.1772 204.981C115.601 204.981 129.586 181.79 129.586 181.79C128.782 154.934 129.222 115.227 129.644 104.006C130.161 90.5207 130.966 85.284 131.215 77.8605C131.215 93.916 132.422 121.807 133.686 132.28C135.066 143.559 143.725 202.641 143.897 203.638H160.45C159.55 176.323 159.377 144.499 156.523 121.04C154.99 108.456 153.975 59.8484 154.224 50.641C154.53 39.8222 152.442 27.6223 140.047 25.589C131.655 24.2079 120.908 20.5249 112.919 18.0121C99.7761 13.8879 99.7952 0 99.7952 0C99.7952 0 86.3653 6.44521 80.8094 8.51689C80.1772 9.45682 80.1389 21.9252 80.1389 21.9252C80.1389 21.9252 79.9473 9.22663 79.4684 8.51689C73.7017 5.94648 60.7891 0 60.7891 0C60.7891 0 60.6166 13.8687 47.4741 18.0121C39.4851 20.5249 28.7373 24.2079 20.346 25.589C7.96982 27.6223 5.88158 39.8222 6.16895 50.641C6.41801 59.8484 5.40262 108.475 3.86996 121.04C1.01539 144.519 0.900437 176.342 0 203.638Z" fill="#143351"/>
<path d="M87.4956 25.4164C87.3807 25.4164 87.2657 25.3972 87.1699 25.3397C86.9784 25.2438 86.8251 25.0711 86.7676 24.8601C85.1775 19.2781 82.4762 10.9914 81.3842 10.0323C81.0585 9.74455 81.0393 9.26499 81.3267 8.9389C81.6141 8.63198 82.093 8.59362 82.4187 8.88135C84.0663 10.3392 86.9592 19.9687 87.9937 23.5365C89.6797 22.5391 94.0669 19.5083 100.945 11.7011C101.232 11.375 101.711 11.3559 102.018 11.6244C102.343 11.9121 102.362 12.3917 102.094 12.6986C92.8791 23.1721 88.0129 25.2629 87.8022 25.3588C87.7064 25.3972 87.6106 25.4164 87.5148 25.4164H87.4956Z" fill="#060606"/>
<path d="M72.6863 25.4164C72.5905 25.4164 72.4947 25.3972 72.3989 25.3588C72.1882 25.2821 67.3412 23.1721 58.1069 12.6986C57.8195 12.3725 57.8579 11.893 58.1835 11.6244C58.5092 11.3367 58.9882 11.375 59.2564 11.7011C66.1342 19.5083 70.5214 22.5391 72.2074 23.5365C73.2419 19.9878 76.154 10.3392 77.7824 8.88135C78.1081 8.59362 78.5871 8.63198 78.8744 8.9389C79.1618 9.26499 79.1235 9.74455 78.817 10.0323C77.7249 10.9914 75.0236 19.2781 73.4335 24.8601C73.376 25.0711 73.2228 25.2438 73.0312 25.3397C72.9354 25.3972 72.8204 25.4164 72.7055 25.4164H72.6863Z" fill="#060606"/>
<path d="M10.4604 182.998C8.14225 182.998 5.47925 182.845 3.027 182.384C2.60552 182.307 2.3373 181.905 2.41394 181.483C2.49057 181.061 2.89289 180.792 3.31437 180.869C9.25342 182.02 16.6868 181.118 16.7635 181.118C17.1849 181.061 17.5681 181.368 17.6256 181.79C17.683 182.212 17.3765 182.595 16.955 182.653C16.7635 182.672 13.9855 183.017 10.4412 183.017L10.4604 182.998Z" fill="#060606"/>
<path d="M149.951 182.998C146.426 182.998 143.629 182.653 143.438 182.634C143.016 182.576 142.729 182.192 142.767 181.77C142.825 181.348 143.208 181.061 143.629 181.099C143.706 181.099 151.158 182.001 157.078 180.85C157.5 180.773 157.902 181.041 157.979 181.463C158.055 181.885 157.787 182.288 157.366 182.365C154.913 182.845 152.27 182.979 149.932 182.979L149.951 182.998Z" fill="#060606"/>
<path d="M79.5833 202.641C79.2576 202.641 78.9702 202.295 78.9702 201.854V18.319C78.9702 17.8778 79.2384 17.5325 79.5833 17.5325C79.9281 17.5325 80.1963 17.8778 80.1963 18.319V201.854C80.1963 202.295 79.9281 202.641 79.5833 202.641Z" fill="#060606"/>
<path d="M84.4112 188.235C84.4112 189.06 83.7406 189.731 82.9168 189.731C82.093 189.731 81.4225 189.06 81.4225 188.235C81.4225 187.41 82.093 186.739 82.9168 186.739C83.7406 186.739 84.4112 187.41 84.4112 188.235Z" fill="#060606"/>
<path d="M84.4112 165.581C84.4112 166.405 83.7406 167.077 82.9168 167.077C82.093 167.077 81.4225 166.405 81.4225 165.581C81.4225 164.756 82.093 164.084 82.9168 164.084C83.7406 164.084 84.4112 164.756 84.4112 165.581Z" fill="#060606"/>
<path d="M84.4112 142.926C84.4112 143.751 83.7406 144.423 82.9168 144.423C82.093 144.423 81.4225 143.751 81.4225 142.926C81.4225 142.102 82.093 141.43 82.9168 141.43C83.7406 141.43 84.4112 142.102 84.4112 142.926Z" fill="#060606"/>
<path d="M84.4112 120.253C84.4112 121.078 83.7406 121.749 82.9168 121.749C82.093 121.749 81.4225 121.078 81.4225 120.253C81.4225 119.428 82.093 118.757 82.9168 118.757C83.7406 118.757 84.4112 119.428 84.4112 120.253Z" fill="#060606"/>
<path d="M84.4112 97.599C84.4112 98.4238 83.7406 99.0952 82.9168 99.0952C82.093 99.0952 81.4225 98.4238 81.4225 97.599C81.4225 96.7741 82.093 96.1027 82.9168 96.1027C83.7406 96.1027 84.4112 96.7741 84.4112 97.599Z" fill="#060606"/>
<path d="M84.4112 74.9448C84.4112 75.7696 83.7406 76.441 82.9168 76.441C82.093 76.441 81.4225 75.7696 81.4225 74.9448C81.4225 74.12 82.093 73.4486 82.9168 73.4486C83.7406 73.4486 84.4112 74.12 84.4112 74.9448Z" fill="#060606"/>
<path d="M84.4112 52.2906C84.4112 53.1155 83.7406 53.7868 82.9168 53.7868C82.093 53.7868 81.4225 53.1155 81.4225 52.2906C81.4225 51.4658 82.093 50.7944 82.9168 50.7944C83.7406 50.7944 84.4112 51.4658 84.4112 52.2906Z" fill="#060606"/>
<path d="M84.4112 29.6173C84.4112 30.4421 83.7406 31.1135 82.9168 31.1135C82.093 31.1135 81.4225 30.4421 81.4225 29.6173C81.4225 28.7925 82.093 28.1211 82.9168 28.1211C83.7406 28.1211 84.4112 28.7925 84.4112 29.6173Z" fill="#060606"/>
<path d="M84.4112 19.7768C84.4112 20.6017 83.7406 21.273 82.9168 21.273C82.093 21.273 81.4225 20.6017 81.4225 19.7768C81.4225 18.952 82.093 18.2806 82.9168 18.2806C83.7406 18.2806 84.4112 18.952 84.4112 19.7768Z" fill="#060606"/>
</svg>
`,
  clothe_50: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C9E6FF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C9E6FF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C9E6FF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#434343"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#434343"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#434343"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#434343"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#434343"/>
</svg>
`,
  clothe_51: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C11717"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C11717"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C11717"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#272727"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#272727"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#272727"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#272727"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#272727"/>
</svg>
`,
  clothe_52: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FF8DDF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FF8DDF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FF8DDF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#A415A7"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#A415A7"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#A415A7"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#A415A7"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#A415A7"/>
</svg>
`,
  clothe_53: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FE862D"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FE862D"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FE862D"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#393939"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#393939"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#393939"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#393939"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#393939"/>
</svg>
`,
  clothe_54: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#A7A7A7"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#A7A7A7"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#A7A7A7"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#A7A7A7"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#A7A7A7"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#A7A7A7"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#A7A7A7"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#A7A7A7"/>
</svg>
`,
  clothe_55: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#7D3906"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#7D3906"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#7D3906"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#7D3906"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#7D3906"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#7D3906"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#7D3906"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#7D3906"/>
</svg>
`,
  clothe_56: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#030303"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#030303"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#030303"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#030303"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#030303"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#030303"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#030303"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#030303"/>
</svg>
`,
  clothe_57: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E3E3E3"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E3E3E3"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#E3E3E3"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#E3E3E3"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#E3E3E3"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#E3E3E3"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#E3E3E3"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#E3E3E3"/>
</svg>
`,
  clothe_58: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#C9E6FF"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#C9E6FF"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#C9E6FF"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#C9E6FF"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#C9E6FF"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#C9E6FF"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#C9E6FF"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#C9E6FF"/>
</svg>
`,
  clothe_59: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#0071CE"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#0071CE"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#0071CE"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#0071CE"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#0071CE"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#0071CE"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#0071CE"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#0071CE"/>
</svg>
`,
  clothe_60: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#0B6614"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#0B6614"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#0B6614"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#0B6614"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#0B6614"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#0B6614"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#0B6614"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#0B6614"/>
</svg>
`,
  clothe_61: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#FFB176"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#FFB176"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#FFB176"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#FFB176"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#FFB176"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#FFB176"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#FFB176"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#FFB176"/>
</svg>
`,
  clothe_62: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#19436B"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#19436B"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#19436B"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#19436B"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#19436B"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#19436B"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#19436B"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#19436B"/>
</svg>
`,
  clothe_63: `<svg width="155" height="145" viewBox="0 0 155 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 100.069C0 100.069 1.01639 28.3778 4.06557 18.4207C7.11475 8.46356 26.7403 5.97428 26.7403 5.97428C26.7403 10.1504 26.7712 58.7742 26.7403 63.2278L23.8629 104.39C14.4489 97.5329 1.20573 100.917 0 100.069Z" fill="#E9B90C"/>
<path d="M155 101.065C155 101.065 154.111 28.3778 151.443 17.9228C148.775 7.46785 131.244 6.13375 131.244 6.13375C131.244 10.3099 129.051 54.7914 129.082 59.2449L132.131 106.541C141.545 99.6838 144.139 101.912 145.344 101.065H155Z" fill="#E9B90C"/>
<path d="M131.429 6.1331L120.408 3.59043C117.702 21.3891 100.42 35.1195 79.5056 35.1195C58.5908 35.1195 41.1541 21.2658 38.5726 3.34387L26.4999 6.11769C23.8629 30.8671 28.3858 104.712 28.3858 104.712C28.3858 106.345 28.3858 125.46 28.3858 134.314C50.4599 145.902 88.9586 150.55 127.557 135.417C126.541 126.456 126.541 106.666 126.541 105.048C126.541 105.048 132.558 14.7165 131.445 6.11769L131.429 6.1331Z" fill="#E9B90C"/>
<path d="M32.8996 4.29941L51.001 0C51.001 0 51.171 33.0854 79.5056 35.135L69.1642 50.468C69.1642 50.468 37.8925 40.5439 32.8996 4.29941Z" fill="#E9B90C"/>
<path d="M125.571 4.29941L107.469 0C107.469 0 107.299 33.0854 78.9645 35.135L89.306 50.468C89.306 50.468 120.578 40.5439 125.571 4.29941Z" fill="#E9B90C"/>
<path d="M79.2118 48.588C80.3814 48.588 81.3296 47.6428 81.3296 46.4768C81.3296 45.3108 80.3814 44.3656 79.2118 44.3656C78.0422 44.3656 77.0941 45.3108 77.0941 46.4768C77.0941 47.6428 78.0422 48.588 79.2118 48.588Z" fill="#E9B90C"/>
<path d="M79.2118 93.9094C80.3814 93.9094 81.3296 92.9642 81.3296 91.7983C81.3296 90.6323 80.3814 89.6871 79.2118 89.6871C78.0422 89.6871 77.0941 90.6323 77.0941 91.7983C77.0941 92.9642 78.0422 93.9094 79.2118 93.9094Z" fill="#E9B90C"/>
<path d="M79.2118 139.231C80.3814 139.231 81.3296 138.286 81.3296 137.12C81.3296 135.954 80.3814 135.009 79.2118 135.009C78.0422 135.009 77.0941 135.954 77.0941 137.12C77.0941 138.286 78.0422 139.231 79.2118 139.231Z" fill="#E9B90C"/>
</svg>
`,
};

export const facialExpression = {
  facial_expression_1:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M15 39.981C17.3907 37.9632 21.4755 36.6022 26.0628 36.2963C30.6502 35.9904 35.662 36.7504 39.233 38.3662C38.4417 39.2263 37.6395 40.0855 36.8482 40.9456C29.4642 39.0955 20.9138 39.119 15 39.981Z" fill="#392734"/>
  <path d="M76 39.981C73.6093 37.9632 69.5245 36.6022 64.9372 36.2963C60.3499 35.9904 55.338 36.7504 51.7671 38.3662C52.5583 39.2263 53.3606 40.0855 54.1518 40.9456C61.5358 39.0955 70.0862 39.119 76 39.981Z" fill="#392734"/>
  <path d="M34.0252 58.6203C35.2724 58.6203 36.2834 57.6215 36.2834 56.3895C36.2834 55.1574 35.2724 54.1587 34.0252 54.1587C32.778 54.1587 31.7669 55.1574 31.7669 56.3895C31.7669 57.6215 32.778 58.6203 34.0252 58.6203Z" fill="#23293D"/>
  <path d="M58.072 58.6204C59.3192 58.6204 60.3303 57.6216 60.3303 56.3896C60.3303 55.1576 59.3192 54.1588 58.072 54.1588C56.8248 54.1588 55.8138 55.1576 55.8138 56.3896C55.8138 57.6216 56.8248 58.6204 58.072 58.6204Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M37 87C36.4477 87 36 86.5523 36 86C36 85.4477 36.4477 85 37 85H55C55.5523 85 56 85.4477 56 86C56 86.5523 55.5523 87 55 87H37Z" fill="#BD4F5C"/>
</svg>`,
  facial_expression_2:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25888 46.7349 4.67434 48.7353C0.0898066 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M15 39.981C17.3907 37.9632 21.4755 36.6022 26.0628 36.2963C30.6502 35.9904 35.662 36.7504 39.233 38.3662C38.4417 39.2263 37.6395 40.0855 36.8482 40.9456C29.4642 39.0955 20.9138 39.119 15 39.981Z" fill="#392734"/>
  <path d="M76 39.981C73.6093 37.9632 69.5245 36.6022 64.9372 36.2963C60.3499 35.9904 55.338 36.7504 51.7671 38.3662C52.5583 39.2263 53.3606 40.0855 54.1518 40.9456C61.5358 39.0955 70.0862 39.119 76 39.981Z" fill="#392734"/>
  <path d="M34.0252 58.6204C35.2724 58.6204 36.2834 57.6216 36.2834 56.3896C36.2834 55.1576 35.2724 54.1588 34.0252 54.1588C32.778 54.1588 31.7669 55.1576 31.7669 56.3896C31.7669 57.6216 32.778 58.6204 34.0252 58.6204Z" fill="#23293D"/>
  <path d="M58.072 58.6205C59.3192 58.6205 60.3303 57.6217 60.3303 56.3897C60.3303 55.1577 59.3192 54.1589 58.072 54.1589C56.8248 54.1589 55.8137 55.1577 55.8137 56.3897C55.8137 57.6217 56.8248 58.6205 58.072 58.6205Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M36 87.4269C36 86.7558 36.7013 86.277 37.3459 86.4638C39.1437 86.9846 42.6172 87.6743 47 87C51.6345 86.287 55.7606 83.0322 57.7473 81.2226C58.207 80.8038 59 81.1312 59 81.7531C59 81.9126 58.9469 82.0638 58.8407 82.1828C57.9368 83.1956 53.2709 88.1042 47 89C41.4793 89.7887 37.8246 88.7113 36.5265 88.2208C36.1979 88.0966 36 87.7781 36 87.4269Z" fill="#392734"/>
</svg>`,
  facial_expression_3:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M15 41C17.2058 38.3379 21.1564 36.2837 25.6996 35.4382C30.2428 34.5928 35.3018 34.9771 39 36.5352C38.2867 37.6462 37.5624 38.7571 36.8491 39.8681C29.332 38.4287 20.8162 39.351 15 41Z" fill="#392734"/>
  <path d="M76 41C73.7943 38.3379 69.8436 36.2837 65.3004 35.4382C60.7572 34.5928 55.6982 34.9771 52 36.5352C52.7133 37.6462 53.4376 38.7571 54.1509 39.8681C61.668 38.4287 70.1838 39.351 76 41Z" fill="#392734"/>
  <path d="M34.0252 58.6204C35.2724 58.6204 36.2834 57.6216 36.2834 56.3896C36.2834 55.1576 35.2724 54.1588 34.0252 54.1588C32.778 54.1588 31.7669 55.1576 31.7669 56.3896C31.7669 57.6216 32.778 58.6204 34.0252 58.6204Z" fill="#23293D"/>
  <path d="M58.072 58.6205C59.3192 58.6205 60.3303 57.6217 60.3303 56.3897C60.3303 55.1577 59.3192 54.1589 58.072 54.1589C56.8248 54.1589 55.8138 55.1577 55.8138 56.3897C55.8138 57.6217 56.8248 58.6205 58.072 58.6205Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M35 84.6863C35 84.0329 35.8066 83.6416 36.3524 84.0009C38.152 85.1858 41.6225 87 46 87C50.3776 87 53.848 85.1858 55.6476 84.0009C56.1934 83.6416 57 84.0329 57 84.6863C57 84.8872 56.9226 85.0774 56.7715 85.2098C55.8319 86.0333 52.0285 89 46 89C39.9715 89 36.1681 86.0333 35.2285 85.2098C35.0774 85.0774 35 84.8872 35 84.6863Z" fill="#392734"/>
</svg>`,
  facial_expression_4:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M35 81C35 81 39.1111 82.9871 46.0922 82.9871C53.0732 82.9871 57 81 57 81C57 81 56.3115 84.4346 53.5095 85.7669C49.8608 87.4997 40.6941 87.3199 38.2385 85.7669C35.9061 84.2874 35 81 35 81Z" fill="white"/>
  <path d="M15 40C17.2058 37.3379 21.1564 35.2837 25.6996 34.4382C30.2428 33.5928 35.3018 33.9771 39 35.5352C38.2867 36.6462 37.5624 37.7571 36.8491 38.8681C29.332 37.4287 20.8162 38.351 15 40Z" fill="#392734"/>
  <path d="M76 40C73.7943 37.3379 69.8436 35.2837 65.3004 34.4382C60.7572 33.5928 55.6982 33.9771 52 35.5352C52.7133 36.6462 53.4376 37.7571 54.1509 38.8681C61.668 37.4287 70.1838 38.351 76 40Z" fill="#392734"/>
  <path d="M34.0252 57.6204C35.2724 57.6204 36.2834 56.6217 36.2834 55.3896C36.2834 54.1576 35.2724 53.1588 34.0252 53.1588C32.778 53.1588 31.7669 54.1576 31.7669 55.3896C31.7669 56.6217 32.778 57.6204 34.0252 57.6204Z" fill="#23293D"/>
  <path d="M58.072 57.6205C59.3192 57.6205 60.3303 56.6218 60.3303 55.3898C60.3303 54.1577 59.3192 53.159 58.072 53.159C56.8248 53.159 55.8138 54.1577 55.8138 55.3898C55.8138 56.6218 56.8248 57.6205 58.072 57.6205Z" fill="#23293D"/>
  <path d="M45 56C45 55.4477 45.4477 55 46 55C46.5523 55 47 55.4477 47 56V70C47 70.5523 46.5523 71 46 71C45.4477 71 45 70.5523 45 70V56Z" fill="#5C2432"/>
</svg>`,
  facial_expression_5:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M58 79C56.8193 83.3384 51.5823 87.5125 46.1767 87.9595C40.7711 88.4065 35.6064 85.0935 34 81.0903C42.2249 81.7279 50.6346 80.7288 58 79Z" fill="white"/>
  <path d="M15 39.9854C17.3861 37.4836 21.4704 35.71 26.0615 35.1835C30.6526 34.6571 35.6725 35.3933 39.253 37.2056C38.4639 38.2641 37.6639 39.3218 36.8749 40.3803C29.4764 38.4201 20.9171 38.7462 15 39.9854Z" fill="#392734"/>
  <path d="M76 39.9854C73.6139 37.4836 69.5296 35.71 64.9385 35.1835C60.3474 34.6571 55.3275 35.3933 51.747 37.2056C52.5361 38.2641 53.3361 39.3218 54.1252 40.3803C61.5236 38.4201 70.083 38.7462 76 39.9854Z" fill="#392734"/>
  <path d="M34.0252 57.6204C35.2724 57.6204 36.2834 56.6217 36.2834 55.3896C36.2834 54.1576 35.2724 53.1588 34.0252 53.1588C32.778 53.1588 31.7669 54.1576 31.7669 55.3896C31.7669 56.6217 32.778 57.6204 34.0252 57.6204Z" fill="#23293D"/>
  <path d="M58.072 57.6205C59.3192 57.6205 60.3303 56.6218 60.3303 55.3898C60.3303 54.1577 59.3192 53.159 58.072 53.159C56.8248 53.159 55.8138 54.1577 55.8138 55.3898C55.8138 56.6218 56.8248 57.6205 58.072 57.6205Z" fill="#23293D"/>
  <path d="M45 56C45 55.4477 45.4477 55 46 55C46.5523 55 47 55.4477 47 56V70C47 70.5523 46.5523 71 46 71C45.4477 71 45 70.5523 45 70V56Z" fill="#5C2432"/>
</svg>`,
  facial_expression_6:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M15 39.8296C17.7048 38.2577 21.9638 37.6267 26.5346 38.122C31.1053 38.6173 35.909 40.236 39.1452 42.4474C38.2166 43.157 37.2774 43.8638 36.3487 44.5735C29.3982 41.4693 20.9736 40.0077 15 39.8296Z" fill="#392734"/>
  <path d="M76 39.8296C73.2952 38.2577 69.0362 37.6267 64.4654 38.122C59.8947 38.6173 55.091 40.236 51.8548 42.4474C52.7834 43.157 53.7226 43.8638 54.6513 44.5735C61.6018 41.4693 70.0264 40.0077 76 39.8296Z" fill="#392734"/>
  <path d="M34.0252 58.6204C35.2724 58.6204 36.2834 57.6216 36.2834 56.3896C36.2834 55.1576 35.2724 54.1588 34.0252 54.1588C32.778 54.1588 31.7669 55.1576 31.7669 56.3896C31.7669 57.6216 32.778 58.6204 34.0252 58.6204Z" fill="#23293D"/>
  <path d="M58.072 58.6205C59.3192 58.6205 60.3303 57.6217 60.3303 56.3897C60.3303 55.1577 59.3192 54.1589 58.072 54.1589C56.8248 54.1589 55.8138 55.1577 55.8138 56.3897C55.8138 57.6217 56.8248 58.6205 58.072 58.6205Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M57 88C57 88 52.8889 85.9689 45.9079 85.9689C38.9268 85.9689 35 88 35 88C35 88 35.6886 84.4893 38.4905 83.1275C42.1392 81.3563 51.0539 81.8375 53.5095 83.4249C55.8419 84.9371 57 88 57 88Z" fill="white"/>
</svg>`,
  facial_expression_7:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25887 46.7349 4.67434 48.7353C0.089807 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M15 46.1032C17.9101 42.111 20.8481 37.5632 25.4999 36.9526C30.1517 36.342 36.4368 39.69 39.5734 42.88C38 43.7551 38 43.7551 37 44.2551C30.5 40 26 36.5 15 46.1032Z" fill="#392734"/>
  <path d="M76 46.1032C73.0899 42.111 70.1519 37.5632 65.5001 36.9526C60.8482 36.342 54.5631 39.69 51.4266 42.88C53 43.7551 53 43.7551 54 44.2551C60.5 40 65 36.5 76 46.1032Z" fill="#392734"/>
  <path d="M32 59.0001C34.2092 59.0001 36 57.2093 36 55.0001C36 52.791 34.2092 51.0001 32 51.0001C29.7909 51.0001 28 52.791 28 55.0001C28 57.2093 29.7909 59.0001 32 59.0001Z" fill="#23293D"/>
  <path d="M60.0001 59.0001C62.2092 59.0001 64.0001 57.2093 64.0001 55.0001C64.0001 52.791 62.2092 51.0001 60.0001 51.0001C57.7909 51.0001 56.0001 52.791 56.0001 55.0001C56.0001 57.2093 57.7909 59.0001 60.0001 59.0001Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M54 84C54 86.2091 50.4183 88 46 88C41.5817 88 38 86.2091 38 84C38 81.7909 41.5817 80 46 80C50.4183 80 54 81.7909 54 84Z" fill="white"/>
</svg>`,
  facial_expression_8:`<svg xmlns="http://www.w3.org/2000/svg" width="92" height="105" viewBox="0 0 92 105" fill="none">
  <path d="M73.6967 57.3928C76.6415 50.6176 82.7411 46.7349 87.3257 48.7353C91.9102 50.7226 93.2482 57.8322 90.3033 64.6073C87.3585 71.3825 81.2589 75.2652 76.6743 73.2648C72.0898 71.2775 70.7518 64.1679 73.6967 57.3928Z" fill="currentColor"/>
  <path d="M18.3033 57.3928C15.3585 50.6176 9.25888 46.7349 4.67434 48.7353C0.0898066 50.7226 -1.24817 57.8322 1.69669 64.6073C4.64155 71.3825 10.7411 75.2652 15.3257 73.2648C19.9102 71.2775 21.2482 64.1679 18.3033 57.3928Z" fill="currentColor"/>
  <path d="M46 105C67.5391 105 85 81.495 85 52.5C85 23.5051 67.5391 0 46 0C24.4609 0 7.00001 23.5051 7.00001 52.5C7.00001 81.495 24.4609 105 46 105Z" fill="currentColor"/>
  <path d="M16.5529 47.0072C17.9945 43.8649 21.2789 40.8582 25.4484 38.8657C29.618 36.8732 34.6041 35.935 38.5796 36.4829C38.1781 37.7406 37.766 39.0012 37.3646 40.2589C29.731 40.8142 21.7441 43.9091 16.5529 47.0072Z" fill="#392734"/>
  <path d="M74.4471 47.0072C73.0055 43.8649 69.7212 40.8582 65.5516 38.8657C61.382 36.8732 56.3959 35.935 52.4205 36.4829C52.8219 37.7406 53.234 39.0012 53.6354 40.2589C61.269 40.8142 69.2559 43.9091 74.4471 47.0072Z" fill="#392734"/>
  <path d="M34.0252 58.6204C35.2724 58.6204 36.2834 57.6216 36.2834 56.3896C36.2834 55.1576 35.2724 54.1588 34.0252 54.1588C32.778 54.1588 31.7669 55.1576 31.7669 56.3896C31.7669 57.6216 32.778 58.6204 34.0252 58.6204Z" fill="#23293D"/>
  <path d="M58.072 58.6205C59.3192 58.6205 60.3303 57.6217 60.3303 56.3897C60.3303 55.1577 59.3192 54.1589 58.072 54.1589C56.8248 54.1589 55.8138 55.1577 55.8138 56.3897C55.8138 57.6217 56.8248 58.6205 58.072 58.6205Z" fill="#23293D"/>
  <path d="M45 57C45 56.4477 45.4477 56 46 56C46.5523 56 47 56.4477 47 57V71C47 71.5523 46.5523 72 46 72C45.4477 72 45 71.5523 45 71V57Z" fill="#5C2432"/>
  <path d="M35 86.3137C35 86.9671 35.8066 87.3584 36.3524 86.9991C38.152 85.8142 41.6225 84 46 84C50.3776 84 53.848 85.8142 55.6477 86.9991C56.1934 87.3584 57 86.9671 57 86.3137C57 86.1128 56.9226 85.9226 56.7715 85.7902C55.8319 84.9667 52.0285 82 46 82C39.9715 82 36.1681 84.9667 35.2285 85.7902C35.0774 85.9226 35 86.1128 35 86.3137Z" fill="#392734"/>
</svg>`,
}
 export const facialExpressionPicker = {
  facial_expression_1:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M10.6964 29.0578C12.4164 27.6166 15.3553 26.6444 18.6558 26.426C21.9562 26.2075 25.5621 26.7503 28.1313 27.9044C27.562 28.5188 26.9848 29.1325 26.4155 29.7468C21.1029 28.4254 14.9512 28.4421 10.6964 29.0578Z" fill="#392734"/>
  <path d="M54.5842 29.0578C52.8641 27.6166 49.9252 26.6444 46.6248 26.426C43.3243 26.2075 39.7185 26.7503 37.1492 27.9044C37.7185 28.5188 38.2957 29.1325 38.865 29.7468C44.1776 28.4254 50.3293 28.4421 54.5842 29.0578Z" fill="#392734"/>
  <path d="M24.3844 42.3716C25.2818 42.3716 26.0092 41.6582 26.0092 40.7782C26.0092 39.8982 25.2818 39.1848 24.3844 39.1848C23.4871 39.1848 22.7597 39.8982 22.7597 40.7782C22.7597 41.6582 23.4871 42.3716 24.3844 42.3716Z" fill="#23293D"/>
  <path d="M41.6855 42.3717C42.5828 42.3717 43.3102 41.6583 43.3102 40.7783C43.3102 39.8983 42.5828 39.1849 41.6855 39.1849C40.7881 39.1849 40.0607 39.8983 40.0607 40.7783C40.0607 41.6583 40.7881 42.3717 41.6855 42.3717Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M26.5248 62.6429C26.1274 62.6429 25.8053 62.3231 25.8053 61.9286C25.8053 61.5341 26.1274 61.2143 26.5248 61.2143H39.4753C39.8726 61.2143 40.1947 61.5341 40.1947 61.9286C40.1947 62.3231 39.8726 62.6429 39.4753 62.6429H26.5248Z" fill="#392734"/>
</svg>`,
  facial_expression_2:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311172 36.7304 -0.993752 41.8087 1.12499 46.6481C3.24374 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M10.6964 29.0578C12.4164 27.6166 15.3553 26.6444 18.6558 26.426C21.9562 26.2075 25.5621 26.7503 28.1313 27.9044C27.562 28.5188 26.9848 29.1325 26.4155 29.7468C21.1029 28.4254 14.9512 28.4421 10.6964 29.0578Z" fill="#392734"/>
  <path d="M54.5842 29.0578C52.8641 27.6166 49.9252 26.6444 46.6248 26.426C43.3243 26.2075 39.7185 26.7503 37.1492 27.9044C37.7185 28.5188 38.2957 29.1325 38.865 29.7468C44.1776 28.4254 50.3293 28.4421 54.5842 29.0578Z" fill="#392734"/>
  <path d="M24.3844 42.3717C25.2818 42.3717 26.0092 41.6583 26.0092 40.7783C26.0092 39.8983 25.2818 39.1849 24.3844 39.1849C23.4871 39.1849 22.7597 39.8983 22.7597 40.7783C22.7597 41.6583 23.4871 42.3717 24.3844 42.3717Z" fill="#23293D"/>
  <path d="M41.6855 42.3718C42.5828 42.3718 43.3102 41.6584 43.3102 40.7784C43.3102 39.8983 42.5828 39.185 41.6855 39.185C40.7881 39.185 40.0607 39.8983 40.0607 40.7784C40.0607 41.6584 40.7881 42.3718 41.6855 42.3718Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M25.8053 62.9478C25.8053 62.4685 26.3098 62.1265 26.7736 62.2598C28.067 62.6319 30.5661 63.1245 33.7195 62.6429C37.0538 62.1336 40.0225 59.8087 41.4518 58.5162C41.7826 58.217 42.3531 58.4508 42.3531 58.8951C42.3531 59.009 42.3149 59.117 42.2385 59.202C41.5882 59.9254 38.2312 63.4315 33.7195 64.0714C29.7475 64.6348 27.118 63.8652 26.1841 63.5148C25.9477 63.4261 25.8053 63.1987 25.8053 62.9478Z" fill="#392734"/>
</svg>`,
  facial_expression_3:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M10.6964 29.7857C12.2833 27.8842 15.1257 26.4169 18.3944 25.813C21.6631 25.2091 25.3029 25.4836 27.9637 26.5966C27.4505 27.3901 26.9294 28.1837 26.4162 28.9772C21.0078 27.9491 14.8809 28.6079 10.6964 29.7857Z" fill="#392734"/>
  <path d="M54.5842 29.7857C52.9972 27.8842 50.1548 26.4169 46.8861 25.813C43.6174 25.2091 39.9776 25.4836 37.3168 26.5966C37.83 27.3901 38.3511 28.1837 38.8643 28.9772C44.2727 27.9491 50.3996 28.6079 54.5842 29.7857Z" fill="#392734"/>
  <path d="M24.3844 42.3717C25.2818 42.3717 26.0092 41.6583 26.0092 40.7783C26.0092 39.8983 25.2818 39.1849 24.3844 39.1849C23.4871 39.1849 22.7597 39.8983 22.7597 40.7783C22.7597 41.6583 23.4871 42.3717 24.3844 42.3717Z" fill="#23293D"/>
  <path d="M41.6855 42.3718C42.5828 42.3718 43.3102 41.6584 43.3102 40.7784C43.3102 39.8983 42.5828 39.185 41.6855 39.185C40.7881 39.185 40.0607 39.8983 40.0607 40.7784C40.0607 41.6584 40.7881 42.3718 41.6855 42.3718Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M25.0858 60.9902C25.0858 60.5235 25.6661 60.244 26.0588 60.5007C27.3536 61.347 29.8505 62.6429 33 62.6429C36.1495 62.6429 38.6464 61.347 39.9412 60.5007C40.3339 60.244 40.9142 60.5235 40.9142 60.9902C40.9142 61.1337 40.8585 61.2696 40.7498 61.3641C40.0738 61.9523 37.3373 64.0714 33 64.0714C28.6627 64.0714 25.9262 61.9523 25.2502 61.3641C25.1415 61.2696 25.0858 61.1337 25.0858 60.9902Z" fill="#392734"/>
</svg>`,
  facial_expression_4:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M25.0858 58.3571C25.0858 58.3571 28.0436 59.7765 33.0663 59.7765C38.089 59.7765 40.9142 58.3571 40.9142 58.3571C40.9142 58.3571 40.4188 60.8104 38.4029 61.7621C35.7777 62.9998 29.1825 62.8714 27.4158 61.7621C25.7377 60.7053 25.0858 58.3571 25.0858 58.3571Z" fill="white"/>
  <path d="M10.6964 29.0714C12.2833 27.1699 15.1257 25.7026 18.3944 25.0987C21.6631 24.4948 25.3029 24.7693 27.9637 25.8823C27.4505 26.6758 26.9294 27.4694 26.4162 28.2629C21.0078 27.2348 14.8809 27.8936 10.6964 29.0714Z" fill="#392734"/>
  <path d="M54.5842 29.0714C52.9972 27.1699 50.1548 25.7026 46.8861 25.0987C43.6174 24.4948 39.9776 24.7693 37.3168 25.8823C37.83 26.6758 38.3511 27.4694 38.8643 28.2629C44.2727 27.2348 50.3996 27.8936 54.5842 29.0714Z" fill="#392734"/>
  <path d="M24.3844 41.6574C25.2818 41.6574 26.0092 40.944 26.0092 40.064C26.0092 39.184 25.2818 38.4706 24.3844 38.4706C23.4871 38.4706 22.7597 39.184 22.7597 40.064C22.7597 40.944 23.4871 41.6574 24.3844 41.6574Z" fill="#23293D"/>
  <path d="M41.6855 41.6575C42.5828 41.6575 43.3102 40.9441 43.3102 40.0641C43.3102 39.1841 42.5828 38.4707 41.6855 38.4707C40.7881 38.4707 40.0607 39.1841 40.0607 40.0641C40.0607 40.9441 40.7881 41.6575 41.6855 41.6575Z" fill="#23293D"/>
  <path d="M32.2805 40.5C32.2805 40.1055 32.6026 39.7857 33 39.7857C33.3974 39.7857 33.7195 40.1055 33.7195 40.5V50.5C33.7195 50.8945 33.3974 51.2143 33 51.2143C32.6026 51.2143 32.2805 50.8945 32.2805 50.5V40.5Z" fill="#5C2432"/>
</svg>`,
  facial_expression_5:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M41.6337 56.9286C40.7842 60.0274 37.0163 63.0089 33.1271 63.3282C29.2379 63.6475 25.5221 61.2811 24.3663 58.4217C30.2839 58.8771 36.3344 58.1634 41.6337 56.9286Z" fill="white"/>
  <path d="M10.6964 29.061C12.4131 27.274 15.3516 26.0071 18.6548 25.6311C21.958 25.255 25.5696 25.7809 28.1457 27.0754C27.578 27.8315 27.0024 28.587 26.4347 29.3431C21.1117 27.9429 14.9535 28.1758 10.6964 29.061Z" fill="#392734"/>
  <path d="M54.5842 29.061C52.8674 27.274 49.9289 26.0071 46.6257 25.6311C43.3226 25.255 39.7109 25.7809 37.1348 27.0754C37.7025 27.8315 38.2781 28.587 38.8458 29.3431C44.1688 27.9429 50.327 28.1758 54.5842 29.061Z" fill="#392734"/>
  <path d="M24.3844 41.6574C25.2818 41.6574 26.0092 40.944 26.0092 40.064C26.0092 39.184 25.2818 38.4706 24.3844 38.4706C23.4871 38.4706 22.7597 39.184 22.7597 40.064C22.7597 40.944 23.4871 41.6574 24.3844 41.6574Z" fill="#23293D"/>
  <path d="M41.6855 41.6575C42.5828 41.6575 43.3102 40.9441 43.3102 40.0641C43.3102 39.1841 42.5828 38.4707 41.6855 38.4707C40.7881 38.4707 40.0607 39.1841 40.0607 40.0641C40.0607 40.9441 40.7881 41.6575 41.6855 41.6575Z" fill="#23293D"/>
  <path d="M32.2805 40.5C32.2805 40.1055 32.6026 39.7857 33 39.7857C33.3974 39.7857 33.7195 40.1055 33.7195 40.5V50.5C33.7195 50.8945 33.3974 51.2143 33 51.2143C32.6026 51.2143 32.2805 50.8945 32.2805 50.5V40.5Z" fill="#5C2432"/>
</svg>`,
  facial_expression_6:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M10.6963 28.9497C12.6423 27.8269 15.7066 27.3762 18.9952 27.73C22.2837 28.0838 25.7398 29.24 28.0681 30.8196C27.4 31.3265 26.7243 31.8313 26.0562 32.3382C21.0554 30.1209 14.9942 29.0769 10.6963 28.9497Z" fill="#392734"/>
  <path d="M54.5842 28.9497C52.6382 27.8269 49.5739 27.3762 46.2854 27.73C42.9968 28.0838 39.5407 29.24 37.2124 30.8196C37.8805 31.3265 38.5562 31.8313 39.2243 32.3382C44.2251 30.1209 50.2863 29.0769 54.5842 28.9497Z" fill="#392734"/>
  <path d="M24.3844 42.3717C25.2818 42.3717 26.0092 41.6583 26.0092 40.7783C26.0092 39.8983 25.2818 39.1849 24.3844 39.1849C23.4871 39.1849 22.7597 39.8983 22.7597 40.7783C22.7597 41.6583 23.4871 42.3717 24.3844 42.3717Z" fill="#23293D"/>
  <path d="M41.6855 42.3718C42.5828 42.3718 43.3102 41.6584 43.3102 40.7784C43.3102 39.8983 42.5828 39.185 41.6855 39.185C40.7881 39.185 40.0607 39.8983 40.0607 40.7784C40.0607 41.6584 40.7881 42.3718 41.6855 42.3718Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M40.9142 63.3571C40.9142 63.3571 37.9564 61.9063 32.9337 61.9063C27.9111 61.9063 25.0858 63.3571 25.0858 63.3571C25.0858 63.3571 25.5812 60.8495 27.5971 59.8768C30.2223 58.6116 36.6362 58.9553 38.4029 60.0892C40.081 61.1694 40.9142 63.3571 40.9142 63.3571Z" fill="white"/>
</svg>`,
  facial_expression_7:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311169 36.7304 -0.993753 41.8087 1.12499 46.6481C3.24373 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M10.6963 33.4308C12.7901 30.5793 14.9039 27.3308 18.2508 26.8947C21.5976 26.4586 26.1196 28.85 28.3762 31.1286C27.2442 31.7536 27.2442 31.7536 26.5247 32.1108C21.8482 29.0714 18.6105 26.5714 10.6963 33.4308Z" fill="#392734"/>
  <path d="M54.5842 33.4308C52.4904 30.5793 50.3766 27.3308 47.0297 26.8947C43.6829 26.4586 39.1609 28.85 36.9043 31.1286C38.0363 31.7536 38.0363 31.7536 38.7558 32.1108C43.4323 29.0714 46.67 26.5714 54.5842 33.4308Z" fill="#392734"/>
  <path d="M22.9274 42.6429C24.5168 42.6429 25.8053 41.3638 25.8053 39.7858C25.8053 38.2078 24.5168 36.9287 22.9274 36.9287C21.338 36.9287 20.0495 38.2078 20.0495 39.7858C20.0495 41.3638 21.338 42.6429 22.9274 42.6429Z" fill="#23293D"/>
  <path d="M43.0727 42.6429C44.6621 42.6429 45.9505 41.3638 45.9505 39.7858C45.9505 38.2078 44.6621 36.9287 43.0727 36.9287C41.4832 36.9287 40.1948 38.2078 40.1948 39.7858C40.1948 41.3638 41.4832 42.6429 43.0727 42.6429Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M38.7558 60.5C38.7558 62.078 36.1788 63.3571 33 63.3571C29.8212 63.3571 27.2442 62.078 27.2442 60.5C27.2442 58.922 29.8212 57.6429 33 57.6429C36.1788 57.6429 38.7558 58.922 38.7558 60.5Z" fill="white"/>
</svg>`,
  facial_expression_8:`<svg xmlns="http://www.w3.org/2000/svg" width="66" height="76" viewBox="0 0 66 76" fill="none">
  <path d="M52.927 41.4948C55.0457 36.6555 59.4342 33.8821 62.7327 35.3109C66.0311 36.7304 66.9938 41.8087 64.875 46.6481C62.7563 51.4875 58.3678 54.2609 55.0693 52.832C51.7709 51.4125 50.8083 46.3342 52.927 41.4948Z" fill="currentColor"/>
  <path d="M13.073 41.4948C10.9543 36.6555 6.56577 33.8821 3.26733 35.3109C-0.0311172 36.7304 -0.993752 41.8087 1.12499 46.6481C3.24374 51.4875 7.63223 54.2609 10.9307 52.832C14.2291 51.4125 15.1918 46.3342 13.073 41.4948Z" fill="currentColor"/>
  <path d="M33 75.5C48.4968 75.5 61.0594 58.7107 61.0594 38C61.0594 17.2893 48.4968 0.5 33 0.5C17.5032 0.5 4.94058 17.2893 4.94058 38C4.94058 58.7107 17.5032 75.5 33 75.5Z" fill="currentColor"/>
  <path d="M11.8136 34.0766C12.8508 31.8321 15.2138 29.6844 18.2137 28.2612C21.2136 26.838 24.801 26.1679 27.6612 26.5592C27.3724 27.4576 27.0759 28.358 26.787 29.2564C21.2949 29.653 15.5486 31.8636 11.8136 34.0766Z" fill="#392734"/>
  <path d="M53.4669 34.0766C52.4297 31.8321 50.0667 29.6844 47.0668 28.2612C44.0669 26.838 40.4796 26.1679 37.6193 26.5592C37.9082 27.4576 38.2046 28.358 38.4935 29.2564C43.9856 29.653 49.7319 31.8636 53.4669 34.0766Z" fill="#392734"/>
  <path d="M24.3844 42.3717C25.2818 42.3717 26.0092 41.6583 26.0092 40.7783C26.0092 39.8983 25.2818 39.1849 24.3844 39.1849C23.4871 39.1849 22.7597 39.8983 22.7597 40.7783C22.7597 41.6583 23.4871 42.3717 24.3844 42.3717Z" fill="#23293D"/>
  <path d="M41.6855 42.3718C42.5828 42.3718 43.3102 41.6584 43.3102 40.7784C43.3102 39.8983 42.5828 39.185 41.6855 39.185C40.7881 39.185 40.0607 39.8983 40.0607 40.7784C40.0607 41.6584 40.7881 42.3718 41.6855 42.3718Z" fill="#23293D"/>
  <path d="M32.2805 41.2143C32.2805 40.8198 32.6026 40.5 33 40.5C33.3974 40.5 33.7195 40.8198 33.7195 41.2143V51.2143C33.7195 51.6088 33.3974 51.9286 33 51.9286C32.6026 51.9286 32.2805 51.6088 32.2805 51.2143V41.2143Z" fill="#5C2432"/>
  <path d="M25.0858 62.1526C25.0858 62.6194 25.6661 62.8988 26.0588 62.6422C27.3536 61.7959 29.8505 60.5 33 60.5C36.1495 60.5 38.6464 61.7959 39.9412 62.6422C40.3339 62.8988 40.9142 62.6194 40.9142 62.1526C40.9142 62.0092 40.8585 61.8733 40.7498 61.7787C40.0738 61.1905 37.3373 59.0714 33 59.0714C28.6627 59.0714 25.9262 61.1905 25.2502 61.7787C25.1415 61.8733 25.0858 62.0092 25.0858 62.1526Z" fill="#392734"/>
</svg>`,
 }
